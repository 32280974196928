<template>
     <div class="w-full bg-primary-2 relative flex flex-col justify-center items-center">
          <div class="w-full 3xl:max-w-screen-3xl h-full flex flex-col justify-start items-center px-5 xs:px-8 sm:px-18 md:px-24 lg:px-16 xl:px-32 pb-8 xs:pb-12 sm:pb-18 md:pb-24 lg:pb-28 xl:pb-32 pt-8 xs:pt-12 sm:pt-18 md:pt-24 lg:pt-0">
               <div class="flex flex-col justify-center items-center">
                    <h1 class="text-white font-helveticaItalic tracking-wide italic text-4xl xs:text-5xl sm:text-7xl md:text-7xl xl:text-8xl leading-3xl xs:leading-4xl sm:leading-6xl md:leading-6xl xl:leading-7.5xl">GAME STUDIOS
                    </h1>
                    <div class="mt-2">
                         <Star/>
                    </div>
               </div>
               <div
                    class="mt-14 md:mt-20 lg:mt-28 w-full flex justify-center"
               >
                    <ProviderList :goTo="goTo"/>
               </div>
          </div>
     </div>
</template>

<script>
import ProviderList from "components/providers/index.vue";
import Star from "components/star/index.vue";
import { useRouter } from "vue-router";

export default {
     components: {
          Star,
          ProviderList
     },
     props: [""],
     setup(props) {
          const router = useRouter();

          const goTo = (alias) => {
               const temp = alias.toLowerCase().replaceAll(" ", "-");
               router.push({ path: "/provider/"+temp, force: true });
          }

          return {
               goTo
          };
     },
};
</script>
