import { getPromotion, banner as bannerAPI } from '@/api/promotion'
import { defineStore } from "pinia"
import { useToaster } from "composables/use-toaster.js";

const { successToast, errorToast } = useToaster();

export const promoStore = defineStore('promo', {
  state: () => {
    return {
      promo: null,
      banner: null,
    }
  },
  actions: {
    getPromotion(type) {
      if (type.published_at == 'promotion' && this.promo != null) {
        return;
      }

      if (type.published_at == 'games' && this.banner != null) {
        return;
      }

      return new Promise((resolve, reject) => {
        getPromotion(type)
          .then(response => {
            const { data } = response;

            if (!data) {
              reject('Failed to load data!')
            }


            this.$patch((state) => {
              if (type.published_at == 'promotion') {
                state.promo = data;
              }
              if (type.published_at == 'games') {
                state.banner = data;
              }

            })
            resolve()
          })
          .catch(error => {
            this.$patch((state) => {
              if (error.response) {
                state.error_msg = error.response.data;
              }
            });
            // errorToast('Error to load data!', "top-right");
            console.error('Error to load promotion data!', error);
            reject(error)
          })
      })
    },
    // getBanner() {
    //   return new Promise(async (resolve, reject) => {
    //     if(this.banner != null) return;
    //     await bannerAPI()
    //       .then(response => {
    //         const { data } = response;

    //         if (!data) {
    //           reject('Failed to load data!')
    //         }

    //         this.$patch((state) => {
    //           state.banner = data;
    //         })
    //         resolve()
    //       })
    //       .catch(error => {
    //         this.$patch((state) => {
    //           if (error.response) {
    //             state.error_msg = error.response.data;
    //           }
    //         });
    //         // errorToast('Error to load data!', "top-right");
    //         console.error('Error to load banner data!', error);
    //         reject(error)
    //       })
    //   })
    // }
  }
})
