import request from '@/utils/request';

export function gameLauncher(data, type) {
  let url = '';

  switch (type) {
    case 'seamless':
      url = '/seamless/game/launcher';
      break;
    case 'pts':
      url = '/ptsons/game/launcher';
      break;
    default:
      break;
  }

  return request({
    url: url,
    method: 'post',
    data: data,
  });
}
