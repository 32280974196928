<template>
     <div class=" p-3 xs:p-0 font-inter text-xs" style="min-height: 400px;">
          <div class="p-4 bg-gray-160 border border-gray-170 rounded-lg">
               <div class="flex flex-col sm:flex-row gap-4">
                    <div class="flex-1">
                         <img
                              class="w-10 mb-2"
                              :src="baseUrl.img+'/assets/img/icons/id-verification.png'"
                              alt=""
                         />
                         <div class="flex items-center gap-2 mb-3">
                              <div class="text-primal-black leading-tight text-base font-bold">
                                   ID Verification
                              </div>
                              <div
                                   :class="
                                    !kycStatus
                                        ? 'bg-primal-black'
                                        : kycStatus == 1
                                        ? 'bg-primal-green'
                                        : kycStatus == 2
                                        ? 'bg-primal-red'
                                        : 'bg-primal-yellow'
                                    "
                                   class="flex flex-row text-white rounded-full px-3 text-mid py-1"
                              >
                                   <svg
                                        v-if="kycStatus == 1"
                                        class="mr-1"
                                        width="16"
                                        height="17"
                                        viewBox="0 0 16 17"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                   >
                                        <path
                                             d="M13.3327 4.5L5.99935 11.8333L2.66602 8.5"
                                             stroke="white"
                                             stroke-width="1.6"
                                             stroke-linecap="round"
                                             stroke-linejoin="round"
                                        />
                                   </svg>
                                   <svg
                                        v-if="kycStatus == 0"
                                        class="mr-1"
                                        width="16"
                                        height="17"
                                        viewBox="0 0 16 17"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                   >
                                        <path
                                             d="M8.00065 4.49967V8.49967L10.6673 9.83301M14.6673 8.49967C14.6673 12.1816 11.6826 15.1663 8.00065 15.1663C4.31875 15.1663 1.33398 12.1816 1.33398 8.49967C1.33398 4.81778 4.31875 1.83301 8.00065 1.83301C11.6826 1.83301 14.6673 4.81778 14.6673 8.49967Z"
                                             stroke="white"
                                             stroke-width="1.6"
                                             stroke-linecap="round"
                                             stroke-linejoin="round"
                                        />
                                   </svg>
                                   <svg
                                        v-if="kycStatus == 2"
                                        class="mr-1"
                                        width="16"
                                        height="17"
                                        viewBox="0 0 16 17"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                   >
                                        <path
                                             d="M12 4.5L4 12.5M4 4.5L12 12.5"
                                             stroke="white"
                                             stroke-width="1.6"
                                             stroke-linecap="round"
                                             stroke-linejoin="round"
                                        />
                                   </svg>
                                   {{
                                    !kycStatus
                                        ? "Not verified"
                                        : kycStatus == 1
                                        ? "Verified"
                                        : kycStatus == 2
                                        ? "Rejected"
                                        : "Pending"
                                    }}
                              </div>
                         </div>
                         <div class="leading-tight text-gray-120">
                              <span v-if="!kycStatus">Please verify your identity by uploading a photo of
                                   your ID card or passport.</span>
                              <span v-if="kycStatus == 2">We couldn't verify your ID. Please try uploading a
                                   clear photo of your ID card or passport again.</span>
                              <span v-if="kycStatus == 1">Your ID verification is successful.</span>
                              <span v-if="kycStatus == 0">We're verifying your identity, just a moment!</span>
                         </div>
                    </div>
                    <div class="self-center">
                         <button
                              v-if="!kycStatus || kycStatus == 2"
                              @click="openUploadId"
                              class="hover:scale-105 transition-transform text-black border-2 border-gray-170 focus:ring-1 focus:outline-none focus:ring-blue-300 font-semibold rounded-lg text-md px-5 pb-2 pt-3 text-center uppercase tracking-wider font-teko text-sm leading-tight"
                         >

                              <svg
                                   width="22"
                                   height="22"
                                   viewBox="0 0 22 22"
                                   fill="none"
                                   xmlns="http://www.w3.org/2000/svg"
                              >
                                   <g clip-path="url(#clip0_3294_12999)">
                                        <path
                                             d="M12.375 4.69639V15.1249C12.375 15.8854 11.7605 16.4999 11 16.4999C10.2395 16.4999 9.625 15.8854 9.625 15.1249V4.69639L6.47109 7.85029C5.93398 8.3874 5.06172 8.3874 4.52461 7.85029C3.9875 7.31318 3.9875 6.44092 4.52461 5.90381L10.0246 0.403809C10.5617 -0.133301 11.434 -0.133301 11.9711 0.403809L17.4711 5.90381C18.0082 6.44092 18.0082 7.31318 17.4711 7.85029C16.934 8.3874 16.0617 8.3874 15.5246 7.85029L12.375 4.69639ZM2.75 15.1249H8.25C8.25 16.6417 9.4832 17.8749 11 17.8749C12.5168 17.8749 13.75 16.6417 13.75 15.1249H19.25C20.7668 15.1249 22 16.3581 22 17.8749V19.2499C22 20.7667 20.7668 21.9999 19.25 21.9999H2.75C1.2332 21.9999 0 20.7667 0 19.2499V17.8749C0 16.3581 1.2332 15.1249 2.75 15.1249ZM18.5625 19.5937C18.836 19.5937 19.0983 19.485 19.2917 19.2916C19.4851 19.0982 19.5938 18.8359 19.5938 18.5624C19.5938 18.2889 19.4851 18.0266 19.2917 17.8332C19.0983 17.6398 18.836 17.5312 18.5625 17.5312C18.289 17.5312 18.0267 17.6398 17.8333 17.8332C17.6399 18.0266 17.5312 18.2889 17.5312 18.5624C17.5312 18.8359 17.6399 19.0982 17.8333 19.2916C18.0267 19.485 18.289 19.5937 18.5625 19.5937Z"
                                             fill="#D1D5DB"
                                        />
                                   </g>
                                   <defs>
                                        <clipPath id="clip0_3294_12999">
                                             <rect
                                                  width="22"
                                                  height="22"
                                                  fill="white"
                                             />
                                        </clipPath>
                                   </defs>

                              </svg>
                         </button>
                    </div>
               </div>
          </div>
     </div>
</template>
<script>
import { baseUrl } from "config/services.js";
export default {
     props: ['isUser', 'kycStatus', 'openUploadId'],
     setup() {
          return {
               baseUrl
          };
     },
};
</script>
