<template>
     <div class="container" :id="id ?? ''">
          <h1 class="font-helvetica tracking-wider text-white text-xl uppercase">{{title}}!</h1>
          <p class="font-helveticaLight tracking-wide text-white text-sm"> {{message}} </p>
     </div>
</template>
<script>
export default {
     props: ["title", "message", "id"],
};
</script>
