<template>

     <Head title="SC Promotional Rules"></Head>
     <div class="home-wrapper mt-16 md:mt-22 lg:mt-0">
          <div class="z-0 w-full h-100-vh welcome-bg-container bg-background-2 fixed top-0 left-0"></div>
          <div class="banner flex bg-no-repeat w-full font-helveticaLight flex-col justify-center items-center relative">
               <div class="text-center">
                    <div class="pt-18 md:pt-22 lg:pt-28 text-white font-helvetica tracking-wide text-center text-4xl xs:text-6xl sm:text-7xl md:text-7xl xl:text-8xl leading-3xl xs:leading-5xl sm:leading-6xl md:leading-6xl xl:leading-7.5xl uppercase px-2">
                        SC Promotional Rules
                    </div>
                    <div class="text-white font-helvetica text-lg xs:text-xl sm:text-2xl tracking-wide md:text-3xl lg:text-4xl xl:text-5xl font-semibold text-center px-2 leading-5xl xs:leading-5xl sm:leading-6xl md:leading-6xl xl:leading-7.5xl uppercase">
                         (Promo Rules)
                    </div>
               </div>
               <div class="w-full flex justify-center items-center pb-20">
                    <div class="py-14 sm:py-16 md:py-20 lg:py-28 px-4 xs:px-10 sm:px-20 lg:px-24 xl:px-36 2xl:px-64">
                         <div v-if="!loading" class="pb-2" v-html="content"></div>
                         <loading-small v-else :isLoading="loading" />
                         <!-- <div class="bg-white rounded-xl py-6 sm:py-12 md:py-20 lg:py-28 px-6 xs:px-10 sm:px-20 lg:px-24 xl:px-36 2xl:px-48 shadow-xl">
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   Moshy Gaming LLC (“Moshy”) provides a social casino platform (“Services”) through <a href="https://www.moozi.com" class="text-primal-blue" target="_blank" rel="noopener noreferrer">https://www.moozi.com</a> and any associated websites and mobile versions (“Moozi”, “us”, “our”, “ours”, etc.), where users can participate in games (“Games”) for promotion and entertainment only. Users can earn SCs Coins (“SC”) by participating in specific qualifying activities (“Promotions”). SC are exclusively used to play Games where users have the chance to win various prizes (“Prizes”). To partake in Games with SC, users must consent to the <a href="https://www.moozi.com/terms-of-use" class="text-primal-blue" target="_blank" rel="noopener noreferrer">SC Promotional Rules</a>, and with all terms defined therein. It is important to note that all Promotions adhere to applicable national, federal, state, and local laws and regulations. The redeemal of any Prize is subject to fulfilling the requirements detailed in this Agreement.
                              </p>
                              <br />
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   It's essential to emphasize that NO PURCHASE OR PAYMENT IS NECESSARY TO PARTICIPATE IN THE PROMOTIONS OR TO WIN PRIZES. MOOZI DOES NOT ENGAGE IN REAL MONEY GAMING. USERS ACKNOWLEDGE AND ACCEPT THAT GOLD COINS (“GC”) CANNOT BE CONVERTED INTO CASH, GIFT CARDS, REWARDS, OR ANY OTHER VALUABLE PRIZES, AND THAT GC HOLD NO REAL-WORLD OR MONETARY VALUE.
                              </p>

                              <br /><br />
                              <p class="mb-3 font-bold text-base sm:text-lg lg:text-xl">
                                   <span class="mr-5">I.</span><span class="underline underline-offset-2">Qualification Criteria</span>
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   Participation in the Promotions is limited to:
                              </p>
                              <br />
                              <ul class="ml-8 list-disc">
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg">
                                        <span>(i) </span>individuals of legal age;
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg">
                                        <span>(ii) </span>who have reached at least eighteen (18) years of age or the minimum age required in their jurisdiction at the time of entry, whichever is higher; and
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg">
                                        <span>(iii) </span>possess an active account on the Site accompanied by a verified email address.
                                   </li>
                              </ul>
                              <br />
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   However, the Promotions are not accessible to individuals:
                              </p>
                              <br />
                              <ul class="ml-8 list-disc">
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg">
                                        <span>(i) </span>residing in jurisdictions where the Services are prohibited by law;
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg">
                                        <span>(ii) </span>residing outside of the United States;
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg">
                                        <span>(iii) </span>residing in Washington, Nevada, Idaho, or Michigan;
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg">
                                        <span>(iv) </span>listed on the Treasury Department's roster of Specially Designated Nationals, or
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg">
                                        <span>(v) </span>individuals who are, or are immediate family members of, employees, distributors, associates, concessionaires, or other representatives of Moozi.
                                   </li>
                              </ul>

                              <br /><br />
                              <p class="mb-3 font-bold text-base sm:text-lg lg:text-xl">
                                   <span class="mr-5">II.</span><span class="underline underline-offset-2">Promotion Duration</span>
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   The Promotions are simultaneous with the sale of GC to our users for entertainment purposes. Our servers are the official timekeeping mechanism for the Promotions. The Promotions will remain active until:
                              </p>
                              <br />
                              <ul class="ml-8 list-disc">
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg">
                                        <span>(i) </span>December 31, 2024, or
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg">
                                        <span>(ii) </span>Moozi issues a thirty (30) day notice of termination, whichever occurs earlier.
                                   </li>
                              </ul>
                              <br />
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   Upon conclusion of the current Promotion, Moozi reserves the right to initiate a new Promotion. Moozi also retains the right to terminate, pause, amend, or suspend any portion or the entirety of the Promotions immediately due to events or circumstances beyond its reasonable control. Any event or circumstance that compromises the proper functioning or integrity of the Promotions will be assessed by Moozi, and corrective measures will be implemented at Moozi's sole discretion.
                              </p>

                              <br /><br />
                              <p class="mb-3 font-bold text-base sm:text-lg lg:text-xl">
                                   <span class="mr-5">III.</span><span class="underline underline-offset-2">SC Coins ("SC")</span>
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   SC may be obtained through various methods, including:
                              </p>
                              <br />
                              <ul class="ml-8">
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg">
                                        <span>(i) </span>Receiving 0.3 complimentary SC for daily logins;
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg">
                                        <span>(ii) </span>Alternative Method of Entry (“AMOE”): Acquiring 5 complimentary SC by mailing a request to Moshy Gaming PO Box 8206, Manchester, NH 03108  in a standard, unfolded, blank, lineless, legibly handwritten 4”x 6” postcard or white paper, placed inside a stamped envelope, including the email address associated with your account, full name as indicated on your government-issued ID, your residential address linked to your account, and the statement: “I wish to receive 5 complimentary SC to participate in the promotion offered by Moozi and I agree to be bound by the Terms of Service of Moozi.” The request must be sent from the same state as the residential address registered with your account. Furthermore, there must be a unique code attached to the mailing request. Each code is unique and can be accessed easily from the Moozi website; or
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg">
                                        <span>(iii) </span>Purchasing designated packages of GC (amount varies depending on the selected package).
                                   </li>
                              </ul>
                              <br />
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   Restrictions to AMOE are limited to the following: only one request card is allowed per outer envelope. The request must be mailed from the same state as the user’s registered address.  Each postcard request must also include a unique access code obtained from the website, which allows the Company to track the requests and provides for more efficient processing of user requests.
                              </p>
                              <br />
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   Further, (i) SC are automatically added to the user's account upon verification of the AMOE submission, (ii) the Company promptly processes each AMOE request upon verification and it is generally completed within seven (7) days however, it can take up to fourteen (14) days from receipt of the request card to award the SC to the user’s account, (iii) the Company will clearly and conspicuously advertise the available AMOE method of entry (including advertising on their website), (iv) the AMOE entries are unlimited, (v) the AMOE entries have the exact same chance of winning as entries obtained as a promotional bonus, and (vi) the Company will also periodically monitor the average entry fee size to ensure that the AMOE SC awarded is equitable and maintains a level of responsible gaming.
                              </p>
                              <br />
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   Furthermore, each AMOE submission is valid for sixty (60) days from the date the player last logged onto their account.
                              </p>
                              <br />
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   Moozi retains the right to restrict or modify the available payment methods for purchasing GC and the authority to adjust the SC balance in your account at its discretion, including the removal of SC associated with inactive accounts.
                              </p>

                              <br /><br />
                              <p class="mb-3 font-bold text-base sm:text-lg lg:text-xl">
                                   <span class="mr-5">IV</span><span class="underline underline-offset-2">Games</span>
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   SC may be utilized to participate in Games offering opportunities to win Prizes. The SC amount required to participate in each Game varies and is prominently displayed on the Services.
                              </p>

                              <br /><br />
                              <p class="mb-3 font-bold text-base sm:text-lg lg:text-xl">
                                   <span class="mr-5">V.</span><span class="underline underline-offset-2">Probabilities</span>
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   SC may be utilized to participate in Games offering opportunities to win Prizes. The SC amount required to participate in each Game varies and is prominently displayed on the Services.
                              </p>

                              <br /><br />
                              <p class="mb-3 font-bold text-base sm:text-lg lg:text-xl">
                                   <span class="mr-5">VI.</span><span class="underline underline-offset-2">Prizes</span>
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   To qualify for Prizes, you must utilize your SC in gameplay at least once. Upon accumulating a minimum of 100 sufficiently utilized SC, you may redeem Prizes at a conversion rate of one (1) SC to one (1) United States dollar. These Prizes will be credited to the account used for GC purchases (or to an alternative bank account or wallet as chosen by you, if technically feasible) provided that you are the rightful owner of said account. Our determination regarding the operation of the Game and Prize allocation is conclusive and binding. Any breach of our Terms of Service renders any Prize void.
                              </p>
                              <br />
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   Note that for users in the state of New York and Florida, Prizes will be limited to $5,000 maximum per prize.
                              </p>
                              <br />
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   As stated in the Officer’s Certificate, all SC Game outcomes and winners are subject to verification by the Company, whose decisions are final and binding in all matters related to SC Games. The Certificate also states that a user is not deemed a “winner” of a prize, unless and until their account has been verified.
                              </p>
                              <br />
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   As part of AMOE verification and overall compliance, ZIP codes will be checked and must match from last registered address.
                              </p>

                              <br /><br />
                              <p class="mb-3 font-bold text-base sm:text-lg lg:text-xl">
                                   <span class="mr-5">VII.</span><span class="underline underline-offset-2">Taxation and Fees</span>
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   Moozi assumes no responsibility for any taxes, bank fees, or transaction charges associated with Prizes. You bear full responsibility for settling any taxes, bank fees, or transaction charges linked to the Prizes.
                              </p>

                              <br /><br />
                              <p class="mb-3 font-bold text-base sm:text-lg lg:text-xl">
                                   <span class="mr-5">VIII.</span><span class="underline underline-offset-2">Authentication</span>
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   Prior to Prizes being released, Moozi may request specific verification documentation and details, along with the signing of specific forms within a seven (7) day timeframe. Failure to furnish the requested documentation, details, or signed forms promptly, or if we ascertain that you have violated our Terms of Service, will result in forfeiture of the Prize, and we reserve the right to suspend or terminate your account. You acknowledge that Moozi bears no responsibility for any delays in Prize disbursement.
                              </p>

                              <br /><br />
                              <p class="mb-3 font-bold text-base sm:text-lg lg:text-xl">
                                   <span class="mr-5">IX.</span><span class="underline underline-offset-2">Release</span>
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   You agree to absolve Moozi, along with our employees, customers, subsidiaries, affiliated entities, agents, media collaborators, officers, directors, content providers, and associated parties, from any and all liability, costs, expenses, and damages arising from (i) your acceptance of any Prize, (ii) your utilization of any materials on the Site, (iii) your engagement in the Games and Promotions, and (iv) your other interactions with the Services.
                              </p>

                              <br /><br />
                              <p class="mb-3 font-bold text-base sm:text-lg lg:text-xl">
                                   <span class="mr-5">X.</span><span class="underline underline-offset-2">Public Representation</span>
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   Upon claiming any Prize, you authorize Moozi to utilize your name, likeness, opinions, remarks, voice, and appearance for promotional purposes across all media platforms, globally, without prior notice or additional compensation, except where prohibited by law.
                              </p>

                              <br /><br />
                              <p class="mb-3 font-bold text-base sm:text-lg lg:text-xl">
                                   <span class="mr-5">XI.</span><span class="underline underline-offset-2">Liability and
                                   Disqualification</span>
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   Moozi reserves the right to seek damages from any individual found to be acting in bad faith to disrupt the lawful operation of the Promotions to the fullest extent permissible by law. Any violation of our Terms of Service may result in disqualification from the Promotions.
                              </p>

                              <br /><br />
                              <p class="mb-3 font-bold text-base sm:text-lg lg:text-xl">
                                   <span class="mr-5">XII.</span><span class="underline underline-offset-2">Arbitration</span>
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   In the event of a disagreement with Moozi regarding Prize payments or these Moozi Rules, both parties must engage in sincere discussions to resolve the dispute amicably. If direct negotiations fail to resolve the matter, either party must refer the issue to binding, confidential arbitration, following the prevailing Commercial Arbitration Rules of the American Arbitration Association. Arbitral claims encompass contract and tort claims of all kinds, as well as those based on any federal, state, or local law, statute, or regulation, excluding claims related to intellectual property infringement, injunctions, attachment, garnishment, and equitable relief. The arbitration proceedings will take place in Delaware, overseen by a sole arbitrator well-versed in Internet and e-commerce disputes, willing to pledge impartiality. The arbitrator is not empowered to award punitive or exemplary damages, certify a class, add parties, deviate from the terms of this Agreement, and must adhere to governing laws. Within thirty (30) days of concluding the arbitration, the arbitrator will issue a written opinion detailing all material facts and the rationale behind the decision. The arbitration award is enforceable in any jurisdiction, under relevant laws. No waiver of the right to arbitration is valid unless expressly provided in writing by the waiving party. No actions, including litigation filings, shall be construed as a waiver or abandonment of the arbitration right.
                              </p>

                              <br /><br />
                              <p class="mb-3 font-bold text-base sm:text-lg lg:text-xl text-center">
                                   THE PROMOTION IS VOID WHERE PROHIBITED
                              </p>
                         </div> -->
                    </div>
               </div>
          </div>
     </div>
</template>
<script>
import { Head } from "@inertiajs/vue3";
import { ref,computed, onMounted } from "vue";
import { policyStore } from "store/policy.js";
import _ from "underscore";
import LoadingSmall from "components/loading/loadingSmall.vue";
import { useScroll } from "composables/use-scroll.js";

export default {
     components: {
          Head,
          LoadingSmall,
     },
     setup() {
          const slug = 'privacy';
          
          const { scrollTop } = useScroll();

          const usePolicyStore = policyStore()

          const loading = ref(false);

          const content = computed(() => {
               if (usePolicyStore.policy) {
                    const filter = _.find(usePolicyStore.policy, function (params) {
                         return params['slug'] == slug
                    })

                    return filter ? filter.content : '';
               }

               return ''
          })

          onMounted(async () => {
               loading.value = true
               scrollTop();
               await usePolicyStore.getPolicy();
               loading.value = false
          })

          return {
               content,
               loading
          };
     },
};
</script>
