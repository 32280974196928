<template>
  <transition
    enter-from-class="scale-0"
    enter-active-class="ease-in transition-medium"
    enter-to-class="scale-1"
    leave-from-class="scale-1"
    leave-active-class="ease-out transition-medium"
    leave-to-class="scale-0"
  >
    <div
      v-if="isOpenDelete"
      id="authentication-modal"
      tabindex="-1"
      aria-hidden="true"
      class="overflow-y-auto overflow-x-hidden fixed z-80 w-full inset-0 max-h-full transform ease-in-out transition-all duration-300 self-center w-full m-auto inset-0 font-helveticaLight flex justify-center items-center"
    >
      <div
        class="relative px-6 py-3 sm:py-4 sm:px-10 w-full max-w-lg max-h-full m-auto"
      >
        <!-- Modal content -->
        <div class="relative bg-shade-1 rounded-xl shadow-xl">
          <button
            @click="closeModal()"
            type="button"
            class="close-btn-c absolute top-2.5 end-2.5 rounded-xl text-sm ms-auto inline-flex justify-center items-center"
            data-modal-hide="authentication-modal"
          >
            <img :src="baseUrl.img+'/assets/img/modal/exclude.svg'" alt="" />
            <span class="sr-only">Close modal</span>
          </button>
          <div
            class="flex flex-col divide-y divide-shade-12 items-center justify-center py-6 lg:py-8 font-roboto text-shade-2 font-bold text-sm xs:text-base px-4 sm:px-6 lg:px-8 tracking-wide leading-tight"
          >
            <div class="pb-4 w-full text-center">
              <h3 class="text-base xs:text-lg font-bold text-gray-900">
                Are you sure you want to delete this card?
              </h3>
            </div>
            <div class="py-2 xs:py-4 w-full">
              <div class="flex flex-col gap-2 sm:gap-4 lg:gap-6 px-2">
                <div class="uppercase mb-1 xs:mb-3">Credit Card Details</div>
                <div class="flex flex-wrap items-start gap-2 sm:gap-3 lg:gap-4">
                  <div class="flex-1 flex flex-col items-start">
                    <span class="text-shade-7">Card holder's name</span>
                    <span>{{ selectedCard?.card_name }}</span>
                  </div>
                  <div class="flex-1 flex flex-col items-start">
                    <span class="text-shade-7">Card type</span>
                    <span>{{ getCardName(selectedCard?.paypage?.type) }}</span>
                  </div>
                </div>
                <div class="flex flex-wrap items-start gap-2 sm:gap-3 lg:gap-4">
                  <div class="flex-1 flex flex-col items-start">
                    <span class="text-shade-7">Card number</span>
                    <span>************{{ selectedCard?.paypage?.lastFour }}</span>
                  </div>
                  <div class="flex-1 flex flex-col items-start">
                    <span class="text-shade-7">Expiry Date</span>
                    <span>{{ selectedCard?.paypage?.card_expiration }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="pt-2 xs:pt-4 text-center w-full font-helveticaLight">
              <!-- <div class="pb-4 xs:pb-6 px-2">
                <div
                  class="text-xs xs:text-sm flex flex-wrap items-center font-normal"
                >
                  <div class="flex-1 flex flex-col items-start">
                    <span class="text-base xs:text-lg lg:text-xl font-bold"
                      >Total</span
                    >
                  </div>
                  <div class="flex-1 flex flex-col items-end">
                    <span class="text-base xs:text-lg lg:text-xl font-bold"
                      >${{ money(coins?.total) }}</span
                    >
                  </div>
                </div>
              </div> -->
              <div class="px-2 flex w-full gap-4">
                <button
                  @click="closeModal()"
                  class="w-full text-primary-1 bg-gray-190 px-4 py-3 xl:py-3.5 text-center leading-tight shadow-xl rounded-full font-bold"
                >
                  Cancel
                </button>
                <button
                  @click="deleteCard(id)"
                  class="w-full text-shade-1 bg-shade-17 px-4 py-3 xl:py-3.5 text-center leading-tight shadow-xl rounded-full font-bold"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <loading-overlay :isLoading="loading" />
  <div
    v-if="isOpenDelete"
    class="fixed inset-0 z-60"
    style="background-color: rgba(0, 0, 0, 0.3)"
  ></div>
</template>

    <script>
import { onMounted, onUnmounted, ref } from "vue";
import eventBus from "plugins/event.js";
import LoadingOverlay from "components/loading/loadingOverlay.vue";
import { useRouter } from "vue-router";
import { utilitiesStore } from "store/utilities.js";
import { deleteCard as deleteCardAPI } from "api/auth.js";
import { useToaster } from "composables/use-toaster.js";
import { cardStore } from "store/cards";
import { useFormatter } from "composables/use-formatter.js";
import { baseUrl } from "config/services.js";

export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
    coins: {
      type: Object,
      required: true,
    },
    selectedCard: {
      type: Object,
      required: true,
    },
  },
  components: {
    LoadingOverlay,
  },
  setup(props, { emit }) {
    const { money } = useFormatter();
    const isOpenDelete = ref(false);
    const loading = ref(false);
    const useUtilitiesStore = utilitiesStore();
    const useCardStore = cardStore();
    const { successToast, errorToast } = useToaster();

    const toggleCreditDeleteConfirmation = () => {
      isOpenDelete.value = !isOpenDelete.value;
    };

    const closeModal = () => {
      isOpenDelete.value = false;
      useUtilitiesStore.enableScroll();
    };

    const deleteCard = async (id) => {
        loading.value = true;
        const params = {
            card_id: id,
        };

        await deleteCardAPI(params)
            .then( async (res) => {
                    await useCardStore.getCards(true);
                    loading.value = false;
                    successToast("Successfully deleted", "top-right");
                    closeModal();
            })
            .catch(({ response }) => {
                    loading.value = false;
                    errorToast("Failed to delete card", "top-right");
                    closeModal();
            });
    };

    const getPercentage = (discount, amount) => {
        return money(amount - (discount / 100) * amount);
    };

    const getCardName = (type) => {
        return useUtilitiesStore.getCardTypeName(type);
    };

    onMounted(async () => {
      eventBus.on("open:deleteconfirmation", () => {
        useUtilitiesStore.disableScroll();
        toggleCreditDeleteConfirmation();
      });
    });

    onUnmounted(() => {
      eventBus.off("open:deleteconfirmation");
    });

    return {
      loading,
      isOpenDelete,
      closeModal,
      deleteCard,
      money,
      getPercentage,
      getCardName,
      baseUrl
    };
  },
};
</script>
