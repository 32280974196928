import { createRouter, createWebHistory } from "vue-router";
import { authStore } from "store/auth.js";
import checkAuth from 'middleware/checkAuth.js';

import routes from "./routes";


const router = createRouter({
    history: createWebHistory(),
    routes,
});

// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Than run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  const globalMiddleware = [checkAuth];
  const useAuthStore = authStore();


  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  if(globalMiddleware.length){
    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, globalMiddleware, 1);
    return globalMiddleware[0]({ ...context, next: nextMiddleware });
  }

  useAuthStore.getUser();
  return next();
});


export default router;
