<template>

     <Head title="Player Safety"></Head>
     <div class="home-wrapper mt-16 md:mt-22 lg:mt-0">
          <div class="z-0 w-full h-100-vh welcome-bg-container bg-background-2 fixed top-0 left-0"></div>
          <div class="banner flex bg-no-repeat w-full font-helveticaLight flex-col justify-center items-center relative">
               <div class="text-center">
                    <div class="pt-18 md:pt-22 lg:pt-28 text-white font-helvetica tracking-wide text-center text-4xl xs:text-6xl sm:text-7xl md:text-7xl xl:text-8xl leading-3xl xs:leading-5xl sm:leading-6xl md:leading-6xl xl:leading-7.5xl uppercase px-2">
                        Responsible Play Policy
                    </div>
               </div>
               <div class="w-full flex justify-center items-center pb-20">
                    <div class="py-14 sm:py-16 md:py-20 lg:py-28 px-4 xs:px-10 sm:px-20 lg:px-24 xl:px-36 2xl:px-64">
                         <div v-if="!loading" class="pb-2" v-html="content"></div>
                         <loading-small v-else :isLoading="loading" />
                         <!-- <div class="bg-white rounded-xl py-6 sm:py-12 md:py-20 lg:py-28 px-6 xs:px-10 sm:px-20 lg:px-24 xl:px-36 2xl:px-48 shadow-xl">
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   Moshy Gaming LLC (the “<span class="font-bold">Company</span>”) adheres to the International Social Games Association’s (“<span class="font-bold">ISGA</span>”) Best Practice Principles (the “<span class="font-bold">Principles</span>”) as well as all pertinent consumer protection, competition, advertising and privacy regulations.
                              </p>
                              <br />
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   We are committed to responsible social play principles. We do not provide gambling services, engage in illegal betting activities, or endorse unlawful gambling practices. We offer engaging social casino games and associated game promotions, in compliance with state and federal gambling laws. By using the Services, you agree not to contravene the laws of your state, or country. We advise consulting with local authorities or legal advisors before partaking in any form of online play. It is solely your responsibility to adhere to all applicable laws, and you assume all associated risks by utilizing the Services. Content published on Moozi.com should not be construed as legal counsel on any matter. We hold no liability for your engagement in illegal gambling activities through the Services, and we do not condone such behavior. It is acknowledged and agreed that the Services are solely for entertainment purposes. We do not guarantee the legality of the Services in your jurisdiction.
                              </p>
                              <br />
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   We have instituted policies and safeguards empowering you to govern your social gameplay. We reserve the right to activate these measures unilaterally if deemed necessary at our sole discretion.
                              </p>

                              <br /><br />
                              <p class="mb-3 font-bold text-base sm:text-lg lg:text-xl">
                                   <span class="mr-5">I.</span>
                                   <span class="underline underline-offset-2">Guidelines</span>
                              </p>
                              <p class="mt-5 mb-3 text-sm sm:text-base lg:text-lg flex flex-ror">
                                   <span class="mr-5">a)</span>
                                   The Smart Mobile Gamers website, accessible at https://smartmobilegamers.org/, serves as a valuable resource offering tools and guidance aimed at fostering a positive play experience. Here's an overview of the resources available:
                              </p>
                              <ul class="ml-16 list-none">
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Basic information: The portal provides fundamental insights into social games and offers guidance on gameplay mechanics.
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Gameplay management tools: Users are equipped with tools to manage their play experiences effectively. These include features to monitor in-game spending, disable notifications, and utilize age ratings.
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Expert advice: Users can access expert advice on addressing various challenges encountered during mobile play, including managing gameplay time and dealing with unpleasant interactions.
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Directory of resources: A comprehensive directory of additional online resources is available for users seeking further assistance or information about mobile games.
                                   </li>
                              </ul>
                              <br />
                              <p class="mb-3 text-sm sm:text-base lg:text-lg">
                                   <span class="mr-5">b)</span>
                                   Purchases monitoring:
                              </p>
                              <ul class="ml-16 list-none">
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Regularly monitor your account to review activities.
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Practice responsible budgeting and adhere to your financial limits; refrain from exceeding your planned spending.
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Avoid using Moozi.com to address financial debts using potential winnings.
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Familiarize yourself with the rules and regulations governing games on Moozi.com.
                                   </li>
                              </ul>
                              <br />
                              <p class="mb-3 text-sm sm:text-base lg:text-lg">
                                   <span class="mr-5">c)</span>
                                   Time allocation:
                              </p>
                              <ul class="ml-16 list-none">
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Acknowledge that online play is a form of entertainment and should be enjoyed in moderation, avoiding excessive play to escape reality.
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Refrain from play if it interferes with your daily responsibilities, if you are in recovery for dependency or addiction, or if you are under the influence of intoxicating substances.
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Establish accountability by seeking support from friends or online groups to help you adhere to your online goals.
                                   </li>
                              </ul>
                              <br />
                              <p class="mb-3 text-sm sm:text-base lg:text-lg">
                                   <span class="mr-5">d)</span>
                                   We provide a range of gameplay management tools:
                              </p>
                              <ul class="ml-16 list-none">
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Responsible social gameplay features: We encourage responsible play habits and offer various features to support users in this endeavor.
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Session reminders: Moozi.com offers session reminders, sent periodically, to assist users in managing their play time effectively. These reminders suspend play, display play history, and provide options to either continue playing or end the session.
                                   </li>
                                   <p class="italic my-5">Limits</p>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Self-Limit policy: Users have the option to request in writing the revocation of specific privileges, such as access to games or the ability to purchase gold coins (“GC”), through our self-limit policy and procedures.
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Daily limits: Users can set daily limits on the number of GC packages they can purchase and the amount of SCs (“SC”) they can use for play. This feature helps users control their spending and gameplay frequency.
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>
                                        <span>
                                             To set up session reminders and daily limits, users can contact us at <a class="text-primal-blue underline" href="mailto:support@moozi.com">support@moozi.com</a> or follow the instructions on the website and complete the appropriate form. Note that the process may require time to take effect. It is the user's responsibility to notify us if the limits have not been applied after communication of their implementation.
                                        </span>
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>
                                        <span>
                                             The Company reserves the right to exclude users from accessing Moozi.com without a request from the user. Users can also change or revoke the set limits by contacting us at <a class="text-primal-blue underline" href="mailto:support@moozi.com">support@moozi.com</a> or completing the relevant forms provided on the website. These measures aim to ensure that users can enjoy Moozi.com responsibly.
                                        </span>
                                   </li>
                              </ul>
                              <br />
                              <p class="mb-3 text-sm sm:text-base lg:text-lg">
                                   <span class="mr-5">e)</span>
                                   Safeguard against unauthorized account access
                              </p>
                              <ul class="ml-16 list-none">
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Strong password: Utilize a strong password and ensure its confidentiality to prevent unauthorized access. Aim for passwords that are at least 16 characters long and that contain a diverse character combination. Avoid using the same password across multiple accounts real words, or personal information.
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>
                                        <span>
                                             If you suspect unauthorized access to your password or account, promptly notify us at <a class="text-primal-blue underline" href="mailto:support@moozi.com">support@moozi.com</a> and proceed to reset your password to prevent further security breaches.
                                        </span>
                                   </li>
                              </ul>
                              <br />
                              <p class="mb-3 text-sm sm:text-base lg:text-lg">
                                   <span class="mr-5">f)</span>
                                   Account closure and self-exclusion
                              </p>
                              <p class="mt-5 mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   The below measures can be taken to close or suspend an account. The measures will only take effect upon full implementation, communicated to you accordingly.
                              </p>
                              <br />
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   Please be aware that we reserve the right to suspend, delete, or exclude your account at our discretion, without prior notice. Any associated charges will remain your responsibility upon termination. Terminated account information may be permanently deleted at our discretion.
                              </p>
                              <br />
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   The lifting of suspensions, account deletions, or self-exclusions will occur three months after receiving a written email request to ignore the previous action. Once these measures are active, you will cease to receive marketing materials from us. We strongly advise seeking exclusion from other social gameplay platforms and ensuring you do not receive our communications via social media channels.
                              </p>
                              <br />
                              <ul class="ml-16 list-none">
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>
                                        <span>
                                             Temporary suspension: If you wish to temporarily suspend your account for a specific duration, kindly send your request to <a class="text-primal-blue underline" href="mailto:support@moozi.com">support@moozi.com</a>. Ensure your request includes your email address, account details, and the desired suspension period. Following the designated period, your access to the moozi.com platform will be reinstated.
                                        </span>
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>
                                        <span>
                                             Indefinite suspension: Should you prefer an indefinite suspension of your account, please email your request to <a class="text-primal-blue underline" href="mailto:support@moozi.com">support@moozi.com</a>, including your email address and account details. An indefinite suspension necessitates a written email request for account reactivation.
                                        </span>
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Account deletion: To permanently discontinue your use of Moozi.com, navigate to your account settings and select "Delete Account" to initiate the deletion process. Please note that this is irreversible.
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>
                                        <span>
                                             Self-Exclusion: To preemptively prevent future account creation, email your request to <a class="text-primal-blue underline" href="mailto:support@moozi.com">support@moozi.com</a>, including your full name, email address, residential address, and date of birth.
                                        </span>
                                   </li>
                              </ul>

                              <br /><br />
                              <p class="mb-3 font-bold text-base sm:text-lg lg:text-xl">
                                   <span class="mr-5">II.</span>
                                   <span class="underline underline-offset-2">Self-Assessment</span>
                              </p>
                              <p class="mt-5 mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   If you suspect that computer gameplay has become problematic for you or someone else, consider the following self-assessment questions:
                              </p>
                              <br />
                              <ul class="ml-16 list-none">
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">•</span>Do you often relive gameplay experiences or think about future ones?
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">•</span>Do you hide or lie about your gameplay?
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">•</span>Do you become very angry when someone or something interrupts a game?
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">•</span>Have you ever taken a break from gameplay only to binge uncontrollably upon your return?
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">•</span>When upset, do you soothe yourself with games or plans to game?
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">•</span>Do you find yourself playing games in the early morning?
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">•</span>Do you seek ways to play games when away from home?
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">•</span>Do you set limits with gameplay and then break them, playing hours longer than intended?
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">•</span>Do you sacrifice hours of sleep for gameplay?
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">•</span>Have you called in sick or arrived late to work or skipped classes to game?
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">•</span>Have you sworn off a game, deleted your account, and later returned to it?
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">•</span>Do you feel guilt and shame surrounding your gameplay?
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">•</span>Does your gameplay contribute to arguments in your relationships?
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">•</span>Has your gameplay replaced hobbies or sports you used to enjoy?
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">•</span>Do you forget appointments, responsibilities, or deadlines at work or school due to online play?
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">•</span>Do you become defensive when others suggest you might be playing online too much?
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">•</span>Have your hours spent playing online increased over time?
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">•</span>Do you prioritize playing online games over social events?
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">•</span>Have you lost contact with friends and family due to online play?
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">•</span>Do you experience intense emotions (highs, lows, anger, fear) when playing online?
                                   </li>
                              </ul>
                              <br />
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   The more "yes" answers you provide, the more likely it is that you are experiencing difficulties with your gameplay. If you need advice and support, please reach out to the Support Organization referenced in the next section.
                              </p>

                              <br /><br />
                              <p class="mb-3 font-bold text-base sm:text-lg lg:text-xl">
                                   <span class="mr-5">III.</span>
                                   <span class="underline underline-offset-2">Support</span>
                              </p>
                              <p class="mt-5 mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   Reaching out to a professional can be instrumental in understanding the role social games play in your life and developing strategies to find alternative activities that serve similar functions. Many clinicians offer free initial phone consultations, and there are various resources available to help you find a professional in your area.
                              </p>
                              <br />
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   Additionally, online support groups can be valuable supplements to in-person treatment or alternatives when in-person help is unavailable. Some reputable online support groups for video game addictions include:
                              </p>
                              <br />
                              <ul class="ml-16 list-none">
                                   <li
                                        style="flex-flow: wrap"
                                        class="mb-2 text-sm sm:text-base lg:text-lg"
                                   >
                                        <span class="mr-5">-</span>On-Line Gamers Anonymous (OLGA) -
                                        <a
                                             class="text-primal-blue ml-1 underline"
                                             style="overflow-wrap: anywhere"
                                             href="https://www.olganon.org/home"
                                             target="_blank"
                                             rel="noopener noreferrer"
                                        >https://www.olganon.org/home</a>
                                   </li>
                                   <li
                                        style="flex-flow: wrap"
                                        class="mb-2 text-sm sm:text-base lg:text-lg"
                                   >
                                        <span class="mr-5">-</span>Game Quitters -
                                        <a
                                             class="text-primal-blue ml-1 underline"
                                             style="overflow-wrap: anywhere"
                                             href="https://gamequitters.com/"
                                             target="_blank"
                                             rel="noopener noreferrer"
                                        >https://gamequitters.com</a>
                                   </li>
                                   <li
                                        style="flex-flow: wrap"
                                        class="mb-2 text-sm sm:text-base lg:text-lg"
                                   >
                                        <span class="mr-5">-</span>Recovery.org -
                                        <a
                                             class="text-primal-blue ml-1 underline"
                                             style="overflow-wrap: anywhere"
                                             href="https://www.recovery.org/forums/categories/internet-gaming-social-media"
                                             target="_blank"
                                             rel="noopener noreferrer"
                                        >https://www.recovery.org/forums/categories/internet-gaming-social-media</a>
                                   </li>
                              </ul>
                              <br />
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   Please note that these support organizations are not affiliated with Moozi.com, and Moozi.com does not guarantee the accuracy or helpfulness of any information or resources provided by these organizations. Additionally, these organizations do not offer customer support or dispute resolution services related to Moozi.com. If you have any concerns or complaints about your Moozi.com account, please contact us directly at <a class="text-primal-blue underline" href="mailto:support@moozi.com">support@moozi.com</a>.
                              </p>

                              <br /><br />
                              <p class="mb-3 font-bold text-base sm:text-lg lg:text-xl">
                                   <span class="mr-5">IV.</span>
                                   <span class="underline underline-offset-2">Protection of Minors</span>
                              </p>
                              <p class="mt-5 mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   Minors are strictly prohibited from using Moozi.com. Users who have devices easily accessible to minors, such as those with children living in the same household, are strongly advised to take additional precautions to ensure that minors do not have access to such devices.
                              </p>
                              <br />
                              <ul class="ml-16 list-none">
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>It is essential to keep your account secure to prevent unauthorized access. This can be achieved by creating and safeguarding strong passwords, as outlined in this policy.
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Never leave your computer or device unattended while logged in to Moozi.com.
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Avoid using password managers or saving your Moozi.com password on any browser or device.
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Keep your bank cards and bank account details out of reach of minors.
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Do not allow minors to participate in games on Moozi.com under any circumstances.
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>If you share a device with others, it is recommended to restrict their access to Moozi.com.
                                   </li>
                              </ul>
                         </div> -->
                    </div>
               </div>
          </div>
     </div>
</template>
<script>
import { Head } from "@inertiajs/vue3";
import { ref,computed, onMounted } from "vue";
import { policyStore } from "store/policy.js";
import _ from "underscore";
import LoadingSmall from "components/loading/loadingSmall.vue";
import { useScroll } from "composables/use-scroll.js";

export default {
     components: {
          Head,
          LoadingSmall,
     },
     setup() {
          const slug = 'privacy';
          
          const { scrollTop } = useScroll();

          const usePolicyStore = policyStore()

          const loading = ref(false);

          const content = computed(() => {
               if (usePolicyStore.policy) {
                    const filter = _.find(usePolicyStore.policy, function (params) {
                         return params['slug'] == slug
                    })

                    return filter ? filter.content : '';
               }

               return ''
          })

          onMounted(async () => {
               loading.value = true
               scrollTop();
               await usePolicyStore.getPolicy();
               loading.value = false
          })

          return {
               content,
               loading
          };
     },
};
</script>