import request from '@/utils/request';

export function getFAQ(data) {
  return request({
    url: '/utils_faq',
    method: 'get',
    data: data,
  });
}
export function getTestimonials(data) {
  return request({
    url: '/utils_testimonials',
    method: 'get',
    data: data,
  });
}