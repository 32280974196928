import request from '@/utils/request';

export function initConnectPay(data) {
    return request({
        url: '/transaction/connect_pay/init',
        method: 'post',
        data: data
    });
}

// export function enrollment(data) {
//     return request({
//         url: '/transaction/connect_pay/enrollment',
//         method: 'post',
//         data: data
//     });
// }

// export function getEnrollment() {
//     return request({
//         url: '/transaction/connect_pay/get_enrolled',
//         method: 'get',
//     });
// }

export function makePurchase(data) {
    return request({
        url: '/transaction/connect_pay/make_purchase',
        method: 'post',
        data: data
    });
}

// export function closeWallet(data) {
//     return request({
//         url: '/transaction/connect_pay/close_account',
//         method: 'post',
//         data: data
//     });
// }

export function getAccount(data) {
    return request({
        url: '/transaction/connect_pay/get_account_list',
        method: 'post',
        data: data
    });
}

export function getWallet(data) {
    return request({
        url: '/transaction/withdraw/get_account_list',
        method: 'post',
        data: data
    });
}

