<template>
     <div class="w-full">
          <p class="text-shade-8 text-xs md:text-sm lg:text-xs xl:text-sm my-5">Connect your bank account for easier payment setup with no expiration and faster refunds.</p>
          <div v-if="enrolled != null && enrolled?.account?.length">
                <p class="text-xs md:text-sm lg:text-xs xl:text-sm my-5 text-black font-bold">Current account</p>
                <div v-if="walletCount == 0 && enrolled?.account[0].accountStatus == '40'">
                        <button
                            @click="() => fetchSDK('bank')"
                            class="flex-1 w-full xl:w-6/12 rounded-full font-bold tracking-wider px-5 py-3 bg-white text-black border-2 border-solid border-black text-sm sm:text-base md:text-lg lg:text-base xl:text-lg cursor-pointer"
                        >
                            Connect your bank
                        </button>
                </div>
                <div v-else
                        class="grid grid-cols-1 lg:grid-cols-2 gap-3"
                >
                    <div
                        v-for="(item, key) in getActiveAccount(enrolled?.account)" :key="key"
                        class="w-full"
                    >
                        <div
                            @click="setSelectedWallet(item)"
                            class="flex flex-row items-end justify-between mb-4 w-full gap-2 px-4 py-3 rounded-lg cursor-pointer transition-all duration-300 ease-out bg-gray-150 border-2 border-solid"
                            :class="selectedWallet?.fdAccountID == item.fdAccountID
                                   ? 'border-2 border-background-7'
                                   : 'border border-shade-10'"
                        >
                            <div class="flex flex-row w-8/12">
                                <span class="w-full text-black cursor-pointer flex flex-col justify-end items-start text-black text-xs md:text-sm lg:text-xs xl:text-sm">
                                        <img
                                             :src="baseUrl.img+'/assets/img/card/bank-icon.png'"
                                             class="h-8"
                                        />
                                        <span class="w-full capitalize text-nowrap text-ellipsis overflow-hidden">{{ item.userIdentificationDetails.bankName }}</span>
                                        Account ending in <span class="font-bold">{{ item.userIdentificationDetails.bankAccountActualLastFour }}</span>
                                </span>
                                <!-- <span class="text-base font-medium text-shade-2 block cursor-pointer">
                                    Account No: {{item.userIdentificationDetails.accountNumber}}
                                </span>
                                <span class="text-base font-medium text-shade-2 block cursor-pointer">
                                    Status: <span
                                            class="font-normal wallet-status"
                                            :class="item.accountStatus == '10' ? 'text-primal-green' : item.accountStatus == '70' || item.accountStatus == '90' ? 'text-primal-yellow' : 'text-primal-red'"
                                    >{{item.accountStatusDesc}}</span>
                                </span> -->
                            </div>
                            <div class="flex flex-col h-full gap-3 items-end">
                                <span
                                    @click="(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            fetchSDK('edit', item.fdAccountID)
                                    }"
                                    class="cursor-pointer"
                                >
                                        <span class="text-black underline underline-offset-2 cursor-pointer flex flex-col justify-end items-start text-black text-xs md:text-sm lg:text-xs xl:text-sm">
                                            Edit
                                        </span>
                                </span>
                                <span
                                    @click="(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            fetchSDK('close', item.fdAccountID)
                                    }"
                                    class="cursor-pointer"
                                >
                                        <span class="text-black underline underline-offset-2 cursor-pointer flex flex-col justify-end items-start text-black text-xs md:text-sm lg:text-xs xl:text-sm">
                                            Remove
                                        </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
          </div>
          <div v-else-if="enrolled == null" class="h-full w-full">
               <div v-if="loading" class="flex item-center justify-center w-full pt-32">
                    <svg aria-hidden="true" class="w-8 h-8 text-black animate-spin fill-secondary-1"
                         viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                         <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"></path>
                         <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"></path>
                    </svg>
               </div>
          </div>
          <div v-else>
                <button
                    @click="() => fetchSDK('bank')"
                    class="flex-1 w-full xl:w-6/12 rounded-full font-bold tracking-wider px-5 py-3 bg-white text-black border-2 border-solid border-black text-sm sm:text-base md:text-lg lg:text-base xl:text-lg cursor-pointer"
                >
                    Connect your bank
                </button>
          </div>
          <button
                @click="openConfirmationModal()"
                :disabled="selectedWallet != null ? false : true"
               :class="selectedWallet != null
                    ? 'bg-secondary-3 text-primary-1'
                    : 'bg-gray-190 text-shade-7'
               "
               class="mt-32 w-full rounded-full font-bold tracking-wider px-10 py-3 bg-secondary-3 text-primary-1 text-sm sm:text-base md:text-lg lg:text-base xl:text-lg cursor-pointer"
          >
               Next
          </button>
     </div>
     <WalletConfirmation :onClickPurchaseCoin="makePurchase" :selectedWallet="selectedWallet" :coins="coins"
          :purchase_loading="purchase_loading" />
</template>
<script>
import { onMounted, reactive, watch, ref } from "vue";
import {
     initConnectPay as initConnectPayAPI,
     makePurchase as makePurchaseAPI,
} from "api/connect_pay.js";
import { authStore } from "store/auth.js";
import { computed } from "vue";
import { utilitiesStore } from "store/utilities.js";
import { connectPayStore } from "store/connectPay.js";
import _ from "underscore";
import { useRoute } from "vue-router";
import eventBus from "plugins/event.js";
import WalletConfirmation from "components/modals/walletConfimation.vue";
import { useToaster } from "composables/use-toaster.js";
import { fiservUrl } from "config/services.js";
import { baseUrl } from "config/services.js";

export default {
     props: ["coins", "setLoading", "setPaymentSuccessCoins", "limitReachValue", "dailyLimit", "closeModal"],
     components: {
          WalletConfirmation
     },
     setup(props, { emit }) {
          var extraParams = reactive({
               firstName: "",
               lastName: "",
               email: "",
               firstName: "",
               street: "",
               city: "",
               state: "",
               postalCode: "",
          });

          const route = useRoute();

          const { successToast, errorToast } = useToaster();

          const useAuthStore = authStore();
          const useUtilitiesStore = utilitiesStore();
          const useconnectPayStore = connectPayStore();

          const isAddAccountPanelOpen = ref(false);
          const loading = ref(false);

          const purchase_loading = ref(false);

          const selectedWallet = ref(null);

          const statusMsg = ["ERROR", "DECLINED"];

          const isUser = computed(() => {
               return useAuthStore.user;
          });

          const state = computed(() => {
               return useUtilitiesStore.state;
          });

          const enrolled = computed(() => {
               // return null
               return useconnectPayStore.enrolled;
          });

          const walletCount = computed(() => {
               return useconnectPayStore.walletCount;
          });

          const getSDKConfig = (data, type, accountId) => {

               const params = {
                    accessToken: data["currentSession"]["security"]["tokenID"],
                    fdCustomerId: ["bank", "manual"].includes(type)
                         ? data["fdCustomerID"]
                         : enrolled.value.customer.fdCustomerID,
                    encryptionKey:
                         data["currentSession"]["security"]["publicKey"],
               };

               switch (type) {
                    case "bank":
                         params["configId"] = fiservUrl.bank;
                         break;
                    case "manual":
                         params["configId"] = fiservUrl.manual;
                         break;
                    case "edit":
                         params["configId"] = fiservUrl.edit;
                         break;
                    case "close":
                         params["configId"] = fiservUrl.close;
                         break;
                    default:
                         break;
               }

               if (accountId != null) {
                    params["accountID"] = accountId;
               }

               return params;
          };

          const getData = () => {
               const non_doc = _.filter(isUser.value.kyc, function (params) {
                    return params['type'].toLowerCase() == 'usa non-doc'
               })

               let jsonAddress = null;
               let jsonUser = null;

               if(non_doc.length >= 1) {
                    const json = JSON.parse(non_doc[0].extracted_docs)

                    if(json.extractedDoc.addresses.length >= 1) {
                         jsonAddress = json.extractedDoc.addresses[0]
                    }

                    jsonUser = json.extractedDoc
               }

               return {
                    address: jsonAddress,
                    user: jsonUser
               };
          };

          const setExtraParams = () => {
               const data = getData();

               extraParams.firstName = data.user.firstName;
               extraParams.lastName = data.user.lastName;
               extraParams.email = data.user.email;
               extraParams["userPhone[0].type"] = "HOME"
               extraParams["userPhone[0].primary"] = "Y"
               extraParams["userPhone[0].number"] = data.user.phone;

               extraParams.street = data.address.street;
               extraParams.city = data.address.town;
               extraParams.state = data.address.state;
               extraParams.postalCode = data.address.postCode;

               if(typeof data.user.tin !== "undefined"){
                    extraParams.ssn = data.user.tin;
               }
       
               return extraParams;
          };

          const fetchSDK = async (type, accountId = null) => {
               let params = {};
               if (["bank", "manual"].includes(type)) {
                    if (walletCount.value >= 1) {
                         return;
                    }
                    isAddAccountPanelOpen.value = true;
                    loading.value = true;
               } else {
                    params = { fdAccountID: accountId };
               }

               props.setLoading(true);

               await initConnectPayAPI(params)
                    .then(async (res) => {
                         if (res) {
                              let CP = await CPSDK(res["api_key"], true);

                              const sdkConfiguration = getSDKConfig(
                                   res,
                                   type,
                                   accountId
                              );

                              const extraData = setExtraParams(res);

                              console.log(extraData);

                              if(
                                   extraData['city'] == null || extraData['city'] == '' ||
                                   extraData['state'] == null || extraData['state'] == '' ||
                                   extraData['postalCode'] == null || extraData['postalCode'] == '' ||
                                   extraData['street'] == null || extraData['street'] == ''
                              ) {
                                   errorToast(
                                        'Address Incomplete!',
                                        "top-right"
                                   );
                                   props.setLoading(false);
                                   return;
                              }

                              if (type == "bank") {
                                   props.setLoading(false);
                                   await addBank(
                                        CP,
                                        sdkConfiguration,
                                        extraData
                                   );
                              } else if (type == "manual") {
                                   props.setLoading(false);
                                   await manualBank(
                                        CP,
                                        sdkConfiguration,
                                        extraData
                                   );
                              } else if (type == "edit") {
                                   props.setLoading(false);
                                   await updateBank(
                                        CP,
                                        sdkConfiguration,
                                        extraData
                                   );
                              } else if (type == "close") {
                                   props.setLoading(false);
                                   await closeBank(
                                        CP,
                                        sdkConfiguration,
                                        extraData
                                   );
                              }
                         }
                    })
                    .catch(({ response }) => {
                         props.setLoading(false);
                    });
          };

          const makePurchase = async (accountId) => {
               purchase_loading.value = true;
               const params = {
                    accountId: accountId,
               };

               if(props.coins['action'] == 'package') {
                    params['coin_id'] = props.coins['coin_id'];
               } else {
                    params['money'] = parseInt(props.coins['total']);
               }

               await makePurchaseAPI(params)
                    .then(async (res) => {
                        purchase_loading.value = false;
                        closeConfirmationModal();
                        props.closeModal();
                        openPaymentSuccess({
                            gc: res.gold_coins,
                            sc: res.silver_coins,
                        });
                        props.setPaymentSuccessCoins(res);
                    })
                    .catch(({ response }) => {

                         const { data, status, message } = response;
                         if(status == 403) {
                              window.location.href = '/'
                              return;
                         }

                         purchase_loading.value = false;
                         closeConfirmationModal();
                         props.closeModal();
                         openPaymentFailed();
                    });
          };

          const openPaymentSuccess = (coins) => {
               eventBus.emit("open:payment_success", coins);
          };

          const openPaymentFailed = () => {
               eventBus.emit("open:payment_failed");
          };

          const openBankWallet = () => {
               eventBus.emit("open:bank_wallet");
          };

          const closeBankWallet = () => {
               eventBus.emit("close:bank_wallet");
          };

          const openManualWallet = () => {
               eventBus.emit("open:manual_wallet");
          };

          const closeManualWallet = () => {
               eventBus.emit("close:manual_wallet");
          };

          const openUpdateWallet = () => {
               eventBus.emit("open:update_wallet");
          };

          const closeUpdateWallet = () => {
               eventBus.emit("close:update_wallet");
          };

          const openRemoveWallet = () => {
               eventBus.emit("open:remove_wallet");
          };

          const closeRemoveWallet = () => {
               eventBus.emit("close:remove_wallet");
          };

          const openConfirmationModal = () => {
               eventBus.emit("open:walletconfirmation");
          };

          const closeConfirmationModal = () => {
               eventBus.emit("close:walletconfirmation");
          };

          const addBank = async (CP, sdkConfiguration, extraData) => {
               console.log("===============================================");
               console.log("STREAMLINE ENROLLMENT() - LINK BANK ACCOUNT");
               console.log(sdkConfiguration);
               console.log(extraData);
               //    32678005-8e7f-4d12-8298-cd40e103866a
               openBankWallet();
               const addBank = await CP.StreamlinedEnrollment(
                    sdkConfiguration,
                    extraData,
                    async function (response) {
                         const res =
                              typeof response == "object"
                                   ? response
                                   : JSON.parse(response);

                         if (
                              res.transactionStatusDescription ==
                              "User Cancelled"
                         ) {
                              //   isAddAccountPanelOpen.value = false;
                              closeBankWallet();
                         } else if (statusMsg.includes(res.transactionStatus)) {
                              console.error(res.transactionStatusDescription);
                              errorToast(
                                   res.transactionStatusDescription,
                                   "top-right"
                              );
                              fetchSDK("bank");
                         } else {
                              successToast(
                                   "Successfully Linked Bank Account!",
                                   "top-right"
                              );
                              purchase_loading.value = true;
                              const saveOldEnrolledList = enrolled.value?.account;
                              await useconnectPayStore.getEnrolled(true);
                              const newEnrolledList = enrolled.value?.account;

                              const pluckOld = _.pluck(saveOldEnrolledList, 'fdAccountID');
                              const pluckNew = _.pluck(newEnrolledList, 'fdAccountID');

                              closeBankWallet();
                              purchase_loading.value = false;

                              const dif = _.difference(pluckNew, pluckOld);
                              console.log(dif);

                              const filter = _.filter(newEnrolledList, function (params) {
                                   return params['fdAccountID'] == dif[0]
                              })

                              setSelectedWallet(filter[0]);
                              openConfirmationModal();
                         }
                    }
               );

               addBank.start("sdk-container-bank");
               loading.value = false;
          };

          const manualBank = async (CP, sdkConfiguration, extraData) => {
               console.log("===============================================");
               console.log("STREAMLINE ENROLLMENT() - MANUAL");
               console.log(sdkConfiguration);
               console.log(extraData);
               openManualWallet();
               const addBank = await CP.StreamlinedEnrollment(
                    sdkConfiguration,
                    extraData,
                    async function (response) {
                         const res =
                              typeof response == "object"
                                   ? response
                                   : JSON.parse(response);

                         if (
                              res.transactionStatusDescription ==
                              "User Cancelled"
                         ) {
                              //   isAddAccountPanelOpen.value = false;
                              closeManualWallet();
                         } else if (statusMsg.includes(res.transactionStatus)) {
                              console.error(res.transactionStatusDescription);
                              errorToast(
                                   res.transactionStatusDescription,
                                   "top-right"
                              );
                              fetchSDK("manual");
                         } else {
                              console.log(response);
                              successToast(
                                   "Successfully Enrolled!",
                                   "top-right"
                              );
                              await useconnectPayStore.getEnrolled(true);
                         }
                    }
               );

               addBank.start("sdk-container-manual");
               loading.value = false;
          };

          const updateBank = async (CP, sdkConfiguration, extraData) => {
               console.log("===============================================");
               console.log("UPDATEENROLLMENT()");
               console.log(sdkConfiguration);
               console.log(extraData);
               openUpdateWallet();

               const editBank = await CP.UpdateEnrollment(
                    sdkConfiguration,
                    extraData,
                    async function (response) {
                         const res =
                              typeof response == "object"
                                   ? response
                                   : JSON.parse(response);

                         if (
                              res.transactionStatusDescription ==
                              "User Cancelled"
                         ) {
                              closeUpdateWallet();
                         } else if (statusMsg.includes(res.transactionStatus)) {
                              console.error(res.transactionStatusDescription);
                              errorToast(
                                   res.transactionStatusDescription,
                                   "top-right"
                              );
                              fetchSDK("edit");
                         } else {
                              console.log(response);
                              successToast(
                                   "Successfully Updated!",
                                   "top-right"
                              );
                         }
                    }
               );

               editBank.start("sdk-container-update");
               loading.value = false;
          };

          const closeBank = async (CP, sdkConfiguration, extraData) => {
               console.log("===============================================");
               console.log("CLOSEACCOUNT()");
               console.log(sdkConfiguration);
               console.log(extraData);
               openRemoveWallet();

               const removeBank = await CP.CloseAccount(
                    sdkConfiguration,
                    extraData,
                    async function (response) {
                         const res =
                              typeof response == "object"
                                   ? response
                                   : JSON.parse(response);

                         if (
                              res.transactionStatusDescription ==
                              "User Cancelled"
                         ) {
                              closeRemoveWallet();
                         } else if (statusMsg.includes(res.transactionStatus)) {
                              console.error(res.transactionStatusDescription);
                              errorToast(
                                   res.transactionStatusDescription,
                                   "top-right"
                              );
                              fetchSDK("close");
                         } else {
                              console.log(response);
                              purchase_loading.value = true;
                              successToast("Successfully Closed!", "top-right");
                              await useconnectPayStore.getEnrolled(true);
                              purchase_loading.value = false;
                              closeRemoveWallet();
                         }
                    }
               );

               removeBank.start("sdk-container-remove");
               loading.value = false;
          };

          const setSelectedWallet = (data) => {
               selectedWallet.value = data;
          };

          const getActiveAccount = (data = []) => {
            const temp = _.filter(data, function (params) {
                return params['accountStatus'] != "40"
            })

            return temp.length != 0 ? temp : []
          }

          const checkAPButton = async () => {
                const apButtonTab = document.getElementById('ap-button-tab');
                const apButtonPanel = document.getElementById('ap-button-panel');
                if(apButtonTab){
                    if (window.ApplePaySession && ApplePaySession.canMakePayments()){
                         apButtonTab.classList.remove("hidden");
                         apButtonPanel.classList.remove("hidden");
                    }
                    else{
                         apButtonTab.remove();
                         apButtonPanel.remove();
                    }
                }
          };

          onMounted(async () => {

                checkAPButton();

               const isExist = document.querySelector("#fiserv-import");
               if(isExist == null) {
                    var script = document.createElement('script');
                    script.id = 'fiserv-import';
                    script.src = (import.meta.env.VITE_ENV == "production" ? 'https://prod.api.firstdata.com/gateway/v2/connectpay/static/v1/js/PaymentSDK.js' : 'https://cat.api.firstdata.com/gateway/v2/connectpay/static/v1/js/PaymentSDK.js');
                    document.body.appendChild(script);
               }

               loading.value = true;
               await useconnectPayStore.getEnrolled();
               if(enrolled.value != null && enrolled.value?.account?.length) {
                    if(walletCount == 0 && enrolled.value?.account[0].accountStatus == '40') return
                    else {
                        const temp = _.filter(enrolled.value?.account, function (params) {
                            return params['accountStatus'] != "40"
                        })

                        if(temp.length != 0) {
                            selectedWallet.value = temp[0]
                        } else {
                            selectedWallet.value = null
                        }
                    }
               }
               await useAuthStore.getUser();
               loading.value = false;
          });

          watch(isUser, (newVal) => {
               if (newVal) {
                    console.log(newVal);
               }
          });

          watch(isUser, (newVal) => {
               if (newVal) {
                    console.log(newVal);
               }
          });

          return {
               enrolled,
               walletCount,
               fetchSDK,
               isAddAccountPanelOpen,
               loading,
               makePurchase,
               purchase_loading,
               selectedWallet,
               setSelectedWallet,
               openConfirmationModal,
               baseUrl,
               getActiveAccount
          };
     },
};
</script>
