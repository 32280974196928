import { io } from "socket.io-client";
import { authStore } from "store/auth.js";
import { computed } from "vue";
import Cookies from 'js-cookie';

let socket;

export const useWebSocket = () => {
    const useAuthStore = authStore();

    const token = computed(() => {
        return useAuthStore.token;
    });

    const username = computed(() => {
        return useAuthStore.username;
    });

    const base = import.meta.env.VITE_ENV == "production" ? "wss://web-socket.moozi.com" : "wss://moozi-socket.cobaaja.xyz";

    if (!socket) {
        socket = io(base, {transports: ["websocket"], query: { token: token.value, username: (username.value ? username.value : Cookies.get("username")) }});
    }

    return {
        socket
    }
}
