<template>
     <!-- Main modal -->
     <transition
          enter-from-class="scale-0"
          enter-active-class="ease-in transition-medium"
          enter-to-class="scale-1"
          leave-from-class="scale-1"
          leave-active-class="ease-out transition-medium"
          leave-to-class="scale-0"
     >
          <div
               v-if="isPaymentCheckout"
               id="payment-checkout-modal"
               tabindex="-1"
               aria-hidden="true"
               class="overflow-y-hidden overflow-x-hidden fixed z-100 w-full inset-0 max-h-full transform ease-in-out transition-all duration-300 self-center m-auto flex justify-center items-center"
          >
               <div class="relative px-3 py-3 sm:py-4 sm:px-6 w-full max-w-2xl max-h-full tracking-wider m-auto">
                    <!-- Modal content -->
                    <div class="relative bg-white rounded-lg shadow font-roboto">
                         <button
                              @click="closeModal()"
                              type="button"
                              class="close-btn-c absolute top-2.5 end-2.5 rounded-xl text-sm ms-auto inline-flex justify-center items-center"
                              data-modal-hide="authentication-modal"
                              >
                              <img :src="baseUrl.img+'/assets/img/modal/exclude.svg'" alt="" />
                              <span class="sr-only">Close modal</span>
                         </button>
                         <div class="bg-gradient-page px-5 pt-5 pb-8 flex flex-col w-full h-max text-white rounded-t-lg">
                              <div class="flex flex-row justify-between pr-5">
                                   <p class="font-bold text-sm sm:text-base md:text-lg lg:text-base xl:text-lg">{{coin.action == 'package' ? coin.type : 'Custom Amount'}}</p>
                                   <p class="font-normal text-sm sm:text-lg md:text-xl lg:text-lg xl:text-xl">Total</p>
                              </div>
                              <div class="flex flex-row justify-between pr-5">
                                   <p class="font-normal text-sm md:text-base lg:text-sm xl:text-base">{{money(coin.gc)}} GC + FREE {{money(coin.sc)}} SC</p>
                                   <p class="font-bold text-md sm:text-xl md:text-2xl lg:text-xl xl:text-2xl">${{money(coin.total)}}</p>
                              </div>
                         </div>
                         <div class="px-5 py-5 flex flex-col w-full h-max overflow-y-auto" style="max-height: calc(100vh - 160px);">
                              <p class="font-bold text-black text-sm md:text-base lg:text-sm xl:text-base mb-2">Payment methods</p>
                              <div class="flex flex-row justify-evenly gap-2">
                                   <div
                                        class="rounded-lg w-4/12 bg-gray-150 border-2 border-solid flex flex-col items-center justify-center px-3 py-3 cursor-pointer"
                                        :class="paymentMethod == 0 ? 'border-background-6' : 'border-white'"
                                        @click="paymentMethod = 0"
                                   >
                                        <div class="w-full flex justify-center font-bold text-black text-sm md:text-base lg:text-sm xl:text-base">Online Banking</div>
                                        <div class="italic bg-background-7 rounded-full px-2 py-1 w-full text-white w-max text-xs md:text-sm">Popular!</div>
                                   </div>
                                   <div v-if="isDev != 'production'"
                                        class="rounded-lg w-4/12 bg-gray-150 border-2 border-solid flex flex-col items-center justify-center px-3 py-3 cursor-pointer"
                                        :class="paymentMethod == 1 ? 'border-background-6' : 'border-white'"
                                        @click="paymentMethod = 1"
                                   >
                                        <img :src="baseUrl.img+'/assets/img/card/visa-rounded.png'" alt="visa" />
                                        <div class="text-shade-7 text-xs md:text-sm">Card</div>
                                   </div>
                                   <div 
                                        v-if="isDev != 'production'"
                                        id="ap-button-tab" 
                                        class="hidden w-4/12"
                                   >
                                        <div
                                             class="rounded-lg w-full bg-gray-150 border-2 border-solid flex flex-col items-center justify-center px-3 py-3 cursor-pointer"
                                             :class="paymentMethod == 2 ? 'border-background-6' : 'border-white'"
                                             @click="paymentMethod = 2"
                                        >
                                             <img :src="baseUrl.img+'/assets/img/card/apple-pay-rounded.png'" alt="apple-pay" />
                                             <div class="text-shade-7 text-xs md:text-sm">Apple Pay</div>
                                        </div>
                                   </div>
                              </div>
                              <OnlineBanking
                                   v-if="paymentMethod == 0"
                                   :coins="coin"
                                   :setPaymentSuccessCoins="setPaymentSuccessCoins"
                                   :setLoading="setLoading"
                                   v-model:limitReachValue="limitReachValue"
                                   v-model:dailyLimit="dailyLimit"
                                   :closeModal="closeModal"
                              />
                              <CreditCard
                                   v-if="paymentMethod == 1"
                                   :coins="coin"
                                   :setPaymentSuccessCoins="setPaymentSuccessCoins"
                                   :setLoading="setLoading"
                                   v-model:limitReachValue="limitReachValue"
                                   v-model:dailyLimit="dailyLimit"
                                   :closeModal="closeModal"
                              />
                              <div id="ap-button-panel" class="hidden w-full" v-if="paymentMethod == 2">
                                   <ApplePay
                                        :coins="coin"
                                        :setLoading="setLoading"
                                        :setPaymentSuccessCoins="setPaymentSuccessCoins"
                                        :closeModal="closeModal"
                                   />
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     </transition>
     <div
          v-if="isPaymentCheckout"
          class="fixed inset-0 z-60"
          style="background: rgba(0, 0, 0, 0.75);"
     ></div>
     <paymentSuccess :successCoins="paymentSuccessCoins" />
     <paymentFailed />
</template>

<script>
import { onMounted, onUnmounted, onActivated, ref, reactive, computed, watch } from "vue";
import eventBus from "plugins/event.js";
import { utilitiesStore } from "store/utilities.js";
import { baseUrl } from "config/services.js";
import OnlineBanking from "./component/online-banking.vue";
import CreditCard from "./component/credit-card.vue";
import ApplePay from "./component/apple-pay.vue";
import { useFormatter } from "composables/use-formatter.js";
import {
  limitReach as limitReachAPI
} from "api/auth.js";
import { authStore } from "store/auth.js";
import _ from "underscore";
import paymentSuccess from "components/modals/paymentSuccess.vue";
import paymentFailed from "components/modals/paymentFailed.vue";
import device from 'middleware/device.js';

export default {
     props: [],
     components: {
        OnlineBanking,
        CreditCard,
        ApplePay,
        paymentSuccess,
        paymentFailed,
     },
     setup(props, { emit }) {

          const isDev = import.meta.env.VITE_ENV;

          const { money } = useFormatter();
          const { isIOS } = device();

          const useUtilitiesStore = utilitiesStore();
          const useAuthStore = authStore();

          const isPaymentCheckout = ref(false);

          const paymentMethod = ref(0);

          const coin = ref(null);

          const paymentSuccessCoins = reactive({
               gold_coins: 0,
               silver_coins: 0,
          });

          const loading = ref(false);

          const limitReachValue = ref(0);
          const dailyLimit = ref(0);

          const closeModal = () => {
               isPaymentCheckout.value = false;
               useUtilitiesStore.enableScroll();
          };

          const isUser = computed(() => {
               return useAuthStore.user;
          });

          const loadingScreen = computed(() => {
               return useUtilitiesStore.loading;
          });

          const setLoading = (data) => {
               loading.value = data;
          };

          async function setPaymentSuccessCoins(res) {
               paymentSuccessCoins.gold_coins = money(res.gold_coins);
               paymentSuccessCoins.silver_coins = money(res.silver_coins);
          }

            const initializeApplePay = async () => {
                var js,appsdk_id="apple-pay-sdk",d=document;
                if (d.getElementById(appsdk_id)) {
                    // checkAPButton();
                    return;
                }
                js=d.createElement('script');
                js.id = appsdk_id;
                js.async = true;
                js.setAttribute("crossorigin","");
                js.src = "https://applepay.cdn-apple.com/jsapi/1.latest/apple-pay-sdk.js?v="+Date.now();
                d.head.appendChild(js);

                // checkAPButton();
            };

            onActivated(() => {
                initializeApplePay();
            });

          onMounted(async () => {
                initializeApplePay();

               await limitReachAPI().then((res) => {
                    if (res.status) {
                         limitReachValue.value = Number(res.purchase_amount)
                    };
               });

               eventBus.on("open:payment_checkout", (data) => {
                    useUtilitiesStore.disableScroll();
                    coin.value = data;
                    console.log(coin.value);
                    isPaymentCheckout.value = true
               });

               eventBus.on("close:payment_checkout", (data) => {
                   useUtilitiesStore.enableScroll();
                   coin.value = data;
                   console.log(coin.value);
                   isPaymentCheckout.value = false;
              });
          });

          onUnmounted(() => {
               eventBus.off("open:payment_checkout");
               eventBus.off("close:payment_checkout");
          });

          watch(loadingScreen, (newVal) => {
               if (!newVal) {
                    if (isUser?.value?.meta) {
                         let filter = _.filter(isUser.value.meta, function (params) {
                              return params["meta_key"] == "daily_limit";
                         });
                         if (filter.length >= 1) {
                              const parseFilter = JSON.parse(filter[0].meta_value);
                              dailyLimit.value = Number(parseFilter.amount);
                         }
                    }
               }
          });

          return {
               isPaymentCheckout,
               closeModal,
               baseUrl,
               paymentMethod,
               coin,
               money,
               setPaymentSuccessCoins,
               setLoading,
               isIOS,
               isDev
          };
     },
};
</script>