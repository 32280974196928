import request from '@/utils/request';

export function games(data) {
  return request({
    url: '/games',
    method: 'post',
    data: data,
  });
}

export function providers(data) {
  return request({
    url: '/providers',
    method: 'get',
    data: data,
  });
}


export function favorites(data) {
  return request({
    url: '/games/favorites',
    method: 'post',
    data: data,
  });
}

export function recent(data) {
  return request({
    url: '/games/recent_play',
    method: 'post',
    data: data,
  });
}

export function setFavorites(data) {
  return request({
    url: '/games/set_favorite',
    method: 'post',
    data: data,
  });
}


export function popular(data) {
  return request({
    url: '/games-popular',
    method: 'post',
    data: data,
  });
}


export function gameType() {
  return request({
    url: '/games_type',
    method: 'post',
  });
}

export function getHeart() {
  return request({
    url: '/games/favorites/all',
    method: 'post',
  });
}

export function gamesDetails(data) {
  return request({
    url: '/game/detail',
    method: 'get',
    params: data,
  });
}

export function gamesSimilar(data) {
  return request({
    url: '/game/similar',
    method: 'get',
    params: data,
  });
}

export function allProviders() {
  return request({
    url: '/all-providers',
    method: 'get',
  });
}
