<template>
<div class="w-full sm:px-5 bg-white py-5 lg:py-8 2xl:py-10 3xl:py-10 px-5 lg:px-14 2xl:px-20 3xl:px-28 overflow-y-auto rounded-b-lg lg:rounded-b-2xl">
	<div class="mx-auto" style="">
		<div class="py-2 ">
			<details class="group menu-select" v-for="(details, keys) in data" :key="keys">
				<summary class="flex border-b border-gray-190 px-0 lg:px-4 py-2 lg:py-4 mb-2 justify-between items-center font-roboto font-medium cursor-pointer list-none">
					<span class="text-sm sm:text-base md:text-lg lg:text-base xl:text-lg">{{ details.title }}</span>
					<svg v-if="selected[keys] == null" width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M5.859 6.51469L7.83 6.51465L13.662 6.51469V8.48569H7.83L5.859 8.48569L0 8.48569V6.51469H5.859Z" fill="black"/>
					</svg>
					<svg v-else width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M6.359 6.01449V0.182495H8.33V6.01449H14.162V7.98549H8.33V13.8175H6.359V7.98549H0.5V6.01449H6.359Z" fill="black"/>
					</svg>

				</summary>
				<div class="pb-2" v-html="details.body"></div>
			</details>
		</div>
	</div>
</div>
</template>

<script>

import { ref,watch } from 'vue';
export default {
	props: ['selected', 'data', 'changeMenu'],
	data: () => {
		return {
			}
    },
    setup(props){
		let menuChange = ref(false)
		
		let selected = ref({})
		const select = (e, value) => {
			if(e != undefined){
				selected.value = {...selected.value, [e]: value}
			}
		}

		watch(selected, async ()=>{
			Array.from(document.getElementsByClassName("menu-select")).map((d)=>{
				d.removeAttribute('open')
			})
		})

		watch(props, async ()=> {
			selected.value = {}
			props.data.map((d, index)=>{
				selected.value = {...selected.value, [index]: ''}
			})
		})

		 return {
			selected,
			select,
			menuChange
		 }
	},
};
</script>
