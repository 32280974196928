<template>
     <div class="w-full bg-primary-2 relative flex flex-col justify-center items-center">
          <div class="w-full 3xl:max-w-screen-3xl h-full flex flex-col justify-start items-center px-5 xs:px-8 sm:px-18 md:px-24 lg:px-16 xl:px-32 pb-8 xs:pb-12 sm:pb-18 md:pb-24 lg:pb-28 xl:pb-32 pt-8 xs:pt-12 sm:pt-18 md:pt-24 lg:pt-0">
               <div class="flex flex-col justify-center items-center">
                    <h1 class="text-white font-helveticaItalic tracking-wide italic text-4xl xs:text-5xl sm:text-7xl md:text-7xl xl:text-8xl leading-3xl xs:leading-4xl sm:leading-6xl md:leading-6xl xl:leading-7.5xl">BENEFIT
                    </h1>
                    <div class="mt-2">
                         <Star/>
                    </div>
               </div>
               <div
                    class="mt-14 md:mt-20 lg:mt-28 w-full flex justify-center"
                    id="benefit-container"
               >
                    <div class="flex flex-col lg:flex-row justify-center h-max 2xl:w-10/12 3xl:w-8/12 items-center gap-7 px-0 lg:px-0 xl:px-0 2xl:32">
                         <div class="bg-wood rounded-lg w-full xs:w-96 lg:w-full shadow-md relative">
                              <div class="p-7 h-full">
                                   <div class="w-full h-48 flex justify-center items-center xxs:h-56 lg:h-52 xl:h-60">
                                        <img
                                             loading="lazy"
                                             id="bee-benefit"
                                             class="absolute z-10 top-20 opacity-0"
                                             :src="baseUrl.img+'/assets/img/home/benefits/hero-animated-bee.webp'"
                                             alt=""
                                        >
                                   </div>
                                   <p class="font-helvetica tracking-wide font-medium text-secondary-3 text-2xl xs:text-3xl sm:text-4xl lg:text-3xl xl:text-4xl 2xl:text-5xl lg:leading-5 xl:leading-6 2xl:leading-7 text-center mb-2 md:mb-4">EASY TOP UP</p>
                                   <p class="helveticaLight tracking-wide text-white text-sm md:text-base xl:text-lg">
                                        Topping up your gold coins is effortless with our user-friendly interface, ensuring a seamless experience for all players.
                                   </p>
                              </div>
                         </div>
                         <div class="bg-wood rounded-lg w-full xs:w-96 lg:w-full shadow-md relative">
                              <div class="p-7 h-full">
                                   <div class="w-full h-48 flex justify-center items-center xs:h-56 lg:h-52 xl:h-60">
                                        <img
                                             loading="lazy"
                                             id="hen-benefit"
                                             class="absolute z-10 top-20 opacity-0"
                                             :src="baseUrl.img+'/assets/img/home/benefits/hero-animated-hen.webp'"
                                             alt=""
                                        >
                                   </div>
                                   <p class="font-helvetica tracking-wide font-medium text-secondary-3 text-2xl xs:text-3xl sm:text-4xl lg:text-3xl xl:text-4xl 2xl:text-5xl lg:leading-5 xl:leading-6 2xl:leading-7 text-center mb-2 md:mb-4">1000+ GAMES</p>
                                   <p class="helveticaLight tracking-wide text-white text-sm md:text-base xl:text-lg">
                                        Moozi have a diverse selection of games spanning across various platforms to cater to every gamer's preference.
                                   </p>
                              </div>
                         </div>
                         <div class="bg-wood rounded-lg w-full xs:w-96 lg:w-full shadow-md relative">
                              <div class="w-full flex justify-center">

                              </div>
                              <div class="p-7 h-full">
                                   <div class="w-full h-48 flex justify-center items-center xs:h-56 lg:h-52 xl:h-60">
                                        <img
                                             loading="lazy"
                                             id="cow-benefit"
                                             class="absolute z-10 top-20 opacity-0"
                                             :src="baseUrl.img+'/assets/img/home/benefits/hero-animated-cow.webp'"
                                             alt=""
                                        >
                                   </div>
                                   <p class="font-helvetica tracking-wide font-medium text-secondary-3 text-2xl xs:text-3xl sm:text-4xl lg:text-3xl xl:text-4xl 2xl:text-5xl lg:leading-5 xl:leading-6 2xl:leading-7 text-center mb-2 md:mb-4">24/7 support</p>
                                   <p class="helveticaLight tracking-wide text-white text-sm md:text-base xl:text-lg">
                                        We offer round-the-clock support from our dedicated team, always prepared to assist you whenever you need it.
                                   </p>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
          <div 
               class="w-full max-w-full h-18 md:h-72 2xl:h-96 z-10 bg-background-4"
               style="clip-path: polygon(100% 100%, 0% 100% , 0.00% 86.17%, 1.67% 85.03%, 3.33% 83.85%, 5.00% 82.61%, 6.67% 81.33%, 8.33% 80.00%, 10.00% 78.63%, 11.67% 77.22%, 13.33% 75.78%, 15.00% 74.31%, 16.67% 72.82%, 18.33% 71.31%, 20.00% 69.77%, 21.67% 68.23%, 23.33% 66.67%, 25.00% 65.12%, 26.67% 63.56%, 28.33% 62.00%, 30.00% 60.46%, 31.67% 58.93%, 33.33% 57.41%, 35.00% 55.92%, 36.67% 54.45%, 38.33% 53.01%, 40.00% 51.60%, 41.67% 50.23%, 43.33% 48.90%, 45.00% 47.62%, 46.67% 46.38%, 48.33% 45.20%, 50.00% 44.07%, 51.67% 42.99%, 53.33% 41.98%, 55.00% 41.03%, 56.67% 40.15%, 58.33% 39.34%, 60.00% 38.59%, 61.67% 37.92%, 63.33% 37.33%, 65.00% 36.81%, 66.67% 36.36%, 68.33% 36.00%, 70.00% 35.72%, 71.67% 35.51%, 73.33% 35.39%, 75.00% 35.35%, 76.67% 35.39%, 78.33% 35.51%, 80.00% 35.72%, 81.67% 36.00%, 83.33% 36.36%, 85.00% 36.81%, 86.67% 37.33%, 88.33% 37.92%, 90.00% 38.59%, 91.67% 39.34%, 93.33% 40.15%, 95.00% 41.03%, 96.67% 41.98%, 98.33% 42.99%, 100.00% 44.07%);"
          ></div>
     </div>
</template>

<script>
import { onMounted } from "vue";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Star from "components/star/index.vue";
import { baseUrl } from "config/services.js";

gsap.registerPlugin(ScrollTrigger);

export default {
     components: {
          Star
     },
     props: ["isUser"],
     setup(props) {
          const setAnimation = (id) => {
               let tl = gsap.timeline({
                    scrollTrigger: {
                         trigger: "#benefit-container",
                         start: "top center",
                         toggleActions: "restart none none reverse",
                         // markers: true,
                    },
               });

               tl.to(id, {
                    top: -40,
                    opacity: 1,
                    duration: 0.3,
                    ease: "sine.in",
               })
                    .to(id, {
                         top: -50,
                         duration: 0.2,
                         ease: "power1.out",
                    })
                    .to(id, {
                         top: -40,
                         duration: 0.3,
                         ease: "power1.out",
                    });
          };

          onMounted(() => {
               setTimeout(() => {
                    setAnimation("#bee-benefit");
                    setAnimation("#hen-benefit");
                    setAnimation("#cow-benefit");
               }, 2000);
          });

          return {
               baseUrl
          };
     },
};
</script>
