<template>
    <Head title="Games"></Head>
    <loading-overlay :isLoading="loading" />
    <div class="iframe-container" :class="isMobile ? '' : 'play-c-padding'">
        <iframe :src="url" frameborder="0"></iframe>
    </div>
    <Expired :isOpenGameTokenExpired="isOpenGameTokenExpired"/>
</template>

<script>
import { ref, computed, onMounted, onUnmounted, onBeforeMount, onBeforeUnmount, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useBalanceSocketInit } from "composables/use-balance-socket-init.js";
import { authStore } from "@/store/auth";
import { Head } from "@inertiajs/vue3";
// import Cookies from 'js-cookie';
import Expired from "./component/expired.vue";
import {
    getGameToken as getGameTokenAPI
} from "@/api/auth";
import _ from "underscore";
import { useScroll } from "composables/use-scroll.js";
import LoadingOverlay from "components/loading/loadingOverlay.vue";
import device from 'middleware/device.js';
import { utilitiesStore } from "store/utilities.js";

export default {
    components: {
        Expired,
        Head,
        LoadingOverlay
     },
    setup() {
        const route = useRoute();
        const useAuthStore = authStore();
        const { socket, emitGameOpen } = useBalanceSocketInit();
        const isOpenGameTokenExpired = ref(false);
        const { scrollTop } = useScroll();
        const { deviceType } = device();

        const useUtilitiesStore = utilitiesStore();

        const loading = ref(false);

        const preloading = computed(() => {
            return useUtilitiesStore.loading;
        });

        const isMobile = ref(false);
        // const url = ref(null);

        const url = computed(() => {
            const urlParam = useAuthStore.active_game_url;
            if (Array.isArray(urlParam)) {
                return decodeURIComponent(urlParam[0]);
            } else {
                return decodeURIComponent(urlParam);
            }
        });

        const active_coin_type = computed(() => {
            return useAuthStore.active_coin_type || route.params.type;
        });

        const myEventHandler = (e) => {
            if (deviceType == "mobile") {
                isMobile.value = true;
            } else {
                isMobile.value = false;
            }
        };

        onBeforeMount(async () => {
            emitGameOpen();
        });

        onMounted(() => {
            scrollTop();
            myEventHandler();
            if (!preloading.value) {
                loading.value = true
            }
            socket.on('game:get', async function () {
                getGameTokenAPI({"launch_code" : route.params.launchcode})
                .then(res => {
                    if(!res.status) {
                        // useAuthStore.setActiveGameUrl(res.launch.url);
                        isOpenGameTokenExpired.value = true;
                        // const checkIfMatch = _.isEqual(res.data, res.launch.auth_key);
                        // if(!checkIfMatch) {
                        //     isOpenGameTokenExpired.value = true;
                        // };

                    } else {
                        useAuthStore.setActiveGameUrl(res.launch.url);
                    }
                    loading.value = false
                })
                .catch(error => {
                    loading.value = false
                    console.error('Error to get Game Token data!', error);
                })
            });
            setTimeout(() => {
                loading.value = false
            }, 1500);
        });

        onBeforeUnmount(() => {
            socket.off("game:get")
        });

        watch(active_coin_type, (newVal) => {
            if (newVal) {
                loading.value = true
                setTimeout(() => {
                    loading.value = false
                }, 1500);
            }
        });

        return {
            url,
            isOpenGameTokenExpired,
            isMobile,
            loading
        };
    },
};
</script>

<style>
.iframe-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.iframe-container iframe {
  width: 100%;
  height: 100%;
  border: none;
}
</style>
