<template>
     <div class="bg-background-4 relative flex justify-center">
          <div
               class="w-full 3xl:max-w-screen-3xl relative"
               id="promotion-container"
          >
               <div class="w-full hidden md:flex justify-center relative">
                    <img
                       
                         id="hen-promotion"
                         class="absolute z-10 -top-80 opacity-0"
                         :src="baseUrl.img+'/assets/img/home/promotion/hero-animated-hen.webp'"
                         alt=""
                    >
                    <img
                       
                         id="cow-promotion"
                         class="absolute z-10 -top-98 scale-50 translate-x-26 opacity-0"
                         :src="baseUrl.img+'/assets/img/home/promotion/hero-animated-cow.webp'"
                         alt=""
                    >
               </div>
               <div class="flex justify-center relative">
                    <div class="w-full 2xl:w-10/12 h-full flex flex-col justify-start items-center md:items-start  px-5 xs:px-8 sm:px-18 md:px-24 lg:px-28 xl:px-32 pb-8 xs:pb-12 sm:pb-18 md:pb-24 lg:pb-28 xl:pb-32 pt-0 sm:pt-0 lg:pt-28 xl:pt-32">
                         <div class="flex flex-col justify-center items-center pt-5 md:pt-0 lg:pt-6 xl:pt-12 2xl:pt-28">
                              <h1 class="text-white font-helveticaItalic tracking-wide italic text-4xl xs:text-5xl sm:text-7xl md:text-7xl xl:text-8xl leading-3xl xs:leading-4xl sm:leading-6xl md:leading-6xl xl:leading-7.5xl">PROMOTIONS
                              </h1>
                              <div class="mt-2">
                                   <Star/>
                              </div>
                         </div>
                         <div class="mt-10 md:mt-20 flex flex-col gap-2 lg:gap-3 xl:gap-10 w-full p-5 lg:p-14 bg-background-3 rounded-2xl">
                              <div
                                   v-for="(item, key) in promoData"
                                   :key="key"
                                   class="flex flex-row items-center gap-2 md:gap-4"
                              >
                                   <svg class="w-7 md:w-9 lg:w-12 xl:w-16 h-7 md:h-9 lg:h-12 xl:h-16" viewBox="0 0 67 73" fill="none" xmlns="http://www.w3.org/2000/svg">
                                   <g clip-path="url(#clip0_2421_3514)">
                                   <path d="M48.6571 6.11328H18.3429C8.21241 6.11328 0 14.2805 0 24.3553V54.5026C0 64.5774 8.21241 72.7446 18.3429 72.7446H48.6571C58.7876 72.7446 67 64.5774 67 54.5026V24.3553C67 14.2805 58.7876 6.11328 48.6571 6.11328Z" fill="#422C18"/>
                                   <path d="M27.6103 72.9992C24.6175 67.6227 22.3005 63.6542 20.6915 61.1259L18.1171 57.1255L16.1862 54.1812C11.9706 47.6525 7.56183 42.2439 2.99219 37.9874C5.59882 35.8432 8.04454 34.7551 10.2972 34.7551C13.0647 34.7551 15.5426 35.7792 17.6987 37.7954C19.8548 39.8116 22.5902 43.652 25.8726 49.3487C29.6055 37.2513 34.3683 25.7941 40.1286 14.9769C43.3145 9.08824 46.1785 5.11981 48.6565 3.07158C51.1665 1.02336 54.4811 0.03125 58.6003 0.03125C60.7885 0.03125 63.4917 0.383289 66.7098 1.05536C58.4394 7.68009 51.8102 15.585 46.8543 24.706C41.8985 33.827 35.4624 49.9567 27.6103 73.0312V72.9992Z" fill="#FCCF3D"/>
                                   </g>
                                   <defs>
                                   <clipPath id="clip0_2421_3514">
                                   <rect width="67" height="73" fill="white"/>
                                   </clipPath>
                                   </defs>
                                   </svg>
                                   <h1 class="text-white whitespace-nowrap font-helvetica tracking-wide text-2xl xs:text-4xl md:text-5xl lg:text-6xl xl:text-7xl leading-3xl xs:leading-4xl md:leading-5xl lg:leading-6xl xl:leading-7xl uppercase">
                                        {{item.title}}
                                        <span class="text-secondary-3">{{item.title_highlight}}</span>
                                   </h1>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     </div>
     
</template>

<script>
import { reactive, onMounted } from "vue";
import { Carousel, Navigation, Pagination, Slide } from "vue3-carousel";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Star from "components/star/index.vue";
gsap.registerPlugin(ScrollTrigger);
import { baseUrl } from "config/services.js";

export default {
     props: ["promoData"],
     components: {
          Carousel,
          Slide,
          Pagination,
          Navigation,
          Star
     },
     setup(props) {
          let mm = gsap.matchMedia();

          const henAnimation = () => {
               let tl = gsap.timeline({
                    scrollTrigger: {
                         trigger: "#promotion-container",
                         start: "top center",
                         toggleActions: "restart none none reverse",
                         // markers: true,
                    },
               });

               mm.add("(min-width: 768px) and (max-width: 839px)", () => {
                    tl.to("#hen-promotion", {
                         top: -200,
                         opacity: 1,
                         duration: 0.3,
                         ease: "sine.in",
                    })
                         .to("#hen-promotion", {
                              top: -210,
                              duration: 0.2,
                              ease: "power1.out",
                         })
                         .to("#hen-promotion", {
                              top: -200,
                              duration: 0.3,
                              ease: "power1.out",
                         });
               });

               mm.add("(min-width: 840px) and (max-width: 922px)", () => {
                    tl.to("#hen-promotion", {
                         top: -245,
                         opacity: 1,
                         duration: 0.3,
                         ease: "sine.in",
                    })
                         .to("#hen-promotion", {
                              top: -255,
                              duration: 0.2,
                              ease: "power1.out",
                         })
                         .to("#hen-promotion", {
                              top: -245,
                              duration: 0.3,
                              ease: "power1.out",
                         });
               });

               mm.add("(min-width: 923px) and (max-width: 1023px)", () => {
                    tl.to("#hen-promotion", {
                         top: -290,
                         opacity: 1,
                         duration: 0.3,
                         ease: "sine.in",
                    })
                         .to("#hen-promotion", {
                              top: -300,
                              duration: 0.2,
                              ease: "power1.out",
                         })
                         .to("#hen-promotion", {
                              top: -290,
                              duration: 0.3,
                              ease: "power1.out",
                         });
               });

               mm.add("(min-width: 1024px)", () => {
                    tl.to("#hen-promotion", {
                         top: -416,
                         opacity: 1,
                         duration: 0.2,
                         ease: "sine.in",
                    })
                         .to("#hen-promotion", {
                              top: -426,
                              duration: 0.2,
                              ease: "power1.out",
                         })
                         .to("#hen-promotion", {
                              top: -416,
                              duration: 0.3,
                              ease: "power1.out",
                         });
               });
          };

          const cowAnimation = () => {
               let tl = gsap.timeline({
                    scrollTrigger: {
                         trigger: "#promotion-container",
                         start: "top center",
                         toggleActions: "restart none none reverse",
                         // markers: true,
                    },
               });

               mm.add("(min-width: 768px) and (max-width: 1023px)", () => {
                    tl.to("#cow-promotion", {
                         top: -200,
                         scale: 1,
                         translateX: 0,
                         // translateY: 0,
                         opacity: 1,
                         duration: 0.3,
                         ease: "sine.in",
                    })
                         .to("#cow-promotion", {
                              top: -210,
                              duration: 0.2,
                              ease: "power1.out",
                         })
                         .to("#cow-promotion", {
                              top: -200,
                              duration: 0.3,
                              ease: "power1.out",
                         });
               });

               mm.add("(min-width: 1024px)", () => {
                    tl.to("#cow-promotion", {
                         top: -416,
                         scale: 1,
                         translateX: 0,
                         // translateY: 0,
                         opacity: 1,
                         duration: 0.2,
                         ease: "sine.in",
                    })
                         .to("#cow-promotion", {
                              top: -426,
                              duration: 0.2,
                              ease: "power1.out",
                         })
                         .to("#cow-promotion", {
                              top: -416,
                              duration: 0.3,
                              ease: "power1.out",
                         });
               });
          };

          onMounted(() => {
               setTimeout(() => {
                    henAnimation();
                    cowAnimation();
               }, 2000);
          });

          return {
               baseUrl
          };
     },
};
</script>
