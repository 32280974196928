<template>
     <div class="w-full flex justify-center items-center flex-col pt-10 pb-20">
          <div class="uppercase text-3xl w-fit font-helvetica text-white tracking-wider text-center mb-3 lg:mb-12">Can't find what you are looking for?</div>
          <div
               @click="goto()"
               class="w-4/6 md:w-3/6 lg:w-80 text-center rounded-full font-roboto font-bold tracking-wider px-10 py-3 bg-secondary-3 text-primary-1 text-sm sm:text-base md:text-lg lg:text-base xl:text-lg cursor-pointer"
          >
               Contact Us
          </div>
     </div>
</template>


<script>
import { useRouter } from "vue-router";
export default {
     components: {},
     setup() {
          const router = useRouter();

          const goto = () => {
               router.push({ path: "/submit-a-request" });
          };

          return {
               goto,
          };
     },
};
</script>