<template>
     <div class="flex flex-row gap-2">
          <svg class="w-8 h-8 md:w-12 md:h-12" viewBox="0 0 54 51" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M17.488 15.3168L2.06968 17.5522L1.79659 17.6078C1.3832 17.7175 1.00633 17.935 0.704484 18.238C0.402635 18.5411 0.186619 18.9188 0.0784943 19.3326C-0.0296306 19.7464 -0.02599 20.1815 0.0890441 20.5935C0.204078 21.0054 0.426384 21.3795 0.733261 21.6774L11.9031 32.55L9.26893 47.9079L9.23751 48.1738C9.2122 48.6013 9.30099 49.0279 9.49476 49.4099C9.68854 49.7919 9.98035 50.1155 10.3403 50.3476C10.7003 50.5797 11.1155 50.712 11.5434 50.7309C11.9713 50.7497 12.3965 50.6546 12.7755 50.4551L26.565 43.2051L40.3231 50.4551L40.5648 50.5663C40.9637 50.7234 41.3972 50.7716 41.8208 50.7058C42.2445 50.6401 42.643 50.4629 42.9756 50.1923C43.3082 49.9217 43.5628 49.5676 43.7133 49.1661C43.8638 48.7647 43.9048 48.3304 43.8321 47.9079L41.1955 32.55L52.3702 21.675L52.5587 21.4696C52.8279 21.1379 53.0045 20.7409 53.0703 20.3188C53.1362 19.8967 53.089 19.4647 52.9335 19.0668C52.778 18.6689 52.5198 18.3193 52.1853 18.0537C51.8507 17.788 51.4517 17.6158 51.0289 17.5546L35.6106 15.3168L28.7183 1.34843C28.5188 0.943718 28.2101 0.602921 27.827 0.364612C27.4439 0.126303 27.0017 0 26.5505 0C26.0993 0 25.6572 0.126303 25.2741 0.364612C24.8909 0.602921 24.5822 0.943718 24.3828 1.34843L17.488 15.3168Z" fill="url(#paint0_radial_2420_3511)"/>
               <defs>
               <radialGradient id="paint0_radial_2420_3511" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(31 21) rotate(123.425) scale(29.9541 31.3503)">
               <stop stop-color="#FFE921"/>
               <stop offset="1" stop-color="#F2A201"/>
               </radialGradient>
               </defs>
          </svg>
          <svg class="w-8 h-8 md:w-12 md:h-12" viewBox="0 0 54 51" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M17.488 15.3168L2.06968 17.5522L1.79659 17.6078C1.3832 17.7175 1.00633 17.935 0.704484 18.238C0.402635 18.5411 0.186619 18.9188 0.0784943 19.3326C-0.0296306 19.7464 -0.02599 20.1815 0.0890441 20.5935C0.204078 21.0054 0.426384 21.3795 0.733261 21.6774L11.9031 32.55L9.26893 47.9079L9.23751 48.1738C9.2122 48.6013 9.30099 49.0279 9.49476 49.4099C9.68854 49.7919 9.98035 50.1155 10.3403 50.3476C10.7003 50.5797 11.1155 50.712 11.5434 50.7309C11.9713 50.7497 12.3965 50.6546 12.7755 50.4551L26.565 43.2051L40.3231 50.4551L40.5648 50.5663C40.9637 50.7234 41.3972 50.7716 41.8208 50.7058C42.2445 50.6401 42.643 50.4629 42.9756 50.1923C43.3082 49.9217 43.5628 49.5676 43.7133 49.1661C43.8638 48.7647 43.9048 48.3304 43.8321 47.9079L41.1955 32.55L52.3702 21.675L52.5587 21.4696C52.8279 21.1379 53.0045 20.7409 53.0703 20.3188C53.1362 19.8967 53.089 19.4647 52.9335 19.0668C52.778 18.6689 52.5198 18.3193 52.1853 18.0537C51.8507 17.788 51.4517 17.6158 51.0289 17.5546L35.6106 15.3168L28.7183 1.34843C28.5188 0.943718 28.2101 0.602921 27.827 0.364612C27.4439 0.126303 27.0017 0 26.5505 0C26.0993 0 25.6572 0.126303 25.2741 0.364612C24.8909 0.602921 24.5822 0.943718 24.3828 1.34843L17.488 15.3168Z" fill="url(#paint0_radial_2420_3511)"/>
               <defs>
               <radialGradient id="paint0_radial_2420_3511" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(31 21) rotate(123.425) scale(29.9541 31.3503)">
               <stop stop-color="#FFE921"/>
               <stop offset="1" stop-color="#F2A201"/>
               </radialGradient>
               </defs>
          </svg>
          <svg class="w-8 h-8 md:w-12 md:h-12" viewBox="0 0 54 51" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M17.488 15.3168L2.06968 17.5522L1.79659 17.6078C1.3832 17.7175 1.00633 17.935 0.704484 18.238C0.402635 18.5411 0.186619 18.9188 0.0784943 19.3326C-0.0296306 19.7464 -0.02599 20.1815 0.0890441 20.5935C0.204078 21.0054 0.426384 21.3795 0.733261 21.6774L11.9031 32.55L9.26893 47.9079L9.23751 48.1738C9.2122 48.6013 9.30099 49.0279 9.49476 49.4099C9.68854 49.7919 9.98035 50.1155 10.3403 50.3476C10.7003 50.5797 11.1155 50.712 11.5434 50.7309C11.9713 50.7497 12.3965 50.6546 12.7755 50.4551L26.565 43.2051L40.3231 50.4551L40.5648 50.5663C40.9637 50.7234 41.3972 50.7716 41.8208 50.7058C42.2445 50.6401 42.643 50.4629 42.9756 50.1923C43.3082 49.9217 43.5628 49.5676 43.7133 49.1661C43.8638 48.7647 43.9048 48.3304 43.8321 47.9079L41.1955 32.55L52.3702 21.675L52.5587 21.4696C52.8279 21.1379 53.0045 20.7409 53.0703 20.3188C53.1362 19.8967 53.089 19.4647 52.9335 19.0668C52.778 18.6689 52.5198 18.3193 52.1853 18.0537C51.8507 17.788 51.4517 17.6158 51.0289 17.5546L35.6106 15.3168L28.7183 1.34843C28.5188 0.943718 28.2101 0.602921 27.827 0.364612C27.4439 0.126303 27.0017 0 26.5505 0C26.0993 0 25.6572 0.126303 25.2741 0.364612C24.8909 0.602921 24.5822 0.943718 24.3828 1.34843L17.488 15.3168Z" fill="url(#paint0_radial_2420_3511)"/>
               <defs>
               <radialGradient id="paint0_radial_2420_3511" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(31 21) rotate(123.425) scale(29.9541 31.3503)">
               <stop stop-color="#FFE921"/>
               <stop offset="1" stop-color="#F2A201"/>
               </radialGradient>
               </defs>
          </svg>
          <svg class="w-8 h-8 md:w-12 md:h-12" viewBox="0 0 54 51" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M17.488 15.3168L2.06968 17.5522L1.79659 17.6078C1.3832 17.7175 1.00633 17.935 0.704484 18.238C0.402635 18.5411 0.186619 18.9188 0.0784943 19.3326C-0.0296306 19.7464 -0.02599 20.1815 0.0890441 20.5935C0.204078 21.0054 0.426384 21.3795 0.733261 21.6774L11.9031 32.55L9.26893 47.9079L9.23751 48.1738C9.2122 48.6013 9.30099 49.0279 9.49476 49.4099C9.68854 49.7919 9.98035 50.1155 10.3403 50.3476C10.7003 50.5797 11.1155 50.712 11.5434 50.7309C11.9713 50.7497 12.3965 50.6546 12.7755 50.4551L26.565 43.2051L40.3231 50.4551L40.5648 50.5663C40.9637 50.7234 41.3972 50.7716 41.8208 50.7058C42.2445 50.6401 42.643 50.4629 42.9756 50.1923C43.3082 49.9217 43.5628 49.5676 43.7133 49.1661C43.8638 48.7647 43.9048 48.3304 43.8321 47.9079L41.1955 32.55L52.3702 21.675L52.5587 21.4696C52.8279 21.1379 53.0045 20.7409 53.0703 20.3188C53.1362 19.8967 53.089 19.4647 52.9335 19.0668C52.778 18.6689 52.5198 18.3193 52.1853 18.0537C51.8507 17.788 51.4517 17.6158 51.0289 17.5546L35.6106 15.3168L28.7183 1.34843C28.5188 0.943718 28.2101 0.602921 27.827 0.364612C27.4439 0.126303 27.0017 0 26.5505 0C26.0993 0 25.6572 0.126303 25.2741 0.364612C24.8909 0.602921 24.5822 0.943718 24.3828 1.34843L17.488 15.3168Z" fill="url(#paint0_radial_2420_3511)"/>
               <defs>
               <radialGradient id="paint0_radial_2420_3511" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(31 21) rotate(123.425) scale(29.9541 31.3503)">
               <stop stop-color="#FFE921"/>
               <stop offset="1" stop-color="#F2A201"/>
               </radialGradient>
               </defs>
          </svg>
          <svg class="w-8 h-8 md:w-12 md:h-12" viewBox="0 0 54 51" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M17.488 15.3168L2.06968 17.5522L1.79659 17.6078C1.3832 17.7175 1.00633 17.935 0.704484 18.238C0.402635 18.5411 0.186619 18.9188 0.0784943 19.3326C-0.0296306 19.7464 -0.02599 20.1815 0.0890441 20.5935C0.204078 21.0054 0.426384 21.3795 0.733261 21.6774L11.9031 32.55L9.26893 47.9079L9.23751 48.1738C9.2122 48.6013 9.30099 49.0279 9.49476 49.4099C9.68854 49.7919 9.98035 50.1155 10.3403 50.3476C10.7003 50.5797 11.1155 50.712 11.5434 50.7309C11.9713 50.7497 12.3965 50.6546 12.7755 50.4551L26.565 43.2051L40.3231 50.4551L40.5648 50.5663C40.9637 50.7234 41.3972 50.7716 41.8208 50.7058C42.2445 50.6401 42.643 50.4629 42.9756 50.1923C43.3082 49.9217 43.5628 49.5676 43.7133 49.1661C43.8638 48.7647 43.9048 48.3304 43.8321 47.9079L41.1955 32.55L52.3702 21.675L52.5587 21.4696C52.8279 21.1379 53.0045 20.7409 53.0703 20.3188C53.1362 19.8967 53.089 19.4647 52.9335 19.0668C52.778 18.6689 52.5198 18.3193 52.1853 18.0537C51.8507 17.788 51.4517 17.6158 51.0289 17.5546L35.6106 15.3168L28.7183 1.34843C28.5188 0.943718 28.2101 0.602921 27.827 0.364612C27.4439 0.126303 27.0017 0 26.5505 0C26.0993 0 25.6572 0.126303 25.2741 0.364612C24.8909 0.602921 24.5822 0.943718 24.3828 1.34843L17.488 15.3168Z" fill="url(#paint0_radial_2420_3511)"/>
               <defs>
               <radialGradient id="paint0_radial_2420_3511" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(31 21) rotate(123.425) scale(29.9541 31.3503)">
               <stop stop-color="#FFE921"/>
               <stop offset="1" stop-color="#F2A201"/>
               </radialGradient>
               </defs>
          </svg>
     </div>
</template>