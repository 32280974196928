<template>
    <div class="relative inline-block text-sm leading-tight w-full" ref="dropdown">
        <div v-if="internalSelectedOption" @click="clearSelected()" class="absolute right-1.5 top-3.5 cursor-pointer">
            <img :src="baseUrl.img+'/assets/img/modal/exclude.svg'" alt="" />
        </div>
      <input
        type="text"
        v-model="searchQuery"
        @focus="isOpen = true"
        @input="filterOptions"
        @change="clearFormErrorFunction(keyValue)"
        class="bg-white border border-shade-10 text-shade-2 text-sm rounded-md focus:ring-background-2 focus:border-background-2 block w-full py-2.5 pl-2.5 pr-3 font-normal"
        :class="
            form.errors?.errors?.[keyValue] != null
            ? 'border-primal-red focus:ring-primal-red focus:border-primal-red'
            : 'border-shade-10 focus:ring-background-2 focus:border-background-2'
        "
        :placeholder="placeholder"
        autocomplete="off"
      />
      <div class="relative">
        <select v-model="internalSelectedOption" class="w-full h-0 opacity-0 absolute" multiple>
          <option v-for="option in filteredOptions" :key="option[optionValue]" :value="option[optionValue]">
            {{ option[optionLabel] }}
          </option>
        </select>
      </div>
      <div v-if="isOpen">
        <ul v-if="filteredOptions.length" class="absolute bg-white border w-full rounded mt-1 max-h-48 overflow-auto z-1">
            <li
            v-for="option in filteredOptions"
            :key="option[optionValue]"
            @click="selectOption(option)"
            class="px-2 py-1 cursor-pointer hover:bg-background-3 hover:text-white"
            >
            {{ option[optionLabel] }}
            </li>
        </ul>
        <ul v-else class="absolute bg-white border w-full rounded mt-1 max-h-48 overflow-auto z-1">
            <li
                class="px-2 py-1"
            >
                No data found.
            </li>
        </ul>
      </div>

    </div>
  </template>

  <script>
  import { ref, computed, watch, onMounted, onBeforeUnmount } from 'vue'
  import { baseUrl } from "config/services.js";

  export default {
    name: 'SearchableSelect',
    props: {
      options: {
        type: Array,
        required: true,
      },
      selectedOption: {
        type: [String, Number, null],
        default: "",
      },
      optionLabel: {
        type: String,
        required: true,
      },
      optionValue: {
        type: String,
        required: true,
      },
      keyValue: {
        type: String,
        required: false,
      },
      form: {
        type: Object,
        required: true
      },
      placeholder: {
        type: String,
        required: false,
      },
      clearFormErrorFunction: {
        type: Function,
        required: false,
      }
    },
    setup(props, { emit }) {
      const { clearFormErrorFunction } = props;
      const searchQuery = ref('')
      const isOpen = ref(false)
      const internalSelectedOption = ref(props.selectedOption)
      const dropdown = ref(null)

      const filteredOptions = computed(() => {
        if (!searchQuery.value) {
          return props.options
        }
        return props.options.filter(option =>
          option[props.optionLabel].toLowerCase().includes(searchQuery.value.toLowerCase())
        )
      })

      const selectOption = (option) => {
        internalSelectedOption.value = option[props.optionValue]
        searchQuery.value = option[props.optionLabel]
        isOpen.value = false
        if(clearFormErrorFunction){
            clearFormErrorFunction(props.keyValue)
        };
        emit('update:selectedOption', option[props.optionValue])
      }

      const filterOptions = () => {
        if (!searchQuery.value) {
          isOpen.value = true
        }
      }

      const handleClickOutside = (event) => {
        if (dropdown.value && !dropdown.value.contains(event.target)) {
          isOpen.value = false
        }
      }

      const clearSelected = () => {
        searchQuery.value = "";
        internalSelectedOption.value = "";
        emit('update:selectedOption', "");
      }

      onMounted(() => {
        document.addEventListener('click', handleClickOutside);
        if(props.options.length){
            internalSelectedOption.value = props.selectedOption;
            const selected = props.options.find(option => option[props.optionValue] === props.selectedOption);
            if (selected) {
                searchQuery.value = selected[props.optionLabel];
            } else {
                searchQuery.value = '';
            }
        }
      })

      onBeforeUnmount(() => {
        document.removeEventListener('click', handleClickOutside);
      })

      watch(() => props.options, (newValue) => {
        if(newValue.length){
            internalSelectedOption.value = props.selectedOption;
            const selected = props.options.find(option => option[props.optionValue] === props.selectedOption);
            if (selected) {
                searchQuery.value = selected[props.optionLabel];
            } else {
                searchQuery.value = '';
            }
        }
      })

      watch(() => props.selectedOption, (newValue) => {
        internalSelectedOption.value = newValue;
        const selected = props.options.find(option => option[props.optionValue] === newValue);
        if (selected) {
          searchQuery.value = selected[props.optionLabel];
        } else {
          searchQuery.value = '';
        }
      })

      return {
        searchQuery,
        isOpen,
        filteredOptions,
        selectOption,
        filterOptions,
        internalSelectedOption,
        dropdown,
        clearSelected,
        clearFormErrorFunction,
        baseUrl
      }
    },
  }
  </script>
