<template>
     <div v-if="list != null" class="w-full bg-primary-2 relative flex flex-col justify-center items-center">
          <div
               v-if="list?.data.length != 0"
               class="w-full h-full flex flex-col justify-start items-center pb-8 xs:pb-12 sm:pb-18 md:pb-24 lg:pb-28 xl:pb-32 pt-8 xs:pt-12 sm:pt-18 md:pt-24 lg:pt-0"
               :class="page == 'home' ? '3xl:max-w-screen-3xl px-5 xs:px-8 sm:px-18 md:px-24 lg:px-16 xl:px-32' : 'px-5 xs:px-8 sm:px-18 md:px-24 lg:px-28 xl:px-32 4xl:px-0 4xl:max-w-screen-3xl'"
          >
               <p class="w-full text-start mb-5 helveticaLight tracking-wide text-white text-base md:text-lg xl:text-xl font-bold">
                    Favorites Games
               </p>
               <div class="w-full">
                    <Carousel
                         :itemsToShow="2"
                         :wrapAround="false"
                         :mouseDrag="false"
                         :touchDrag="false"
                         snapAlign="start"
                         :transition="500"
                         class="home-slider-list"
                         :breakpoints="{
                              768: {
                                   itemsToShow: 3,
                              },
                              1024: {
                                   itemsToShow: 4,
                              },
                              1280: {
                                   itemsToShow: 5,
                              },
                         }"
                    >
                         <Slide
                              v-for="(item, key) in list?.data"
                              :key="key"
                         >
                              <div
                                   class="game-card rounded-xl bg-primary-1 w-full h-max relative p-1 mx-1.5 md:mx-2.5"
                                   @click="() => goToDetail(item.game_name, item.id)"
                              >
                                   <div
                                        v-if="isUser"
                                        class="absolute top-2 right-2 rounded-xl z-10"
                                   >
                                        <span
                                             @click="(e) => {
                                                  e.stopPropagation();
                                                  e.preventDefault();
                                                  onStar(item.id, heartData?.includes(item.id))
                                             }"
                                             class="text-2xl md:text-3xl icon-addFavorites cursor-pointer"
                                             :class="heartData?.includes(item.id) ? 'active' : ''"
                                        ></span>
                                   </div>
                                   <div
                                        v-if="isUser"
                                        class="game-card-overlay absolute top-0 left-0 rounded-xl cursor-pointer"
                                   >
                                        <div class="flex flex-col justify-center items-center h-full">
                                             <div
                                                  @click="(e) => {
                                                       e.stopPropagation();
                                                       e.preventDefault();
                                                       onLaunch(item, 'gold')
                                                  }"
                                                  class="bg-background-2 w-10/12 sm:w-9/12 md:w-10/12 xl:w-11/12 2xl:w-9/12 h-7 xs:h-8 sm:h-10 md:h-8 lg:h-9 xl:h-10 2xl:h-12 rounded-full relative cursor-pointer"
                                             >
                                                  <div class="flex items-center justify-center tracking-wider h-full">
                                                       <img
                                                            loading="lazy"
                                                            class="cursor-pointer w-7 xs:w-8 sm:w-10 md:w-8 lg:w-9 xl:w-10 2xl:w-12 h-7 xs:h-8 sm:h-10 md:h-8 lg:h-9 xl:h-10 2xl:h-12 absolute top-0 left-0"
                                                            :src="baseUrl.img+'/assets/img/header/GC.svg'"
                                                            alt=""
                                                       >
                                                       <span class="ml-5 sm:ml-8 2xl:ml-10 text-white text-mid md:text-xs xl:text-sm">{{ money(gold_balance) }}</span>
                                                  </div>
                                             </div>
                                             <div
                                                  @click="(e) => {
                                                       e.stopPropagation();
                                                       e.preventDefault();
                                                       onLaunch(item, 'silver')
                                                  }"
                                                  class="bg-background-2 w-10/12 sm:w-9/12 md:w-10/12 xl:w-11/12 2xl:w-9/12 h-7 xs:h-8 sm:h-10 md:h-8 lg:h-9 xl:h-10 2xl:h-12 rounded-full relative mt-3 sm:mt-5 md:mt-3 2xl:mt-5 cursor-pointer"
                                             >
                                                  <div class="flex items-center justify-center tracking-wider h-full">
                                                       <img
                                                            loading="lazy"
                                                            class="cursor-pointer w-7 xs:w-8 sm:w-10 md:w-8 lg:w-9 xl:w-10 2xl:w-12 h-7 xs:h-8 sm:h-10 md:h-8 lg:h-9 xl:h-10 2xl:h-12 absolute top-0 left-0"
                                                            :src="baseUrl.img+'/assets/img/header/SC.svg'"
                                                            alt=""
                                                       >
                                                       <span class="ml-5 sm:ml-8 2xl:ml-10 text-white text-mid md:text-xs xl:text-sm">{{ money(silver_balance) }}</span>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                                   <div class="games-aspect-ratio-3-4 relative items-center cursor-pointer">
                                        <img
                                             loading="lazy"
                                             :src="baseUrl.img+'/assets/img/loader/popular-loader.png'"
                                             alt="game"
                                             class="absolute top-0 left-0 rounded-t-md w-full h-full game-thumbnails"
                                        >
                                        <div class="absolute top-0 left-0 w-full h-full flex justify-center items-center">
                                             <img
                                                  loading="lazy"
                                                  v-if="item.game_pic != null"
                                                  :src="toWebp(item.game_pic, 'games')"
                                                  :alt="getProvider(item.provider_id) +'-'+ item.game_pic"
                                                  class="rounded-t-md w-full hidden md:block game-thumbnails"
                                                  style="height: inherit;"
                                             >
                                             <img
                                                  loading="lazy"
                                                  v-else
                                                  :src="baseUrl.img+'/assets/img/loader/popular-loader.png'"
                                                  alt="game"
                                                  class="rounded-t-md w-full h-auto hidden md:block game-thumbnails"
                                             >
                                             <img
                                                  loading="lazy"
                                                  v-if="item.game_pic_mobile != null"
                                                  :src="toWebp(item.game_pic_mobile, 'games')"
                                                  :alt="getProvider(item.provider_id) +'-'+ item.game_name"
                                                  class="rounded-t-md w-full h-auto block md:hidden game-thumbnails"
                                                  style="height: inherit;"
                                             >
                                             <img
                                                  loading="lazy"
                                                  v-else
                                                  :src="baseUrl.img+'/assets/img/loader/popular-loader.png'"
                                                  alt="game"
                                                  class="rounded-t-md w-full h-auto block md:hidden game-thumbnails"
                                             >
                                        </div>
                                   </div>
                                   <div class="flex justify-between items-center w-full px-4 py-4">
                                        <div class="game-name">
                                             <p class="helveticaLight tracking-wide text-start text-secondary-1 text-sm md:text-base xl:text-lg">
                                                  {{item.game_name}}
                                             </p>
                                        </div>
                                        <img
                                             loading="lazy"
                                             :src="baseUrl.img+'/assets/img/home/game/moozi.webp'"
                                             alt="moozi"
                                             class="rounded-md w-6 md:w-8 xl:w-11 h-6 md:h-8 xl:h-11"
                                        >
                                   </div>
                              </div>
                         </Slide>
                         <template #addons>
                              <Navigation/>
                         </template>
                    </Carousel>
               </div>
          </div>
     </div>
     <loading-overlay :isLoading="loading" />
</template>
<script>
import { computed, onMounted, reactive, ref, watch } from "vue";
import { Carousel, Navigation, Pagination, Slide } from "vue3-carousel";
import { gamesStore } from "store/games";
import _ from "underscore";
import { useRouter } from "vue-router";
import { useFormatter } from "composables/use-formatter.js";
import { useImage } from "composables/use-image.js";
import { authStore } from "store/auth.js";
import { setFavorites as setFavoritesAPI } from "@/api/games";
import { gameLauncher as gameLauncherAPI } from "@/api/seamless";
import { useToaster } from "composables/use-toaster.js";
import LoadingOverlay from "components/loading/loadingOverlay.vue";
import { baseUrl } from "config/services.js";

export default {
     components: {
          Carousel,
          Slide,
          Pagination,
          Navigation,
          LoadingOverlay
     },
     props: ["page"],
     setup() {
          const { money } = useFormatter();
          const { toWebp } = useImage();
          const router = useRouter();
          const { successToast, errorToast } = useToaster();

          const useAuthStore = authStore();
          const useGamesStore = gamesStore();

          const loading = ref(false);

          const params = reactive({
               game_name: "",
               game_promo: 0,
               game_type: [],
               provider_id: [],
               page: 1,
               itemsPerPage: 20,
               sortType: "a-z"
          })

          const providerData = computed(() => {
               if (useGamesStore.provider != null) {
                    setTimeout(async () => {
                         params.provider_id = [..._.pluck(useGamesStore.provider, "id")];

                         await useGamesStore.getFavoriteSlider(params);
                    }, 0);
               }
               return useGamesStore.provider || null;
          });

          const list = computed(() => {
               return useGamesStore.favoriteSlider || null;
          });

          const isUser = computed(() => {
               return useAuthStore.user;
          });

          const kycStatus = computed(() => {
               return useAuthStore.kycStatus;
          });

          const heartData = computed(() => {
               return useGamesStore.heart || null;
          });

          const gold_balance = computed(() => {
               return useAuthStore.gold_balance;
          });

          const silver_balance = computed(() => {
               return parseFloat(useAuthStore.silver_balance) + parseFloat(useAuthStore.silver_balance_redeem);
          });



          const goToDetail = (name, id) => {
               router.push({
                    path:
                         "/games/detail/" +
                         name.replaceAll(" ", "-") +
                         "-" +
                         id,
               });
          };

          const onStar = async (id, status) => {
               loading.value = true
               const data = { game_id: id };

               if (status == true) {
                    data["unset"] = 1;
               }

               await setFavoritesAPI(data);
               await useGamesStore.getFavoriteSlider(params, true);
               loading.value = false
          };

          const onLaunch = async (slug, type) => {
               if (isUser.value) {
                    if(openKyc() == true) {
                         return;
                    };

                    const filter = _.filter(
                         providerData.value,
                         function (params) {
                              return params.id == slug.provider_id;
                         }
                    );

                    await gameLauncherAPI(
                         { game_slug: slug.game_slug, type: type },
                         filter[0]["game_source"]
                    )
                         .then((res) => {
                              if (res.status) {
                                   if (
                                        /iPad|iPhone|iPod/.test(
                                             navigator.userAgent
                                        )
                                   ) {
                                        // alert("This is an iOS device.");
                                        setTimeout(() => {
                                            //  window.open(res.url, "_blank");
                                            useAuthStore.setActiveCoinType(type);
                                            useAuthStore.setActiveGameUrl(res.url);
                                            router.push({ name: 'play', params: { slug: slug.game_slug, id: slug.provider_id, launchcode: res.launch_code, type: type } });
                                        });
                                   } else {
                                        // window.open(res.url, "_blank");
                                        useAuthStore.setActiveCoinType(type);
                                        useAuthStore.setActiveGameUrl(res.url);
                                        router.push({ name: 'play', params: { slug: slug.game_slug, id: slug.provider_id, launchcode: res.launch_code, type: type } });
                                   }
                              }
                         })
                         .catch((e) => {
                              errorToast("Something went wrong!", "top-right");
                         });
               } else {
                    router.push({ name: "login" });
               }
          };

          const openKyc = () => {
               if (!kycStatus.value && !isUser.value["address"]) {
                    eventBus.emit("open:kyc");
                    router.replace({ query: { modal: "kyc" } });

                    return true;
               } else if (!kycStatus.value && isUser.value["address"]) {
                    eventBus.emit("open:idverification");
                    router.replace({ query: { modal: "idverification" } });

                    return true;
               }

               return false;
          };

          const getProvider = (id) => {
               const filter = _.filter(providerData.value, (params) => {
                    return params['id'] == id
               })

               return filter[0]['name']
          }

          // dont remove ill kill you hahahaha its hard to debug
          watch(providerData, async (newVal) => {
               // if (newVal!= null) {
               //      params.provider_id = [..._.pluck(providerData.value, "id")];

               //      await useGamesStore.getFavoriteSlider(params);
               // }
          });

          return {
               list,
               goToDetail,
               isUser,
               onStar,
               heartData,
               gold_balance,
               silver_balance,
               onLaunch,
               openKyc,
               loading,
               toWebp,
               money,
               getProvider,
               baseUrl
          }
     }
}
</script>
