<template>

     <Head title="Forgot Password"></Head>
     <div class="w-full h-full relative flex justify-center mt-20 lg:mt-0">
          <div class="z-0 w-full h-100-vh welcome-bg-container bg-background-2 fixed top-0 left-0"></div>
          <div class="w-full 2xl:max-w-screen-2xl bg-transparent relative">
               <div class="w-full h-screem flex flex-col lg:flex-row justify-start md:justify-center items-center px-4 xs:px-4 sm:px-8 md:px-8 lg:px-12 xl:px-24 pt-14 pb-8 xs:pb-12 sm:pb-18 md:pb-24 lg:pb-28 xl:pb-32 gap-4 relative">
                    <div class="hidden lg:flex h-full flex-1 flex-col justify-end items-center relative z-10">
                         <h1 class="text-white font-helvetica tracking-wide text-center text-6xl xs:text-6xl sm:text-7xl md:text-7xl xl:text-8xl leading-5xl xs:leading-5xl sm:leading-6xl md:leading-6xl xl:leading-7.5xl italic relative z-10">
                              JOIN US NOW!
                         </h1>
                         <h2 class="text-white font-helvetica tracking-wide text-center text-3xl xs:text-3xl sm:text-4xl md:text-4xl xl:text-5xl leading-5xl xs:leading-5xl sm:leading-6xl md:leading-6xl xl:leading-7.5xl relative z-10">
                              TO OBTAIN <span class="text-secondary-3">50,000</span> GOLD COINS
                         </h2>
                         <div class="w-full flex justify-center items-center absolute top-0 h-full xl:h-0">
                              <img
                                   id="dash-forgot"
                                   class="absolute w-auto"
                                   :src="baseUrl.img+'/assets/img/auth/hero-animated-dash.png'"
                                   alt=""
                              >
                              <img
                                   id="coin-forgot"
                                   class="absolute w-auto z-0"
                                   :src="baseUrl.img+'/assets/img/auth/hero-animated-coin.png'"
                                   alt=""
                              >
                              <img
                                   id="cow-forgot"
                                   class="absolute w-auto z-0"
                                   :src="baseUrl.img+'/assets/img/auth/hero-animated-cow.png'"
                                   alt=""
                              >

                         </div>
                    </div>
                    <div class="flex-initial justify-center items-center max-w-md w-full">
                         <div class="relative tracking-tight m-auto">
                              <div class="relative bg-gradient-to-t to-55% from-background-2 to-background-3 from-0% rounded-lg shadow-xl">
                                   <div class="text-right pt-5 pb-2 mb-2">
                                        <div class="px-4 xs:px-6">
                                             <h3 class="text-xl xs:text-3xl font-semibold uppercase text-shade-1">
                                                  Forgot Password
                                             </h3>
                                        </div>
                                   </div>
                                   <div class="px-4 xs:px-6 font-inter">
                                        <form
                                             @submit.prevent="submitForgotPassword"
                                             class="space-y-3"
                                        >
                                             <div>
                                                  <label
                                                       for="email"
                                                       class="block mb-2 text-base font-medium text-background-4"
                                                  >Email Address
                                                  </label>
                                                  <div class="relative">
                                                       <input
                                                            v-model="form.email"
                                                            type="email"
                                                            name="email"
                                                            id="email"
                                                            class="bg-white border border-background-3 text-shade-2 text-base rounded-md focus:ring-background-2 focus:border-background-2 block w-full p-2.5"
                                                            placeholder="Enter your email"
                                                            autocomplete="username"
                                                            @change="clearFormError('email')"
                                                            :class="
                          form.errors?.errors?.email != null
                            ? 'border-primal-red focus:ring-primal-red focus:border-primal-red'
                            : 'border-background-3 focus:ring-background-2 focus:border-background-2'
                        "
                                                       />
                                                  </div>
                                                  <has-error-form
                                                       v-model:form="form"
                                                       :field="'email'"
                                                  ></has-error-form>
                                             </div>

                                             <button
                                                  type="submit"
                                                  class="w-full text-primary-1 bg-secondary-3 px-4 py-3 xl:py-3.5 text-center leading-tight shadow-xl rounded-full font-roboto font-bold text-sm sm:text-base md:text-lg lg:text-base xl:text-lg"
                                             >
                                                  Submit
                                             </button>

                                             <div class="text-base font-medium text-background-3 text-center pt-2 pb-8">
                                                  Already have an account?
                                                  <span
                                                       @click.prevent="openLogin()"
                                                       class="text-base text-secondary-1 underline cursor-pointer"
                                                  >Log In</span>
                                             </div>
                                        </form>
                                   </div>
                              </div>
                         </div>
                    </div>
                    <loading-overlay :isLoading="loading" />
               </div>
          </div>
     </div>
</template>

  <script>
import { Head } from "@inertiajs/vue3";
import { onMounted, ref, reactive, computed, watch } from "vue";
import { authStore } from "store/auth";
import Form from "vform";
import LoadingOverlay from "components/loading/loadingOverlay.vue";
import { useRouter } from "vue-router";
import { utilitiesStore } from "store/utilities.js";
import HasErrorForm from "components/haserror/index.vue";
import { gsap } from "gsap";
import { useScroll } from "composables/use-scroll.js";
import { baseUrl } from "config/services.js";

export default {
     components: {
          LoadingOverlay,
          HasErrorForm,
          Head,
     },
     setup() {
          const useAuthStore = authStore();
          const router = useRouter();
          const { scrollTop } = useScroll();

          const loading = ref(false);
          const showPassword = ref(false);
          const useUtilitiesStore = utilitiesStore();

          const form = reactive(
               new Form({
                    email: "",
               })
          );

          const preloading = computed(() => {
               return useUtilitiesStore.loading;
          });
          const error_msg = computed(() => {
               return useAuthStore.error_msg;
          });

          const openLogin = () => {
               form.reset();
               router.push({ name: "login" });
          };

          const submitForgotPassword = () => {
               loading.value = true;
               useAuthStore
                    .forgotPassword(form)
                    .then(() => {
                         form.reset();
                         router.push({ name: "login" });
                         loading.value = false;
                    })
                    .catch((e) => {
                         loading.value = false;
                    });
          };

          const clearFormError = (key) => {
               if (typeof form.errors.errors[key] !== "undefined") {
                    delete form.errors.errors[key];
               }
          };

          const cowAnimation = () => {
               gsap.timeline()
                    .from("#cow-forgot", {
                         opacity: 0,
                         xPercent: 50,
                         yPercent: -30,
                         scale: 0.5,
                         duration: 0.3,
                         ease: "sine.in",
                    })
                    .to("#cow-forgot", {
                         x: -10,
                         y: 10,
                         scale: 1,
                         opacity: 0.93,
                         duration: 0.2,
                         ease: "power1.out",
                    })
                    .to("#cow-forgot", {
                         x: 5,
                         y: -5,
                         scale: 0.96,
                         duration: 0.2,
                         ease: "power1.out",
                    })
                    .to("#cow-forgot", {
                         x: 0,
                         y: 0,
                         scale: 1,
                         opacity: 1,
                         duration: 0.3,
                         ease: "power1.out",
                    });
          };

          const dashAnimation = () => {
               gsap.timeline()
                    .from("#dash-forgot", {
                         opacity: 0,
                         duration: 0.3,
                         ease: "sine.in",
                    })
                    .to("#dash-forgot", {
                         opacity: 1,
                         duration: 0.2,
                         ease: "power1.out",
                    });
          };

          const coinAnimation = () => {
               gsap.timeline()
                    .from("#coin-forgot", {
                         opacity: 0,
                         duration: 0.3,
                         ease: "sine.in",
                    })
                    .to("#coin-forgot", {
                         opacity: 1,
                         duration: 0.2,
                         ease: "power1.out",
                    });
          };

          onMounted(async () => {
               scrollTop();
               if (!preloading.value) {
                    cowAnimation();
                    dashAnimation();
                    coinAnimation();
               }
          });

          watch(error_msg, (newVal) => {
               if (newVal) {
                    form.errors.errors = newVal;
               }
          });

          return {
               form,
               loading,
               showPassword,
               openLogin,
               submitForgotPassword,
               clearFormError,
               baseUrl
          };
     },
};
</script>
