<template>
     <div class="rounded-full w-full h-11 bg-primary-4 shadow-lg flex flex-row items-center">
          <span class="icon-search text-3xl ml-2 md:ml-3"></span>
          <input
               type="text"
               class="search-game p-0 pl-1 md:pl-2 h-full !bg-primary-4 font-roboto font-extrabold !text-primary-1 text-sm md:text-base xl:text-lg outline-none border-none !shadow-none focus:shadow-none"
               :class="hideDropdown == true ? 'rounded-r-full' : ''"
               placeholder="Search"
               v-model="search"
               @keyup="() => onSearch(search)"
               style="width: inherit;"
          >
          <FilterGame
               :provider="provider" 
               :isDropdown="isDropdown" 
               :onDropdown="(val) => {onDropdown(val)}" 
               :selectedDropdown="selectedDropdown"
               :onSelect="onSelect"
               :getSelectedDropwdown="getSelectedDropwdown"
               :hideDropdown="hideDropdown"
          />
     </div>
</template>
<script>
import FilterGame from "components/games/filter/index.vue";
import { ref } from "vue";

export default {
     props: ['onSearch', 'provider', 'isDropdown', 'onDropdown', 'selectedDropdown', 'onSelect', 'getSelectedDropwdown', 'hideDropdown'],
     components: {
          FilterGame
     },
     setup(props, { emit }) {

          const search = ref("");

          return {
               search
          };
     },
};
</script>