<template>

     <div v-if="selfExclusion == null && coin != null" class="bg-white w-full flex flex-col justify-start rounded-b-xl lg:rounded-bl-none rounded-r-none lg:rounded-r-xl " style="min-height: 400px;">
          <div class="flex flex-row gap-2">
               <div
                    @click="tabs = 'credit'"
                    class="hover:bg-background-3 hover:text-white font-helveticaLight tracking-wide rounded-full px-8 py-2 cursor-pointer transition-colors duration-300"
                    :class="tabs == 'credit' ? 'bg-background-3 text-white' : 'bg-white text-background-3 font-bold'"
               >
                    Credit Card
               </div>
               <div
                    @click="tabs = 'wallet'"
                    class="hover:bg-background-3 hover:text-white font-helveticaLight tracking-wide rounded-full px-8 py-2 cursor-pointer transition-colors duration-300"
                    :class="tabs == 'wallet' ? 'bg-background-3 text-white' : 'bg-white text-background-3 font-bold'"
               >
                    Bank Transfer
               </div>
          </div>
          <CreditCard
               v-if="tabs == 'credit'"
               :coins="coin"
               :setLoading="setLoading"
               :cardDetails="cardDetails"
          />
          <Wallet
               v-if="tabs == 'wallet'"
               :tabs="tabs"
               :coins="coin"
               :setLoading="setLoading"
          />
     </div>
     <SelfExclusionAlert :selfExclusion="selfExclusion" :lightMode="true" v-else-if="selfExclusion != null" />

     <paymentSuccess :successCoins="paymentSuccessCoins" />
     <paymentFailed />
     <loading-overlay :isLoading="loading" />
</template>
<script>
import { onMounted, reactive, ref, computed } from "vue";
import { Head } from "@inertiajs/vue3";
import _ from "underscore";
import { useFormatter } from "composables/use-formatter.js";
import { useRouter, useRoute } from "vue-router";
import LoadingOverlay from "components/loading/loadingOverlay.vue";
import HasErrorForm from "components/haserror/index.vue";
import paymentSuccess from "components/modals/paymentSuccess.vue";
import paymentFailed from "components/modals/paymentFailed.vue";
import CreditCard from "./component/credit.vue";
import Wallet from "./component/wallet.vue";
import { utilitiesStore } from "store/utilities.js";
import { cardStore } from "store/cards.js";
import SelfExclusionAlert from "components/selfExclusionAlert/index.vue";

export default {
     components: {
          LoadingOverlay,
          Head,
          HasErrorForm,
          paymentSuccess,
          paymentFailed,
          CreditCard,
          Wallet,
          SelfExclusionAlert
     },
     setup() {
          
          const router = useRouter();
          const route = useRoute();
          const { money } = useFormatter();
          const useUtilitiesStore = utilitiesStore();
          const useCardStore = cardStore();

          const loading = ref(false);

          const tabs = ref(route.query.panel ?? "credit");

          const selfExclusion = ref(null);

          const cardDetails = computed(() => {
               return useCardStore.cards ?? [];
          });

          const coin = computed(() => {
               if(useUtilitiesStore?.selected_coin?.type == 'days' || useUtilitiesStore?.selected_coin?.type == 'time') {
                    selfExclusion.value = useUtilitiesStore.selected_coin
               }

               return useUtilitiesStore.selected_coin;
          });

          const paymentSuccessCoins = reactive({
               gold_coins: 0,
               silver_coins: 0,
          });

          const goTo = () => {
               router.push({ path: "/gold-coin-shop" });
          };

          const setLoading = (data) => {
               loading.value = data;
          };

          const fetchGetCoins = async () => {
               await useUtilitiesStore.getCoins({
                    id: route.path.split("/")[2],
               });
          };

          async function setPaymentSuccessCoins(res) {
               paymentSuccessCoins.gold_coins = money(res.gold_coins);
               paymentSuccessCoins.silver_coins = money(res.silver_coins);
          }

          const getPercentage = (discount, amount) => {
               return money(amount - (discount / 100) * amount);
          };

          onMounted(async () => {
               window.scrollTo({
                    top: 0,
                    behavior: "smooth",
               });
               await fetchGetCoins();
               await useUtilitiesStore.getState();
               await useCardStore.getCards();
          });

          return {
               goTo,
               coin,
               money,
               tabs,
               loading,
               setLoading,
               paymentSuccessCoins,
               setPaymentSuccessCoins,
               cardDetails,
               getPercentage,
               selfExclusion
          };
     },
};
</script>