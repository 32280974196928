import axios from "axios";
// import { ElMessage } from 'element-plus';
import { isLogged, getToken } from 'utils/auth.js';
import { useToaster } from "composables/use-toaster.js";
import  {baseUrl} from "config/services.js";
import { useRouter, useRoute } from "vue-router";

const { successToast, errorToast } = useToaster();
const route = useRoute();

// Create axios instance
const service = window.axios.create({
  baseURL: baseUrl.api,
  timeout: 60000, // Request timeout
});

// Request intercepter
service.interceptors.request.use(
  config => {
    const token = isLogged();
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + getToken(); // Set JWT token
    }
    return config;
  },
  error => {
    // Do something with request error
    Promise.reject(error);
  }
);

// response pre-processing
service.interceptors.response.use(
  response => {
    // if (response.headers.authorization) {
    //   setLogged(response.headers.authorization);
    //   response.data.token = response.headers.authorization;
    // }

    return response.data;
  },
  error => {
    let message = error.message;
    if (error.response.data && error.response.data.message) {
      message = error.response.data.message;
    }
    if(error.response.statusText == "Unauthorized" && error.response.config.url != "/login") {
        window.location.reload();
    }
    if(error.response.data.error){
      if(window.location.pathname != '/login') {
        errorToast(error.response.data.message ? error.response.data.message : error.response.data.error, "bottom-center");
      }
    }
    // ElMessage({
    //   message: message,
    //   type: 'error',
    //   duration: 5 * 1000,
    // });
    return Promise.reject(error);
  }
);

export default service;
