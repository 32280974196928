<template>
    <transition
        enter-from-class="opacity-0 -translate-y-1.5"
        enter-active-class="transition ease-in duration-300"
        enter-to-class="opacity-100 translate-y-0"
        leave-from-class="opacity-100 translate-y-0"
        leave-active-class="transition ease-out duration-300"
        leave-to-class="opacity-0 -translate-y-1.5"
    >
        <HasError
            class="text-xs text-primal-red ease-in-out transition-all duration-300 font-inter"
            :form="form"
            :field="field"
        />
    </transition>
</template>

<script>

export default {
    props: {
        form: {
            type: Object,
            required: true,
        },
        field: {
            type: String,
            required: true
        }
    },
  setup(){
    return {
    }
  }
};
</script>
