<template>
     <div
          class="w-full bg-background-3 relative flex flex-col justify-center items-center"
          id="popular-container"
     >
          <div class="w-full 3xl:max-w-screen-3xl h-full flex flex-col justify-center items-center px-0 md:px-0 lg:px-6 xl:px-12 2xl:px-28 pb-0 lg:pb-28 xl:pb-32 pt-6 xs:pt-10 sm:pt-14 md:pt-20 lg:pt-0">
               <div class="w-full">
                    <div class="flex flex-col justify-center items-center w-full">
                         <h1 class="text-white font-helveticaItalic tracking-wide italic text-4xl xs:text-5xl sm:text-7xl md:text-7xl xl:text-8xl leading-3xl xs:leading-4xl sm:leading-6xl md:leading-6xl xl:leading-7.5xl">POPULAR
                         </h1>
                         <div class="mt-2">
                              <Star/>
                         </div>
                    </div>
               </div>
               <div class="mt-10 w-full hidden md:block">
                    <div class="w-full flex justify-center">
                         <img
                              loading="lazy"
                              id="bee-cow-popular"
                              class="absolute top-24 z-10"
                              :src="baseUrl.img+'/assets/img/home/popular/hero-animated-bee-cow.webp'"
                              alt=""
                         >
                         <img
                              id="coin-popular"
                              class="absolute -top-96 opacity-0 z-10"
                              :src="baseUrl.img+'/assets/img/home/popular/hero-animated-coin.webp'"
                              alt=""
                         >
                    </div>

                    <div class="flex justify-center items-center relative z-20">
                         <Carousel
                              :itemsToShow="1"
                              :wrapAround="true"
                              :mouseDrag="false"
                              :touchDrag="false"
                              snapAlign="center"
                              :transition="500"
                              v-model="currentSlideWeb"
                              class="popular-txt-carousel w-80"
                              :autoplay="2000"
                         >
                              <Slide
                                   v-for="(item, key) in pagination.data"
                                   :key="key"
                              >
                                   <p class="text-center text-secondary-5 font-roboto font-extrabold tracking-wide w-max text-sm sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-2xl mb-4">
                                        Top{{key + 1}}: {{ item.game_name }}
                                   </p>
                              </Slide>
                         </Carousel>
                    </div>
                    <Carousel
                         :itemsToShow="3"
                         :wrapAround="true"
                         snapAlign="center"
                         :transition="500"
                         v-model="currentSlideWeb"
                         class="popular-img-carousel relative z-20"
                         :autoplay="2000"
                    >
                         <Slide
                              v-for="(item, key) in pagination.data"
                              :key="key"
                         >
                              <div
                                   @click="() => goToDetail(item.game_name, item.id)"
                                   class="aspect-ratio-3-4 rounded-md p-1 mx-4 cursor-pointer"
                                   :class="item.game_pic == null ? 'bg-secondary-3' : ''"
                              >
                                   <img
                                        loading="lazy"
                                        v-if="item.game_pic == null"
                                        :src="baseUrl.img+'/assets/img/loader/popular-loader.png'"
                                        alt="popular"
                                        class="rounded-md absolute top-0 left-0 w-full h-full thumbnails border-4 border-solid border-secondary-3"
                                   >
                                   <img
                                        loading="lazy"
                                        v-else
                                        :src="toWebp(item.game_pic, 'popular')"
                                        :alt="item.game_name"
                                        class="rounded-md absolute top-0 left-0 w-full h-full thumbnails border-4 border-solid border-secondary-3"
                                   >
                                   <div
                                        loading="lazy"
                                        v-if="item.game_pic != null"
                                        class="rounded-md absolute top-0 left-0 w-full h-full thumbnails-blur bg-cover border-4 border-solid border-secondary-3"
                                        :style="'background-image:url('+toWebp(item.game_pic, 'popular')+')'"
                                   ></div>
                              </div>
                         </Slide>

                         <template #addons>
                              <Pagination class="!mt-6" />
                         </template>
                    </Carousel>
               </div>
               <div v-if="pagination.data" class="mt-10 w-full block md:hidden">
                    <div class="flex justify-center items-center">
                         <Carousel
                              :itemsToShow="1"
                              :wrapAround="true"
                              :mouseDrag="false"
                              :touchDrag="false"
                              snapAlign="center"
                              :transition="500"
                              v-model="currentSlideMobile"
                              class="popular-txt-carousel w-full"
                              :autoplay="2000"
                         >
                              <Slide
                                   v-for="(item, key) in pagination.data"
                                   :key="key"
                              >
                                   <p class="text-center text-secondary-5 font-roboto font-extrabold tracking-wide w-full text-sm xs:text-base sm:text-lg lg:text-xl xl:text-2xl 2xl:text-2xl mb-4">
                                        Top{{key + 1}}: {{ item.game_name }}
                                   </p>
                              </Slide>
                         </Carousel>
                    </div>
                    <div class="flex justify-center items-center w-full">
                         <Carousel
                              :itemsToShow="1.2"
                              :wrapAround="true"
                              snapAlign="center"
                              :transition="500"
                              v-model="currentSlideMobile"
                              class="popular-img-carousel relative w-full"
                              :autoplay="2000"
                         >
                              <Slide
                                   v-for="(item, key) in pagination.data"
                                   :key="key"
                              >
                                   <div
                                        @click="() => goToDetail(item.game_name, item.id)"
                                        class="aspect-ratio-3-4 rounded-md p-1 mx-2 cursor-pointer"
                                        :class="item.game_pic == null ? 'bg-secondary-3' : ''"
                                   >
                                        <img
                                             loading="lazy"
                                             width="329"
                                             height="311"
                                             v-if="item.game_pic_mobile == null"
                                             :src="baseUrl.img+'/assets/img/loader/popular-loader.png'"
                                             alt="popular"
                                             class="rounded-md absolute top-0 left-0 w-full h-full thumbnails border-4 border-solid border-secondary-3"
                                        >
                                        <img
                                             loading="lazy"
                                             width="329"
                                             height="311"
                                             v-else
                                             :src="toWebp(item.game_pic_mobile, 'popular')"
                                             :alt="item.game_name"
                                             class="rounded-md absolute top-0 left-0 w-full h-full thumbnails border-4 border-solid border-secondary-3"
                                        >
                                        <!-- <div
                                             v-if="item.game_pic_mobile != null"
                                             class="rounded-md absolute top-0 left-0 w-full h-full thumbnails-blur bg-cover border-4 border-solid border-secondary-3"
                                             :style="'background-image:url('+toWebp(item.game_pic_mobile, 'popular')+')'"
                                        >
                                        </div> -->
                                   </div>
                              </Slide>

                              <template #addons>
                                   <Pagination class="!mt-6" />
                              </template>
                         </Carousel>
                    </div>
               </div>
          </div>
          <div
               class="w-full max-w-full h-18 md:h-72 2xl:h-96 z-10 bg-primary-2 -mb-1"
               id="games-container"
               style="clip-path: polygon(100% 100%, 0% 100% , 0.00% 86.17%, 1.67% 85.03%, 3.33% 83.85%, 5.00% 82.61%, 6.67% 81.33%, 8.33% 80.00%, 10.00% 78.63%, 11.67% 77.22%, 13.33% 75.78%, 15.00% 74.31%, 16.67% 72.82%, 18.33% 71.31%, 20.00% 69.77%, 21.67% 68.23%, 23.33% 66.67%, 25.00% 65.12%, 26.67% 63.56%, 28.33% 62.00%, 30.00% 60.46%, 31.67% 58.93%, 33.33% 57.41%, 35.00% 55.92%, 36.67% 54.45%, 38.33% 53.01%, 40.00% 51.60%, 41.67% 50.23%, 43.33% 48.90%, 45.00% 47.62%, 46.67% 46.38%, 48.33% 45.20%, 50.00% 44.07%, 51.67% 42.99%, 53.33% 41.98%, 55.00% 41.03%, 56.67% 40.15%, 58.33% 39.34%, 60.00% 38.59%, 61.67% 37.92%, 63.33% 37.33%, 65.00% 36.81%, 66.67% 36.36%, 68.33% 36.00%, 70.00% 35.72%, 71.67% 35.51%, 73.33% 35.39%, 75.00% 35.35%, 76.67% 35.39%, 78.33% 35.51%, 80.00% 35.72%, 81.67% 36.00%, 83.33% 36.36%, 85.00% 36.81%, 86.67% 37.33%, 88.33% 37.92%, 90.00% 38.59%, 91.67% 39.34%, 93.33% 40.15%, 95.00% 41.03%, 96.67% 41.98%, 98.33% 42.99%, 100.00% 44.07%);"
          ></div>
     </div>
</template>

<script>
import { computed, onMounted, reactive, ref, watch } from "vue";
import { Carousel, Navigation, Pagination, Slide } from "vue3-carousel";
import { gamesStore } from "store/games";
import { authStore } from "store/auth.js";
import _ from "underscore";
import { gameLauncher as gameLauncherAPI } from "@/api/seamless";
import { useToaster } from "composables/use-toaster.js";
import { useFormatter } from "composables/use-formatter.js";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useRouter } from "vue-router";
import { useImage } from "composables/use-image.js";
import Star from "components/star/index.vue";
import { baseUrl } from "config/services.js";

gsap.registerPlugin(ScrollTrigger);

export default {
     components: {
          Carousel,
          Slide,
          Pagination,
          Navigation,
          Star
     },
     setup() {
          const { money } = useFormatter();
          const { toWebp } = useImage();

          const router = useRouter();

          const currentSlideWeb = ref(1);
          const currentSlideMobile = ref(0);

          const useGamesStore = gamesStore();
          const useAuthStore = authStore();

          const { successToast, errorToast } = useToaster();

          const gold_balance = computed(() => {
               return useAuthStore.gold_balance;
          });

          const silver_balance = computed(() => {
               return parseFloat(useAuthStore.silver_balance) + parseFloat(useAuthStore.silver_balance_redeem);
          });


          const popularData = computed(() => {
               if (useGamesStore.popular) {
                    pagination.data = useGamesStore.popular["data"];
                    pagination.totalPage = Math.ceil(
                         (useGamesStore.popular["total"] ?? 0) /
                              (useGamesStore.popular["per_page"] ?? 0)
                    );
               }
               return useGamesStore.popular || null;
          });

          const providerData = computed(() => {
               return useGamesStore.provider || null;
          });

          const isUser = computed(() => {
               return useAuthStore.user;
          });

          const pagination = reactive({
               data: [],
               page: 1,
               itemsPerPage: 4,
               totalPage: 0,
          });

          const onImageError = (e) => {
               let temp = errorImages.value;
               temp.push(parseInt(e.target.alt));

               const uniq = _.uniq(temp);

               errorImages.value = uniq;

               e.target.src = "assets/img/games/default.png";
          };

          const onLaunch = async (slug, type) => {
               if (isUser.value) {
                    const filter = _.filter(
                         providerData.value,
                         function (params) {
                              return params.id == slug.provider_id;
                         }
                    );

                    await gameLauncherAPI(
                         { game_slug: slug.game_slug, type: type },
                         filter[0]["game_source"]
                    )
                         .then((res) => {
                              if (res.status) {
                                   if (
                                        /iPad|iPhone|iPod/.test(
                                             navigator.userAgent
                                        )
                                   ) {
                                        // alert("This is an iOS device.");
                                        setTimeout(() => {
                                            //  window.open(res.url, "_blank");
                                            useAuthStore.setActiveCoinType(type);
                                            useAuthStore.setActiveGameUrl(res.url);
                                            router.push({ name: 'play', params: { slug: slug.game_slug, id: slug.provider_id, launchcode: res.launch_code, type: type } });
                                        });
                                   } else {
                                        // window.open(res.url, "_blank");
                                        useAuthStore.setActiveCoinType(type);
                                        useAuthStore.setActiveGameUrl(res.url);
                                        router.push({ name: 'play', params: { slug: slug.game_slug, id: slug.provider_id, launchcode: res.launch_code, type: type } });
                                   }
                              }
                         })
                         .catch((e) => {
                              errorToast("Something went wrong!", "top-right");
                         });
               } else {
                    router.push({ name: "login" });
               }
          };

          const goToDetail = (name, id) => {
               router.push({
                    path:
                         "/games/detail/" +
                         name.replaceAll(" ", "-") +
                         "-" +
                         id,
               });
          };

          const openLogin = () => {
               router.push({ name: "login" });
          };

          let mm = gsap.matchMedia();

          const cowBeeAnimation = () => {
               let tl = gsap.timeline({
                    scrollTrigger: {
                         trigger: "#popular-container",
                         start: "top center",
                         toggleActions: "restart none none reverse",
                         // markers: true,
                    },
               });

               tl.to("#bee-cow-popular", {
                    y: -170,
                    duration: 0.4,
                    ease: "sine.in",
               })
               .to("#bee-cow-popular", {
                    y: -180,
                    duration: 0.2,
                    ease: "power1.out",
               })
               .to("#bee-cow-popular", {
                    y: -170,
                    duration: 0.3,
                    ease: "power1.out",
               });

               mm.add("(min-width: 1024px)", () => {
                    tl.to("#bee-cow-popular", {
                         y: -200,
                         duration: 0.4,
                         ease: "sine.in",
                    })
                    .to("#bee-cow-popular", {
                         y: -210,
                         duration: 0.2,
                         ease: "power1.out",
                    })
                    .to("#bee-cow-popular", {
                         y: -200,
                         duration: 0.3,
                         ease: "power1.out",
                    });
               });


          };

          const coinAnimation = () => {
               let tl = gsap.timeline({
                    scrollTrigger: {
                         trigger: "#popular-container",
                         start: "top center",
                         toggleActions: "restart none none reverse",
                         // markers: true,
                    },
               });

               tl.to("#coin-popular", {
                    opacity: 1,
                    top: -160,
                    duration: 0.4,
                    ease: "sine.in",
               })
               .to("#coin-popular", {
                    top: -170,
                    duration: 0.2,
                    ease: "power1.out",
               })
               .to("#coin-popular", {
                    top: -160,
                    duration: 0.3,
                    ease: "power1.out",
               });

               mm.add("(min-width: 1024px)", () => {
                    tl.to("#coin-popular", {
                         opacity: 1,
                         top: -190,
                         duration: 0.4,
                         ease: "sine.in",
                    })
                    .to("#coin-popular", {
                         top: -200,
                         duration: 0.2,
                         ease: "power1.out",
                    })
                    .to("#coin-popular", {
                         top: -190,
                         duration: 0.3,
                         ease: "power1.out",
                    });
               });
          };

          onMounted(async () => {
               cowBeeAnimation();
               coinAnimation();
          });

          watch(popularData, (newVal) => {
               if (newVal) {
                    pagination.data = newVal["data"];
                    pagination.totalPage = Math.ceil(
                         (newVal["total"] ?? 0) / (newVal["per_page"] ?? 0)
                    );
               }
          });

          return {
               onImageError,
               onLaunch,
               pagination,
               isUser,
               gold_balance,
               silver_balance,
               openLogin,
               money,
               currentSlideWeb,
               currentSlideMobile,
               goToDetail,
               toWebp,
               baseUrl
          };
     },
};
</script>
