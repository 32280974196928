import { getTestimonials as getTestimonialsAPI, getFAQ as getFAQAPI } from '@/api/faq'
import { defineStore } from "pinia"

export const faqStore = defineStore('faq', {
    state: () => {
        return {
            testimonial: null,
            faq: null,
        }
    },
    actions: {
        getTestimonials() {
            if (this.testimonial != null) {
                return;
            }
            return new Promise((resolve, reject) => {
                getTestimonialsAPI()
                    .then(response => {
                        this.$patch((state) => {
                            state.testimonial = response;
                        })
                        resolve()
                    })
                    .catch(error => {
                        this.$patch((state) => {
                            if (error.response) {
                                state.error_msg = error.response.data;
                            }
                        });
                        // errorToast('Error to load data!', "top-right");
                        console.error('Error to load testimonials data!', error);
                        reject(error)
                    })
            })
        },
        getFaq() {
            if (this.faq != null) {
                return;
            }
            return new Promise((resolve, reject) => {
                getFAQAPI()
                    .then(response => {
                        this.$patch((state) => {
                            state.faq = response.data;
                        })
                        resolve()
                    })
                    .catch(error => {
                        this.$patch((state) => {
                            if (error.response) {
                                state.error_msg = error.response.data;
                            }
                        });
                        // errorToast('Error to load data!', "top-right");
                        console.error('Error to load faq data!', error);
                        reject(error)
                    })
            })
        },
    }
})
