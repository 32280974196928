/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import './bootstrap';
import '../css/app.css';
import "vue3-carousel/dist/carousel.css";
import 'vue-skeletor/dist/vue-skeletor.css';
import "vue-toastification/dist/index.css";
// import 'vue-tel-input/vue-tel-input.css';

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { createPinia } from 'pinia'

import AppRoute from "router";
import router from "./router";

import { HasError, AlertError } from "vform/src/components/tailwind";
import { Skeletor } from 'vue-skeletor';
import Toast from "vue-toastification";

import VueTelInput from 'vue-tel-input';
import VueLazyload from 'vue-lazyload';
import { baseUrl } from "config/services.js";

import * as Sentry from "@sentry/vue";
import { BrowserTracing } from '@sentry/tracing';
import { Inertia } from '@inertiajs/inertia';

createInertiaApp({
    title: (title) => `${title} - Moozi`,
    resolve: (name) => {
        return resolvePageComponent(`./app/${name}.vue`, import.meta.glob('./app/**/*.vue'))
    },
    setup({ el, App, props, plugin }) {

        const app = createApp({ render: () => h(App, props) });

        app.use(plugin)
            .use(AppRoute)
            .use(createPinia())
            .use(Toast)
            .use(VueTelInput)
            .use(VueLazyload, {
                preLoad: 1.3,
                error: baseUrl.img+'/assets/img/games/default.png',
                attempt: 1
            })
            .component(HasError.name, HasError)
            .component(AlertError.name, AlertError)
            .component(Skeletor.name, Skeletor)
        
        // if(import.meta.env.VITE_ENV != 'production') {
            app.use(router)
        // }

        // if (import.meta.env.VITE_ENV == 'production') {
            
            // Sentry.init({
            //     app: app,
            //     dsn: 'https://fc6ac39e26f1e9f97fcdb56296ceedd5@o4507759068053504.ingest.us.sentry.io/4507785293856768', // Replace with your actual DSN
            //     integrations: [
            //         // new BrowserTracing({
            //         //     tracingOrigins: ['localhost', /^\//],
            //         //     routingInstrumentation: Sentry.vueRouterInstrumentation(Inertia),
            //         // }),
            //         Sentry.browserTracingIntegration({ router }),
            //         Sentry.replayIntegration(),
            //         Sentry.browserProfilingIntegration(),
            //     ],

            //     tracesSampleRate: 1.0,

            //     // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
            //     tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

            //     // Capture Replay for 10% of all sessions,
            //     // plus for 100% of sessions with an error
            //     replaysSessionSampleRate: 0.1,
            //     replaysOnErrorSampleRate: 1.0,

            //     // Profiling
            //     profilesSampleRate: 1.0, // Profile 100% of the transactions. This value is relative to tracesSampleRate
            // });

        // } 

        app.mount(el);
    },
    progress: {
        color: '#4B5563',
    },
});
