

<template>
     <Head title="Home"></Head>
     <Welcome />
     <Popular />
     <Games />
     <Favorite v-if="isUser" page="home"/>
     <Recent v-if="isUser" page="home"/>
     <!-- <Providers/> -->
     <Benefits/>
     <Promotions
          :promoData="promoData"
     />
     <Terms/>
</template>
<script>
import { Head } from "@inertiajs/vue3";
import Welcome from "./components/welcome/index.vue";
import Benefits from "./components/benefits/index.vue";
import Providers from "./components/providers/index.vue";
import Games from "./components/games/index.vue";
import Popular from "./components/popular/index.vue";
import Favorite from "./components/favorite/index.vue";
import Recent from "./components/recent/index.vue";
import Promotions from "./components/promotions/index.vue";
import { verifyAccount } from "api/verify.js";
import { authStore } from "store/auth.js";
import { computed, onMounted, ref } from "vue";
import { useToaster } from "composables/use-toaster.js";
import { useRouter, useRoute } from "vue-router";
import { promoStore } from "store/promotion";
import { gamesStore } from "store/games";
import { useScroll } from "composables/use-scroll.js";

export default {
     components: {
          Head,
          Welcome,
          Benefits,
          Providers,
          Games,
          Popular,
          Promotions,
          Favorite,
          Recent
     },
     setup() {
          const router = useRouter();
          
          const { scrollTop } = useScroll();

          const useGamesStore = gamesStore();
          const usePromoStore = promoStore();
          const useAuthStore = authStore();

          const { successToast, errorToast } = useToaster();
          const promoData = computed(() => {
               return usePromoStore.promo || null;
          });

          onMounted(async () => {
               scrollTop();
               await usePromoStore.getPromotion({published_at: 'promotion'});
               await useGamesStore.getPopular({ page: 1, itemsPerPage: 4 });
               await useGamesStore.getAllProvider();

               if (
                    new URL(location.href).searchParams.get(
                         "account_activation"
                    )
               ) {
                    await verifyAccount(
                         new URL(location.href).searchParams.get(
                              "account_activation"
                         )
                    ).then(async (data) => {
                         if (data.status) {
                              await useAuthStore.getUser(true);
                              successToast(data.message, "top-right");
                              router.push({ path: "/" });
                         } else {
                              errorToast(data.message, "top-right");
                              router.push({ path: "/" });
                         }
                    });
               }
          });


          const isUser = computed(() => {
               return useAuthStore.user;
          });

          return {
               isUser,
               promoData,
               // viewportHeight,
               // clientHeight
          };
     },
};
</script>
