<template>
  <Head title="Reset Password"></Head>
  <div
    id="authentication-modal"
    tabindex="-1"
    aria-hidden="true"
    class="overflow-y-auto overflow-x-hidden fixed z-70 w-full inset-0 max-h-full transform ease-in-out transition-all duration-300 self-center w-full m-auto inset-0"
  >
    <div
      class="relative px-3 py-3 sm:py-4 sm:px-6 w-full max-w-md max-h-full tracking-wider m-auto"
    >
      <div class="relative bg-white rounded-lg shadow">
        <button
          @click="closeModal()"
          type="button"
          class="close-btn-c absolute end-2.5 rounded-lg text-sm ms-auto inline-flex justify-center items-center"
          data-modal-hide="authentication-modal"
          style="
            right: -50px;
            background: red;
            border-radius: 100px;
            color: #fff;
          "
        >
          <svg
            class="w-4 h-4"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
          <span class="sr-only">Close modal</span>
        </button>
        <!-- Modal header -->
        <div
          class="flex items-center justify-center divide-gray-70 divide-x py-6 mb-2"
        >
          <div class="px-2 sm:px-4">
            <img class="w-36" :src="baseUrl.img+'/assets/img/modal-logo.svg'" alt="" />
          </div>
          <div class="px-2 sm:px-4">
            <h3
              class="text-lg xs:text-2xl font-semibold text-gray-900 uppercase"
            >
              Reset Password
            </h3>
          </div>
        </div>
        <!-- Modal body -->
        <div class="px-4 xs:px-6 font-inter">
          <form @submit.prevent="submitUpdatePassword" class="space-y-3">
            <div>
              <label
                for="password"
                class="block mb-2 text-xs font-medium text-gray-900"
                >New Password</label
              >
              <div class="relative">
                <input
                  v-model="form.password"
                  :type="showPassword ? 'text' : 'password'"
                  name="password"
                  id="password"
                  placeholder="Enter your new password"
                  class="bg-white border border-gray-100 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 tracking-wider focus:bg-blue-10 px-10"
                  autocomplete="current-password"
                  @change="clearFormError('password')"
                  :class="form.errors?.errors?.password != null ? 'border-primal-red focus:ring-primal-red focus:border-primal-red focus:bg-red-20 bg-red-20' : 'border-gray-100 focus:ring-blue-500 focus:border-blue-500 focus:bg-blue-10'"
                />
                <div class="absolute" style="top: 12px; left: 8px">
                  <span
                    class="items-center leading-normal bg-grey-lighter rounded rounded-r-none whitespace-no-wrap"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-5 h-5"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
                      />
                    </svg>
                  </span>
                </div>
                <div
                  class="absolute cursor-pointer"
                  style="top: 12px; right: 8px"
                >
                  <span
                    class="items-center leading-normal bg-grey-lighter rounded rounded-r-none whitespace-no-wrap"
                    @click="toggleShowPassword()"
                  >
                    <svg
                      v-if="showPassword"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-5 h-5"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                      />
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                    </svg>
                    <svg
                      v-else
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="{1.5}"
                      stroke="currentColor"
                      class="w-5 h-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                      />
                    </svg>
                  </span>
                </div>
              </div>
              <has-error-form
                  v-model:form="form"
                  :field="'password'"
                ></has-error-form>
            </div>
            <div>
              <label
                for="password_confirmation"
                class="block mb-2 text-xs font-medium text-gray-900"
                >Confirm New Password</label
              >
              <div class="relative">
                <input
                  v-model="form.password_confirmation"
                  :type="showPasswordConfirm ? 'text' : 'password'"
                  name="password_confirmation"
                  id="password_confirmation"
                  placeholder="Enter your new password confirmation"
                  class="bg-white border border-gray-100 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 tracking-wider focus:bg-blue-10 px-10"
                  autocomplete="current-password"
                  @change="clearFormError('password_confirmation')"
                  :class="form.errors?.errors?.password_confirmation != null ? 'border-primal-red focus:ring-primal-red focus:border-primal-red focus:bg-red-20 bg-red-20' : 'border-gray-100 focus:ring-blue-500 focus:border-blue-500 focus:bg-blue-10'"
                />
                <div class="absolute" style="top: 12px; left: 8px">
                  <span
                    class="items-center leading-normal bg-grey-lighter rounded rounded-r-none whitespace-no-wrap"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-5 h-5"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
                      />
                    </svg>
                  </span>
                </div>
                <div
                  class="absolute cursor-pointer"
                  style="top: 12px; right: 8px"
                >
                  <span
                    class="items-center leading-normal bg-grey-lighter rounded rounded-r-none whitespace-no-wrap"
                    @click="toggleShowPasswordConfirm()"
                  >
                    <svg
                      v-if="showPasswordConfirm"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-5 h-5"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                      />
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                    </svg>
                    <svg
                      v-else
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="{1.5}"
                      stroke="currentColor"
                      class="w-5 h-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                      />
                    </svg>
                  </span>
                </div>
              </div>
              <has-error-form
                  v-model:form="form"
                  :field="'password_confirmation'"
                ></has-error-form>
            </div>

            <button
              type="submit"
              class="w-full text-white bg-primal-red hover:text-primal-yellow focus:ring-1 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-md px-5 py-2 text-center uppercase tracking-wider font-teko"
            >
              Submit
            </button>

            <div class="text-xs font-medium text-gray-80 text-center pt-2 pb-6">
              <span
                @click="backToHome()"
                class="text-primal-black hover:underline font-400 cursor-pointer"
                >Back to home</span
              >
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <loading-overlay :isLoading="loading" />
  <div class="bg-gray-60 fixed inset-0 z-40"></div>
</template>

<script>
import { onMounted, ref, reactive, computed, watch } from "vue";
import { authStore } from "store/auth";
import Form from "vform";
import LoadingOverlay from "components/loading/loadingOverlay.vue";
import { Head } from "@inertiajs/vue3";
import { useRouter, useRoute } from "vue-router";
import HasErrorForm from "components/haserror/index.vue";
import { useScroll } from "composables/use-scroll.js";
import { baseUrl } from "config/services.js";

export default {
  components: {
    LoadingOverlay,
    Head,
    HasErrorForm
  },
  setup() {
    const useAuthStore = authStore();
    const loading = ref(false);
    const showPassword = ref(false);
    const showPasswordConfirm = ref(false);
    const router = useRouter();
    const route = useRoute();
    const { scrollTop } = useScroll();

    const form = reactive(
      new Form({
        code: "",
        password: "",
        password_confirmation: "",
      })
    );

    const error_msg = computed(() => {
      return useAuthStore.error_msg;
    });

    const closeModal = () => {
      form.reset();
      router.push({ name: "home" });
    };

    const backToHome = () => {
      form.reset();
      router.push({ name: "home" });
    };

    const submitUpdatePassword = () => {
      loading.value = true;
      if (route.params.token) {
        form.code = route.params.token;
      }
      useAuthStore
        .updatePassword(form)
        .then(() => {
          form.reset();
          router.push({ name: "login" });
          loading.value = false;
        })
        .catch((e) => {
          loading.value = false;
        });
    };

    const clearFormError = (key) => {
      if (typeof form.errors.errors[key] !== "undefined") {
        delete form.errors.errors[key];
      }
    };

    const toggleShowPassword = () => {
      showPassword.value = !showPassword.value;
    };

    const toggleShowPasswordConfirm = () => {
      showPasswordConfirm.value = !showPasswordConfirm.value;
    };

    onMounted(async () => {
      scrollTop();
    });

    watch(error_msg, (newVal) => {
      if (newVal) {
        form.errors.errors = newVal;
      }
    });

    return {
      form,
      loading,
      showPassword,
      showPasswordConfirm,
      closeModal,
      backToHome,
      submitUpdatePassword,
      toggleShowPassword,
      toggleShowPasswordConfirm,
      clearFormError,
      baseUrl
    };
  },
};
</script>
