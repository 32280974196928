<template>
     <div class="" style="min-height: 400px;">
          <div 
               v-if="social_linked"
               class="space-y-4 mt-5 lg:mt-0 font-helveticaLight text-xs xs:text-sm sm:text-lg md:text-xl lg:text-lg xl:text-xl">
               <button
                    :disabled="social_linked?.includes('google')?true:false"
                    @click="setSocialLink('google', social_linked?.includes('google'))"
                    class="mb-4 w-full rounded-full text-black bg-white border-2 border-solid border-shade-6 focus:ring-1 focus:outline-none focus:ring-blue-300 font-medium text-xs pl-10 pr-8 py-3 lg:py-4  text-center tracking-wider flex items-center space-x-2 justify-between"
                    :class="
                                social_linked?.includes('google')
                                ? 'cursor-not-allowed'
                                : 'cursor-pointer'
                            "
                    :style="social_linked?.includes('google')
                              ? 'box-shadow: 0px 4px 5.7px -2px rgba(0, 0, 0, 0.15) inset;'
                              : 'box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15)'
                            "
               >
                    <div class="flex flex-row items-center gap-2">
                         <img
                              class="w-auto"
                              :src="baseUrl.img+'/assets/img/auth/google.svg'"
                              alt=""
                         />
                    </div>
                    <div>
                         <span
                              v-if="!social_linked?.includes('google')"
                              class="font-bold text-black"
                         >CONNECT</span>
                         <span
                              v-else
                              class="font-bold text-black opacity-50"
                         >CONNECTED</span>
                    </div>
               </button>
               <!-- <button
                    :disabled="social_linked?.includes('facebook')?true:false"
                    @click="setSocialLink('facebook', social_linked?.includes('facebook'))"
                    class="mb-4 w-full text-black bg-white border-2 border-solid border-shade-6 focus:ring-1 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-xs pl-10 pr-8 py-3 lg:py-4  text-center tracking-wider flex items-center space-x-2 justify-between"
                    :class="
                                social_linked?.includes('facebook')
                                ? 'cursor-not-allowed'
                                : 'cursor-pointer'
                            "
                    :style="social_linked?.includes('facebook')
                              ? 'box-shadow: 0px 4px 5.7px -2px rgba(0, 0, 0, 0.15) inset;'
                              : 'box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15)'
                            "
               >
                    <div class="flex flex-row items-center gap-2">
                         <img
                              class="w-auto"
                              :src="baseUrl.img+'/assets/img/auth/meta.svg'"
                              alt=""
                         />
                    </div>
                    <div>
                         <span
                              v-if="!social_linked?.includes('facebook')"
                              class="font-bold text-black"
                         >CONNECT</span>
                         <span
                              v-else
                              class="font-bold text-black opacity-50"
                         >CONNECTED</span>
                    </div>
               </button> -->
               <button
                    :disabled="social_linked?.includes('twitter')?true:false"
                    @click="setSocialLink('twitter', social_linked?.includes('twitter'))"
                    class="mb-4 w-full text-black bg-white border-2 border-solid border-shade-6 focus:ring-1 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-xs pl-10 pr-8 py-3 lg:py-4  text-center tracking-wider flex items-center space-x-2 justify-between"
                    :class="
                                social_linked?.includes('twitter')
                                ? 'cursor-not-allowed'
                                : 'cursor-pointer'
                            "
                    :style="social_linked?.includes('twitter')
                              ? 'box-shadow: 0px 4px 5.7px -2px rgba(0, 0, 0, 0.15) inset;'
                              : 'box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15)'
                            "
               >
                    <div class="flex flex-row items-center gap-2">
                         <img
                              class="w-auto"
                              :src="baseUrl.img+'/assets/img/auth/x.svg'"
                              alt=""
                         />
                    </div>
                    <div>
                         <span
                              v-if="!social_linked?.includes('twitter')"
                              class="font-bold text-black"
                         >CONNECT</span>
                         <span
                              v-else
                              class="font-bold text-black opacity-50"
                         >CONNECTED</span>
                    </div>
               </button>
          </div>
     </div>
</template>
<script>
import { onMounted } from "vue";
import { useRoute } from "vue-router";
import { useToaster } from "composables/use-toaster.js";
import { baseUrl } from "config/services.js";

export default {
     props: ["social_linked", "setSocialLink"],
     setup() {

          const route = useRoute();
          const { successToast } = useToaster();

          onMounted(() => {
               if (route.query.status == 'true') {
                    successToast("Successfully Connected", "top-right");
               }
          })

          return {
               baseUrl
          };
     },
};
</script>
