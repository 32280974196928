<template>
     <div v-if="enrolled != null && enrolled?.account?.length" class="w-full">
          <div v-if="walletRedeemCount == 0 && enrolled?.account[0].accountStatus == '40'" class="w-full">
               <div class="border border-shade-10 py-3 px-4 w-full text-center text-shade-10 text-lg rounded-lg mb-4 font-helveticaLight tracking-wide font-normal text-sm sm:text-lg md:text-xl lg:text-sm xl:text-base">
                    No bank connected. 
                    <span @click="goToWallet" class="text-background-3 cursor-pointer text-end w-full mb-2 underline underline-offset-2">
                         Please link new account.
                    </span>
               </div>
          </div>
          <div
               v-for="(item, key) in enrolled?.account"
               :key="key"
               class="mb-2 cursor-pointer w-full"
          >
               <div
                    v-if="item.accountStatus != '40'"
                    @click="onUpdateID(item.fdAccountID)"
                    class="fiserv-list-account w-full bg-shade-9 text-gray-900 border-2 border-solid border-shade-7 p-3 rounded-md transition-colors duration-300 wallet-list flex justify-start items-center gap-2"
               >
                    <input
                         v-model="form.accountId"
                         :id="item.fdAccountID"
                         :key="key"
                         type="radio"
                         name="existingCard"
                         :value="item.fdAccountID"
                         class="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300 cursor-pointer"
                    />
                    <div>

                         <p class="text-sm font-inter font-medium mb-0 flex flex-row gap-2">
                              <span class="font-normal">{{item.userIdentificationDetails.bankName}}</span>
                         </p>
                         <p class="text-sm font-inter font-medium mb-0 flex flex-row gap-2">
                              <span class="font-normal">{{item.userIdentificationDetails.accountNumber}}</span>
                         </p>
                    </div>
               </div>
          </div>
     </div>
     <div v-else-if="enrolled == null" class="h-32 w-full">
          <div v-if="loading" class="w-full h-full flex items-center justify-center">
               <svg aria-hidden="true" class="w-8 h-8 text-black animate-spin fill-secondary-1"
                    viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                         fill="currentColor"></path>
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                         fill="currentFill"></path>
               </svg>
          </div>
     </div>
     <div v-else class="w-full mt-3">
          <div class="border border-shade-10 py-3 px-4 w-full text-center text-shade-10 text-lg rounded-lg mb-4 font-helveticaLight tracking-wide font-normal text-sm sm:text-lg md:text-xl lg:text-sm xl:text-base">
               No bank connected. 
               <span @click="goToWallet" class="text-background-3 cursor-pointer text-end w-full mb-2 underline underline-offset-2">
                    Please link new account.
               </span>
          </div>
     </div>
</template>
<script>
import { computed, onMounted, ref } from 'vue';
import { connectPayStore } from "store/connectPay.js";
import { useRouter } from "vue-router";

export default {
     props: ['form', 'onUpdateID'],
     setup(props, { emit }) {
          const router = useRouter();

          const loading = ref(false)

          const useconnectPayStore = connectPayStore();

          const enrolled = computed(() => {
               return useconnectPayStore.walletRedeem;
          });

          const walletRedeemCount = computed(() => {
               return useconnectPayStore.walletRedeemCount;
          });

          const goToWallet = () => {
               router.push({ path: "/account/", query: { tab: 'payment-details', panel: 'wallet'}});
          };

          onMounted(async () => {
               loading.value = true
               await useconnectPayStore.getWalletRedeem();
               loading.value = false
          });

          return {
               enrolled,
               walletRedeemCount,
               goToWallet,
               loading
          };
     },
};
</script>
