<template>

     <Head title="Login"></Head>
     <div class="w-full h-full relative flex justify-center mt-20 lg:mt-0">
          <div class="z-0 w-full h-100-vh welcome-bg-container bg-background-2 fixed top-0 left-0"></div>
          <div class="w-full 2xl:max-w-screen-2xl bg-transparent relative">
               <div class="w-full h-full flex flex-col lg:flex-row justify-start md:justify-center items-center px-4 xs:px-4 sm:px-8 md:px-8 lg:px-12 xl:px-24 pt-14 pb-8 xs:pb-12 sm:pb-18 md:pb-24 lg:pb-28 xl:pb-32 gap-4 relative">
                    <div class="hidden lg:flex h-full flex-1 flex-col justify-end items-center relative z-10">
                         <h1 class="text-white font-helvetica tracking-wide text-center text-6xl xs:text-6xl sm:text-7xl md:text-7xl xl:text-8xl leading-5xl xs:leading-5xl sm:leading-6xl md:leading-6xl xl:leading-7.5xl italic relative z-10">
                              JOIN US NOW!
                         </h1>
                         <h2 class="text-white font-helvetica tracking-wide text-center text-3xl xs:text-3xl sm:text-4xl md:text-4xl xl:text-5xl leading-5xl xs:leading-5xl sm:leading-6xl md:leading-6xl xl:leading-7.5xl relative z-10">
                              TO OBTAIN <span class="text-secondary-3">50,000</span> GOLD COINS
                         </h2>
                         <div class="w-full flex justify-center items-center absolute top-0 h-full xl:h-0">
                              <img
                                   id="dash-login"
                                   class="absolute w-auto"
                                   :src="baseUrl.img+'/assets/img/auth/hero-animated-dash.png'"
                                   alt=""
                              >
                              <img
                                   id="coin-login"
                                   class="absolute w-auto z-0"
                                   :src="baseUrl.img+'/assets/img/auth/hero-animated-coin.png'"
                                   alt=""
                              >
                              <img
                                   id="cow-login"
                                   class="absolute w-auto z-0"
                                   :src="baseUrl.img+'/assets/img/auth/hero-animated-cow.png'"
                                   alt=""
                              >

                         </div>
                    </div>
                    <div class="flex-initial justify-center items-center max-w-md w-full">
                         <div class="relative tracking-tight m-auto">
                              <div class="relative bg-gradient-to-t to-55% from-background-2 from-0% to-background-3 rounded-lg shadow-xl">
                                   <div class="text-right pt-5 pb-2 mb-2">
                                        <div class="px-4 xs:px-6">
                                             <h3 class="text-xl xs:text-3xl font-semibold uppercase text-shade-1">
                                                  LOG IN
                                             </h3>
                                        </div>
                                   </div>
                                   <div class="px-4 xs:px-6 font-inter">
                                        <form
                                             @submit.prevent="submitLogin"
                                             class="space-y-3"
                                        >
                                             <div>
                                                  <label
                                                       for="email"
                                                       class="block mb-2 text-base font-medium text-background-4"
                                                  >Email Address
                                                  </label>
                                                  <div class="relative">
                                                       <input
                                                            v-model="form.email"
                                                            type="email"
                                                            name="email"
                                                            id="email"
                                                            class="bg-white border border-background-3 text-shade-2 text-base rounded-md focus:ring-background-2 focus:border-background-2 block w-full p-2.5"
                                                            placeholder="Enter your email"
                                                            autocomplete="username"
                                                            @change="clearFormError('email')"
                                                            :class="
                          form.errors?.errors?.email != null
                            ? 'border-primal-red focus:ring-primal-red focus:border-primal-red'
                            : 'border-background-3 focus:ring-background-2 focus:border-background-2'
                        "
                                                       />
                                                  </div>
                                                  <has-error-form
                                                       v-model:form="form"
                                                       :field="'email'"
                                                  ></has-error-form>
                                             </div>
                                             <div>
                                                  <label
                                                       for="password"
                                                       class="block mb-2 text-base font-medium text-background-4"
                                                  >Password</label>
                                                  <div class="relative">
                                                       <input
                                                            v-model="form.password"
                                                            :type="showPassword ? 'text' : 'password'"
                                                            name="password"
                                                            id="password"
                                                            placeholder="Enter your password"
                                                            class="bg-white border border-background-3 text-shade-2 text-base rounded-md focus:ring-background-2 focus:border-background-2 block w-full p-2.5 pr-10"
                                                            autocomplete="current-password"
                                                            @change="clearFormError('password')"
                                                            :class="
                          form.errors?.errors?.password != null
                            ? 'border-primal-red focus:ring-primal-red focus:border-primal-red'
                            : 'border-background-3 focus:ring-background-2 focus:border-background-2'
                        "
                                                       />
                                                       <div
                                                            class="absolute cursor-pointer"
                                                            style="top: 12px; right: 8px"
                                                       >
                                                            <span
                                                                 class="items-center leading-normal bg-grey-lighter rounded rounded-r-none whitespace-no-wrap"
                                                                 @click="toggleShowPassword()"
                                                            >
                                                                 <span
                                                                      v-if="showPassword"
                                                                      class="icon-eye text-xl text-shade-8"
                                                                 ><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
                                                                 <span
                                                                      v-else
                                                                      class="icon-eye-close text-2xl text-shade-8"
                                                                 ></span>
                                                            </span>
                                                       </div>
                                                  </div>
                                                  <has-error-form
                                                       v-model:form="form"
                                                       :field="'password'"
                                                  ></has-error-form>
                                             </div>
                                             <div v-if="login_attempts >= 3">
                                                  <label
                                                       for="captcha"
                                                       class="block mb-2 text-base font-medium text-background-4"
                                                  >Captcha</label>
                                                  <div class="flex items-center">
                                                       <div class="flex-1">
                                                            <input
                                                                 class="bg-white border border-background-3 text-shade-2 text-base rounded-md focus:ring-background-2 focus:border-background-2 block w-full p-2.5"
                                                                 type="tel"
                                                                 name="captcha"
                                                                 placeholder="Enter captcha"
                                                                 v-model="form.captcha"
                                                                 step="1"
                                                                 min="0"
                                                                 @keydown="filterKey"
                                                                 @input="captchaRestriction"
                                                                 @change="clearFormError('captcha')"
                                                                 :class="
                            form.errors?.errors?.captcha != null
                              ? 'border-primal-red focus:ring-primal-red focus:border-primal-red'
                              : 'border-background-3 focus:ring-background-2 focus:border-background-2'
                          "
                                                            />
                                                       </div>
                                                       <div class="flex-1">
                                                            <img
                                                                 :src="captcha?.img"
                                                                 @click="resetCaptcha()"
                                                                 class="w-full cursor-pointer h-auto"
                                                            />
                                                       </div>
                                                  </div>
                                                  <has-error-form
                                                       v-model:form="form"
                                                       :field="'captcha'"
                                                  ></has-error-form>
                                             </div>

                                             <div class="flex justify-between">
                                                  <div class="flex items-center">
                                                       <div class="flex items-center h-5">
                                                            <input
                                                                 id="remember"
                                                                 type="checkbox"
                                                                 value=""
                                                                 class="w-4 h-4 border border-shade-7 rounded bg-shade-1 focus:ring-1 focus:ring-shade-7 text-background-3"
                                                            />
                                                       </div>
                                                       <label
                                                            for="remember"
                                                            class="ms-2 text-base font-medium text-background-4"
                                                       >Remember me</label>
                                                  </div>
                                                  <span
                                                       @click="openForgot"
                                                       class="text-base text-secondary-1 underline cursor-pointer"
                                                  >Forgot Password ?</span>
                                             </div>
                                             <button
                                                  type="submit"
                                                  class="w-full text-primary-1 bg-secondary-3 px-4 py-3 xl:py-3.5 text-center leading-tight shadow-xl rounded-full font-roboto font-bold text-sm sm:text-base md:text-lg lg:text-base xl:text-lg"
                                             >
                                                  Log In
                                             </button>
                                             <div class="flex items-center px-4 lg:px-6 text-center text-background-3 text-base font-bold w-full">
                                                  <span class="flex-1 h-px bg-background-3"></span>
                                                  <span class="flex-initial px-4">Or</span>
                                                  <span class="flex-1 h-px bg-background-3"></span>
                                             </div>
                                             <button
                                                  @click.prevent="googleLogin()"
                                                  class="flex items-center w-full text-primary-1 bg-shade-1 px-6 sm:px-10 py-3 xl:py-3.5 text-center leading-tight shadow-xl rounded-full font-bold border border-shade-6"
                                             >
                                                  <img
                                                       class="w-auto flex-initial"
                                                       :src="baseUrl.img+'/assets/img/auth/google.svg'"
                                                       alt=""
                                                  />
                                                  <span class="flex-1 text-sm sm:text-base md:text-lg lg:text-base">Sign In With Google</span>
                                             </button>
                                             <button
                                                  @click.prevent="twitterLogin()"
                                                  class="flex items-center w-full text-primary-1 bg-shade-1 px-6 sm:px-10 py-3 xl:py-3.5 text-center leading-tight shadow-xl rounded-full font-bold border border-shade-6"
                                             >
                                                  <img
                                                       class="w-auto flex-initial"
                                                       :src="baseUrl.img+'/assets/img/auth/x.svg'"
                                                       alt=""
                                                  />
                                                  <span class="flex-1 text-sm sm:text-base md:text-lg lg:text-base">Sign In With X</span>
                                             </button>

                                             <div class="text-base font-medium text-background-3 text-center pt-2 pb-8">
                                                  Don't have an account?
                                                  <span
                                                       @click.prevent="openRegister()"
                                                       class="text-base text-secondary-1 underline cursor-pointer"
                                                  >Sign Up</span>
                                             </div>
                                        </form>
                                   </div>
                              </div>
                         </div>
                    </div>
                    <loading-overlay :isLoading="loading" />
               </div>
          </div>
     </div>
</template>

  <script>
import { Head } from "@inertiajs/vue3";
import { onMounted, ref, reactive, computed, watch } from "vue";
import { authStore } from "store/auth";
import Form from "vform";
import LoadingOverlay from "components/loading/loadingOverlay.vue";
import { useRouter, useRoute } from "vue-router";
import { utilitiesStore } from "store/utilities.js";
import HasErrorForm from "components/haserror/index.vue";
import { gsap } from "gsap";
import { useToaster } from "composables/use-toaster.js";
import device from 'middleware/device.js';
import Cookies from "js-cookie";
import { useScroll } from "composables/use-scroll.js";
import {
     addReferralStats as addReferralStatsAPI,
} from "api/bonus.js";
import { baseUrl } from "config/services.js";

export default {
     components: {
          LoadingOverlay,
          HasErrorForm,
          Head,
     },
     setup() {
          const useAuthStore = authStore();
          const showPassword = ref(false);
          const loading = ref(false);
          const router = useRouter();
          const route = useRoute();
          const { scrollTop } = useScroll();

          const useUtilitiesStore = utilitiesStore();

          const { infoToast } = useToaster();
          const { deviceType } = device();

          const form = reactive(
               new Form({
                    email: "",
                    password: "",
                    captcha: "",
                    key: "",
               })
          );

          const preloading = computed(() => {
               return useUtilitiesStore.loading;
          });

          const captcha = computed(() => {
               return useAuthStore.captcha;
          });

          const error_msg = computed(() => {
               return useAuthStore.error_msg;
          });

          const login_attempts = computed(() => {
               return useAuthStore.login_attempts;
          });

          const openRegister = () => {
               form.reset();
               router.push({ name: "register" });
          };

          const openForgot = () => {
               form.reset();
               router.push({ name: "forgot" });
          };

          const submitLogin = async () => {

               form.key = captcha.value?.key;
               loading.value = true;
               useAuthStore
                    .login(form)
                    .then(() => {
                         form.reset();

                         const triggerTTD = document.getElementById('login-ttd');
                         if(triggerTTD) {
                              triggerTTD.setAttribute("src", 'https://insight.adsrvr.org/track/pxl/?adv=nqpc8db&ct=0:yy9s89o&fmt=3');
                         }

                         // loading.value = false;

                         // setTimeout(() => {
                              useAuthStore.getUser().then(() => {
                                   // router.push({ name: "home"});
                                   window.location.href = "/"
                                   Cookies.set("isLoggedOn", true,)
                              });
                         // }, 2000);
                    })
                    .catch((e) => {
                         loading.value = false;
                         form.captcha = "";
                         if (login_attempts.value >= 3) {
                              useAuthStore.getCaptcha();
                         } else {
                              if (e.response.data.attempts) {
                                   useAuthStore
                                        .getLoginAttempts()
                                        .then((res) => {
                                             if (res.attempt >= 3) {
                                                  useAuthStore.getCaptcha();
                                             }
                                        });
                              }
                         }
                    });
          };

          const resetCaptcha = () => {
               useAuthStore.getCaptcha();
          };

          const toggleShowPassword = () => {
               showPassword.value = !showPassword.value;
          };

          const googleLogin = () => {
               window.location.href = "/auth/google?type"+(deviceType == 'mobile'? 'app' : 'web');
          };

          const facebookLogin = () => {
               infoToast("Meta Auth is under maintainance", "top-right");
               // window.location.href = "/auth/facebook?type"+(deviceType == 'mobile'? 'app' : 'web');
          };

          const twitterLogin = () => {
               window.location.href = "/auth/twitter?type"+(deviceType == 'mobile'? 'app' : 'web');;
          };

          const filterKey = (e) => {
               const key = e.key;
               if (key === ".") return e.preventDefault();

               if (key === "e") return e.preventDefault();
          };

          const captchaRestriction = (e) => {
               if (String(e.target.value).length <= 4) {
                    e.target.value = e.target.value;
               } else {
                    const turnString = form.captcha.toString();
                    const turnSlice = turnString.slice(0, 4);
                    form.captcha = turnSlice;
               }
          };
          const checkLoginAttmepts = async () => {
               await useAuthStore.getLoginAttempts().then((res) => {
                    if (res.attempt >= 3) {
                         useAuthStore.getCaptcha();
                    }
               });
          };

          const clearFormError = (key) => {
               if (typeof form.errors.errors[key] !== "undefined") {
                    delete form.errors.errors[key];
               }
          };

          const cowAnimation = () => {
               gsap.timeline()
                    .from("#cow-login", {
                         opacity: 0,
                         xPercent: 50,
                         yPercent: -30,
                         scale: 0.5,
                         duration: 0.3,
                         ease: "sine.in",
                    })
                    .to("#cow-login", {
                         x: -10,
                         y: 10,
                         scale: 1,
                         opacity: 0.93,
                         duration: 0.2,
                         ease: "power1.out",
                    })
                    .to("#cow-login", {
                         x: 5,
                         y: -5,
                         scale: 0.96,
                         duration: 0.2,
                         ease: "power1.out",
                    })
                    .to("#cow-login", {
                         x: 0,
                         y: 0,
                         scale: 1,
                         opacity: 1,
                         duration: 0.3,
                         ease: "power1.out",
                    });
          };

          const dashAnimation = () => {
               gsap.timeline()
                    .from("#dash-login", {
                         opacity: 0,
                         duration: 0.3,
                         ease: "sine.in",
                    })
                    .to("#dash-login", {
                         opacity: 1,
                         duration: 0.2,
                         ease: "power1.out",
                    });
          };

          const coinAnimation = () => {
               gsap.timeline()
                    .from("#coin-login", {
                         opacity: 0,
                         duration: 0.3,
                         ease: "sine.in",
                    })
                    .to("#coin-login", {
                         opacity: 1,
                         duration: 0.2,
                         ease: "power1.out",
                    });
          };

          onMounted(async () => {
               scrollTop();
               checkLoginAttmepts();
               if(!Cookies.get('ref')) {
                    await addReferralStatsAPI({referral_code: route.query.ref});
                    if (route.query.ref) {
                         Cookies.set('ref', route.query.ref, { expires: 7 });
                    }
               }

               if (!preloading.value) {
                    cowAnimation();
                    dashAnimation();
                    coinAnimation();
               }
          });

          watch(preloading, (newVal) => {
               if (!newVal) {
                    cowAnimation();
                    dashAnimation();
                    coinAnimation();
               }
          });

          watch(error_msg, (newVal) => {
               if (newVal) {
                    form.errors.errors = newVal;
               }
          });

          return {
               captcha,
               form,
               showPassword,
               loading,
               openRegister,
               openForgot,
               submitLogin,
               resetCaptcha,
               filterKey,
               captchaRestriction,
               toggleShowPassword,
               googleLogin,
               facebookLogin,
               twitterLogin,
               login_attempts,
               clearFormError,
               baseUrl,
          };
     },
};
</script>
