import request from '@/utils/request';
import requestUpload from '@/utils/requestUpload';

export function createTicket(data) {
  return request({
    url: '/contactus/send_ticket',
    method: 'post',
    data: data,
  });
}

export function uploadFilesTicket(data) {
  return requestUpload({
    url: '/fileupload',
    method: 'post',
    data: data,
  });
}

export function getCity(data) {
  return request({
    url: '/utils_city',
    method: 'get',
    params: data
  });
}

export function getState() {
  return request({
    url: '/utils_province',
    method: 'get',
  });
}


export function getZipCode(data) {
  return request({
    url: '/utils_zipcode',
    method: 'get',
    params: data
  });
}

export function getCoins(data) {
  return request({
    url: '/utils_coin_conversion',
    method: 'get',
    params: data
  });
}
