import {
    login,
    register,
    forgotPassword,
    updatePassword,
    getCaptcha,
    getUser,
    emailVerification,
    emailVerificationResend,
    profileUpdate,
    uploadIdKyc,
    getLoginAttempts,
    getUniqueCode,
    reloadBalance,
    getSocialLinked,
    logout as logoutAPI,
    changePassword as changePasswordAPI,
    getReferralCode as getReferralCodeAPI
} from 'api/auth.js'
import Cookies from 'js-cookie'
import {
    isLogged,
    setToken,
    removeToken,
    setUsername,
    removeUsername
} from 'utils/auth.js'
import { useRouter, useRoute } from 'vue-router'
import { defineStore } from 'pinia'
// import {permissionStore} from "@/store/permission";
import { useToaster } from 'composables/use-toaster.js'
import Form from 'vform'
import _ from 'underscore'
import { useFormatter } from 'composables/use-formatter.js'
// import { useBalanceSocketInit } from "composables/use-balance-socket-init.js";

const { successToast, errorToast } = useToaster()
const { money } = useFormatter()
const router = useRouter()
// const route = useRoute();

export const authStore = defineStore('user', {
    state: () => {
        return {
            user: null,
            token: Cookies.get('token'),
            error_msg: {},
            captcha: null,
            unique_code: null,
            profile_form: new Form({
                email: '',
                first_name: '',
                last_name: '',
                phone: '',
                phone_area: '',
                address: {
                    address_1: '',
                    address_2: '',
                    city_id: '',
                    province_id: '',
                    zip_code: ''
                },
                birthday: ''
            }),
            set_phone_form: '',
            set_phone_area: '',
            selected_country: null,
            gold_balance: 0,
            silver_balance: 0,
            silver_balance_redeem: 0,
            login_attempts: 0,
            social_linked: null,
            username: Cookies.get('username'),
            referral_code: null,
            kycStatus: null,
            game_token: null,
            active_coin_type: null,
            active_game_url: null
        }
    },
    actions: {
        // user login
        login(params) {
            // if(params.email){
            //   params.email = params.email.trim();
            // }
            return new Promise((resolve, reject) => {
                login(params)
                    .then((response) => {
                        if (response.access_token) {
                            setToken(response.access_token, response.expires_in)
                            setUsername(
                                response.user.username,
                                response.expires_in
                            )
                            this.$patch((state) => {
                                state.token = response.access_token
                                state.username = response.user.username
                            })
                            // setToken(response.access_token);
                        }
                        // duRegisterServiceWorker();
                        // router.replace({});

                        if(params['auth_key']) {
                            if(response.new){
                                const triggerTTD = document.getElementById('register-ttd');
                                if(triggerTTD) {

                                    if(response.social == 'google') {
                                        successToast('Successfully Signed up with Google!', 'top-right', "register-success")
                                        triggerTTD.setAttribute("src", 'https://insight.adsrvr.org/track/pxl/?adv=nqpc8db&ct=0:agntqy4&fmt=3');
                                    } else if (response.social == 'x') {
                                        successToast('Successfully Signed up with X!', 'top-right', "register-success")
                                        triggerTTD.setAttribute("src", 'https://insight.adsrvr.org/track/pxl/?adv=nqpc8db&ct=0:2evvqnc&fmt=3');
                                    }
                                }
                            } else {
                                successToast('Successfully Login!', 'top-right')
                            }
                        } else {
                            successToast('Successfully Login!', 'top-right')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        this.$patch((state) => {
                            if (error.response) {
                                state.error_msg = error.response.data.errors
                            }
                        })
                        if (error.response.data.status) {
                            errorToast(error.response.data.status, 'top-right')
                        }
                        reject(error)
                    })
            })
        },

        register(form) {
            return new Promise((resolve, reject) => {
                register(form)
                    .then((response) => {
                        // if(response.access_token){
                        //   setToken(response.access_token);
                        // }
                        successToast('Registration Successful!', 'top-right', "register-success")
                        resolve()
                    })
                    .catch((error) => {
                        this.$patch((state) => {
                            if (error.response) {
                                state.error_msg = error.response.data.errors
                                errorToast(
                                    error.response.data.message,
                                    'top-right'
                                )
                            }
                        })
                        reject(error)
                    })
            })
        },

        forgotPassword(form) {
            return new Promise((resolve, reject) => {
                forgotPassword(form)
                    .then((response) => {
                        if (response.status) {
                            successToast(response.message, 'top-right')
                        } else {
                            errorToast(response.message, 'top-right')
                        }
                        resolve()
                    })
                    .catch((error) => {
                        this.$patch((state) => {
                            if (error.response) {
                                state.error_msg = error.response.data.errors
                                errorToast(
                                    error.response.data.message,
                                    'top-right'
                                )
                            }
                        })
                        reject(error)
                    })
            })
        },

        updatePassword(form) {
            return new Promise((resolve, reject) => {
                updatePassword(form)
                    .then((response) => {
                        if (response.status) {
                            successToast(response.message, 'top-right')
                        } else {
                            errorToast(response.message, 'top-right')
                        }
                        resolve()
                    })
                    .catch((error) => {
                        this.$patch((state) => {
                            if (error.response) {
                                state.error_msg = error.response.data.errors
                                errorToast(
                                    error.response.data.message,
                                    'top-right'
                                )
                            }
                        })
                        reject(error)
                    })
            })
        },

        getCaptcha() {
            return new Promise((resolve, reject) => {
                getCaptcha()
                    .then((response) => {
                        if (!response) {
                            reject('Failed to load captcha!')
                        }

                        this.$patch((state) => {
                            state.captcha = response.url
                        })
                        resolve()
                    })
                    .catch((error) => {
                        this.$patch((state) => {
                            if (error.response) {
                                state.error_msg = error.response.data
                            }
                        })
                        errorToast('Error to load captcha!', 'top-right')
                        reject(error)
                    })
            })
        },

        getUser(force = false) {
            if (this.token == null) {
                return
            }
            if (!force) {
                if (this.user != null) {
                    return
                }
            }
            return new Promise((resolve, reject) => {
                getUser()
                    .then((response) => {
                        const { data } = response

                        if (!data) {
                            reject('Verification failed, please Login again.')
                        }
                        this.$patch((state) => {
                            state.profile_form.email = data.email
                                ? data.email
                                : ''
                            state.profile_form.first_name = data.first_name
                                ? data.first_name
                                : ''
                            state.profile_form.last_name = data.last_name
                                ? data.last_name
                                : ''
                            state.profile_form.phone = data.phone
                                ? data.phone
                                : ''
                            state.profile_form.address = {
                                address_1: data.address
                                    ? JSON.parse(data.address).address_1
                                    : '',
                                address_2: data.address
                                    ? JSON.parse(data.address).address_2
                                    : '',
                                city_id: data.address
                                    ? JSON.parse(data.address).city_id
                                    : '',
                                province_id: data.address
                                    ? JSON.parse(data.address).province_id
                                    : '',
                                zip_code: data.address
                                    ? JSON.parse(data.address).zip_code
                                    : ''
                            }
                            state.profile_form.birthday = data.birthday
                                ? data.birthday
                                : ''
                            state.set_phone_form = data.phone_area
                                ? data.phone_area.val
                                : ''
                            state.profile_form.phone_area = data.phone_area
                                ? data.phone_area.country
                                : 'US'
                            state.set_phone_area = data.phone_area
                                ? data.phone_area.country
                                : 'US'
                            state.user = data
                            state.username = data.username

                            console.log(state.set_phone_area)

                            // KYC CHECKER
                            this.checkStatusOfKYC(data)
                        })

                        resolve(data)
                    })
                    .catch((error) => {
                        this.user = null
                        this.token = null
                        this.kycStatus = null
                        removeToken()
                        removeUsername()
                        reject(error)
                    })
            })
        },

        forceGetUser() {
            return new Promise((resolve, reject) => {
                getUser()
                    .then((response) => {
                        const { data } = response

                        if (!data) {
                            reject('Verification failed, please Login again.')
                        }
                        this.$patch((state) => {
                            state.profile_form.email = data.email
                                ? data.email
                                : ''
                            state.profile_form.first_name = data.first_name
                                ? data.first_name
                                : ''
                            state.profile_form.last_name = data.last_name
                                ? data.last_name
                                : ''
                            state.profile_form.phone = data.phone
                                ? data.phone
                                : ''
                            state.profile_form.address = {
                                address_1: data.address
                                    ? JSON.parse(data.address).address_1
                                    : '',
                                address_2: data.address
                                    ? JSON.parse(data.address).address_2
                                    : '',
                                city_id: data.address
                                    ? JSON.parse(data.address).city_id
                                    : '',
                                province_id: data.address
                                    ? JSON.parse(data.address).province_id
                                    : '',
                                zip_code: data.address
                                    ? JSON.parse(data.address).zip_code
                                    : ''
                            }
                            state.profile_form.birthday = data.birthday
                                ? data.birthday
                                : ''
                            state.set_phone_form = data.phone_area
                                ? data.phone_area.val
                                : ''
                            state.profile_form.phone_area = data.phone_area
                                ? data.phone_area.country
                                : 'US'
                            state.set_phone_area = data.phone_area
                                ? data.phone_area.country
                                : 'US'
                            state.user = data

                            // KYC CHECKER
                            this.checkStatusOfKYC(data)
                            
                        })
                        resolve(data)
                    })
                    .catch((error) => {
                        this.user = null
                        this.token = null
                        this.kycStatus = null
                        removeToken()
                        removeUsername()
                        reject(error)
                    })
            })
        },

        updateProfileFormValue(field, value) {
            this.$patch((state) => {
                if (field == 'errors') {
                    state.profile_form.errors.errors = value
                } else {
                    state.profile_form[field] = value
                }
            })
        },

        updatePhoneFormValue(value) {
            this.$patch((state) => {
                state.set_phone_form = value
            })
        },

        updatePhoneAreaValue(value) {
            this.$patch((state) => {
                state.set_phone_area = value
            })
        },

        updateSelectedCountry(value) {
            this.$patch((state) => {
                state.selected_country = value
            })
        },

        updateAddressCity(value) {
            this.$patch((state) => {
                state.profile_form.address.city_id = value
            })
        },

        updateAddressZipcode(value) {
            this.$patch((state) => {
                state.profile_form.address.zip_code = value
            })
        },

        clearCurrentFormError(key) {
            this.$patch((state) => {
                if (typeof state.error_msg[key] !== 'undefined') {
                    delete state.error_msg[key]
                }
            })
        },

        getUniqueCode(data) {
            return new Promise((resolve, reject) => {
                getUniqueCode(data)
                    .then((response) => {
                        if (!response) {
                            reject('Failed to load unique code!')
                        }

                        this.$patch((state) => {
                            state.unique_code = response
                        })
                        resolve()
                    })
                    .catch((error) => {
                        this.$patch((state) => {
                            if (error.response) {
                                state.error_msg = error.response.data
                            }
                        })
                        errorToast('Error to load unique code!', 'top-right')
                        reject(error)
                    })
            })
        },

        // user logout
        logout() {
            return new Promise((resolve, reject) => {
                logoutAPI()
                    .then((response) => {
                        this.$patch((state) => {
                            state.token = null
                            state.user = null
                            successToast('Successfully Logout.', 'top-right')
                        })
                        removeToken()
                        removeUsername()
                        resolve()
                    })
                    .catch((error) => {
                        console.error('Error to logout!')
                        reject(error)
                    })
            })
        },

        // emailVerification(data) {
        //   return new Promise((resolve, reject) => {
        //     emailVerification(data)
        //       .then(response => {
        //         this.$patch((state) => {
        //             if(!response.status){
        //               const error = {
        //                 email: ["Your email is not yet verified."]
        //               };
        //               state.error_msg = error;
        //             }
        //         });
        //         resolve(response)
        //       })
        //       .catch(error => {
        //         reject(error)
        //       })
        //   })
        // },

        emailVerificationResend(form) {
            return new Promise((resolve, reject) => {
                emailVerificationResend(form)
                    .then((response) => {
                        if (response.status) {
                            successToast(response.message, 'top-right')
                        } else {
                            errorToast(response.message, 'top-right')
                        }
                        resolve()
                    })
                    .catch((error) => {
                        this.$patch((state) => {
                            if (error.response) {
                                state.error_msg = error.response.data.errors
                                errorToast(
                                    error.response.data.message,
                                    'top-right'
                                )
                            }
                        })
                        reject(error)
                    })
            })
        },

        profileUpdate(form) {
            return new Promise((resolve, reject) => {
                profileUpdate(form)
                    .then((response) => {
                        if (response.status) {
                            this.$patch((state) => {
                                state.error_msg = {}
                                successToast(response.message, 'top-right')
                            })
                        } else {
                            errorToast(response.message, 'top-right')
                        }
                        resolve()
                    })
                    .catch((error) => {
                        this.$patch((state) => {
                            if (error.response) {
                                state.error_msg = error.response.data.errors
                                errorToast(
                                    error.response.data.message,
                                    'top-right'
                                )
                            }
                        })
                        reject(error)
                    })
            })
        },
        uploadIdKyc(form) {
            return new Promise((resolve, reject) => {
                uploadIdKyc(form)
                    .then((response) => {
                        // if(response.access_token){
                        //   setToken(response.access_token);
                        // }
                        successToast('ID added!', 'top-right')
                        resolve()
                    })
                    .catch((error) => {
                        this.$patch((state) => {
                            if (error.response) {
                                state.error_msg = error.response.data.errors
                                errorToast(
                                    error.response.data.message,
                                    'top-right'
                                )
                            }
                        })
                        reject(error)
                    })
            })
        },

        clearErrors() {
            this.$patch((state) => {
                state.error_msg = {}
            })
        },

        clearErrorsProfile() {
            this.$patch((state) => {
                state.profile_form.first_name = ''
                state.profile_form.last_name = ''
                state.profile_form.phone = ''
                state.profile_form.birthday = ''
                state.profile_form.errors.errors = {}
            })
        },

        initWalletBalance() {
            this.$patch((state) => {
                if (state.user) {
                    if (state.user.wallet.length) {
                        _.map(state.user.wallet, function (obj) {
                            if (obj.type === 'gold') {
                                state.gold_balance = obj.balance ? parseFloat(obj.balance) : 0
                            } else if (obj.type === 'silver') {
                                state.silver_balance = obj.balance ? parseFloat(obj.balance) : 0
                            } else if (obj.type === 'silver_winnings') {
                                state.silver_balance_redeem = obj.balance ? parseFloat(obj.balance) : 0
                            }
                        })
                    }
                }
            })
        },

        updateWalletBalance(params) {
            if (params.type == 'silver') {
                this.$patch((state) => {
                    state.silver_balance = params.balance ? parseFloat(params.balance) : 0
                    state.silver_balance_redeem = params.balance_winnings ? parseFloat(params.balance_winnings) : 0
                })
            } else if (params.type == 'gold') {
                this.$patch((state) => {
                    state.gold_balance = params.balance ? parseFloat(params.balance) : 0
                })
            }
        },
        // updateWalletBalanceAfterTopup(params) {
        //     this.$patch((state) => {
        //         console.log(params);
        //         console.log(state.silver_balance);
        //         console.log(params.silver_coins);

        //         state.gold_balance = state.gold_balance + parseFloat(params.gold_coins)
        //         state.silver_balance = state.silver_balance + parseFloat(params.silver_coins)
        //         state.silver_balance_redeem = parseFloat(params.balance_winnings)
        //     })
        // },
        getLoginAttempts() {
            return new Promise((resolve, reject) => {
                getLoginAttempts()
                    .then((response) => {
                        this.$patch((state) => {
                            state.login_attempts = response.attempt
                        })
                        resolve(response)
                    })
                    .catch((error) => {
                        if (error.response.data.status) {
                            errorToast(error.response.data.status, 'top-right')
                        }
                        reject(error)
                    })
            })
        },

        reloadBalance(params) {
            const { type } = params
            switch (type) {
                case 'gold_coins':
                    return new Promise((resolve, reject) => {
                        reloadBalance({ type: 'gold_coins' })
                            .then((response) => {
                                if (!response) {
                                    reject('Failed to load balance!')
                                }

                                this.$patch((state) => {
                                    state.gold_balance = response.balance ? parseFloat(response.balance) : 0
                                })
                                resolve()
                            })
                            .catch((error) => {
                                this.$patch((state) => {
                                    if (error.response) {
                                        state.error_msg = error.response.data
                                    }
                                })
                                errorToast(
                                    'Error to load balance!',
                                    'top-right'
                                )
                                reject(error)
                            })
                    })
                    break
                case 'silver_coins':
                    return new Promise((resolve, reject) => {
                        reloadBalance({ type: 'silver_coins' })
                            .then((response) => {
                                if (!response) {
                                    reject('Failed to load balance!')
                                }

                                this.$patch((state) => {
                                    state.silver_balance = response.balance ? parseFloat(response.balance) : 0
                                    state.silver_balance_redeem = response.balance_winnings ? parseFloat(response.balance_winnings) : 0

                                })
                                resolve()
                            })
                            .catch((error) => {
                                this.$patch((state) => {
                                    if (error.response) {
                                        state.error_msg = error.response.data
                                    }
                                })
                                errorToast(
                                    'Error to load balance!',
                                    'top-right'
                                )
                                reject(error)
                            })
                    })
                    break
            }
        },
        getSocialLinked(force = false) {
            if (!force) {
                if (this.social_linked != null) {
                    return
                }
            }
            return new Promise((resolve, reject) => {
                getSocialLinked()
                    .then((response) => {
                        this.$patch((state) => {
                            if (response?.list?.length == 0) {
                                state.social_linked = []
                            } else {
                                state.social_linked = _.pluck(
                                    response.list,
                                    'provider'
                                )
                            }
                        })
                        resolve(response)
                    })
                    .catch((error) => {
                        if (error.response.data.status) {
                            errorToast(error.response.data.status, 'top-right')
                        }
                        reject(error)
                    })
            })
        },
        changePassword(params) {
            // if(params.email){
            //   params.email = params.email.trim();
            // }
            return new Promise((resolve, reject) => {
                changePasswordAPI(params)
                    .then((response) => {
                        this.$patch((state) => {
                            state.error_msg = {}
                        })
                        resolve(response)
                    })
                    .catch((error) => {
                        this.$patch((state) => {
                            if (error.response) {
                                state.error_msg = error.response.data.errors
                            }
                        })
                        if (error.response.data.status) {
                            errorToast(error.response.data.message, 'top-right')
                        }
                        reject(error)
                    })
            })
        },
        getReferralCode() {
            return new Promise((resolve, reject) => {
                getReferralCodeAPI()
                    .then((response) => {
                        this.$patch((state) => {
                            state.referral_code = response.code
                        })
                        resolve()
                    })
                    .catch((error) => {
                        this.$patch((state) => {
                            if (error.response) {
                                state.error_msg = error.response.data
                            }
                        })
                        reject(error)
                    })
            })
        },
        setActiveCoinType(type) {
            this.$patch((state) => {
                state.active_coin_type = type
            })
        },
        setActiveGameUrl(url) {
            this.$patch((state) => {
                state.active_game_url = encodeURIComponent(url)
            })
        },
        checkStatusOfKYC(data) {
            this.$patch((state) => {
                const kycLength = data.kyc.length

                // kycStatus == false (need to display kyc anymore) 
                // kycStatus == 1 (verify) 
                // kycStatus == 0 (not verify) 

                if (kycLength == 0) {
                    state.kycStatus = null
                }
                if (kycLength == 1) {
                    let non_doc = _.find(
                        data.kyc,
                        function (params) {
                            return params.type == 'USA non-doc'
                        }
                    )

                    if (non_doc) {
                        if (non_doc?.type == "USA non-doc" && non_doc?.status == 1){
                            state.kycStatus = 1;
                        } else if (non_doc?.type == "USA non-doc" && non_doc?.status != 1) {
                            state.kycStatus = non_doc.status;
                        } else {
                            state.kycStatus = false
                        } 
                    } else {
                        state.kycStatus = null
                    }
                }
                if (data.kyc.length == 2) {
                    let basic = _.filter(
                        data.kyc,
                        function (params) {
                            return params.type == 'basic-kyc-level'
                        }
                    )

                    let non_doc = _.find(
                        data.kyc,
                        function (params) {
                            return params.type == 'USA non-doc'
                        }
                    )

                    if (basic && non_doc) {
                        if (
                            (non_doc?.type == "USA non-doc" && non_doc?.status == 1) &&
                            (basic?.type == "basic-kyc-level" && basic?.status == 1)
                        ){
                            state.kycStatus = 1
                        } else if (
                            (non_doc?.type == "USA non-doc" && non_doc?.status != 1) ||
                            (basic?.type == "basic-kyc-level" && basic?.status != 1) 
                        ) {
                            if(non_doc?.status != 1 && basic?.status == 1) {
                                state.kycStatus = non_doc?.status
                            } else if (basic?.status != 1 && non_doc?.status == 1) {
                                state.kycStatus = basic?.status
                            } else if (non_doc?.status == 0 && basic?.status == 0) {
                                state.kycStatus = 0
                            } else if (non_doc?.status == 2 && basic?.status == 2) {
                                state.kycStatus = 2
                            }
                        }
                    } else {
                        state.kycStatus = null
                    }
                }  
            })
        }
    }
})
