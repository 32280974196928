<template>
     <div
          v-if="provider"
          @click="onDropdown()"
          class="dropdown-trigger rounded-full bg-primary-1 h-full flex justify-center items-center cursor-pointer px-5"
          :class="hideDropdown == true ? 'hidden' : ''"
     >
          <p class="provider-dropdown-list font-roboto font-extrabold text-white text-xs md:text-xs lg:text-sm xl:text-lg capitalize md:uppercase cursor-pointer">
               {{ selectedDropdown.length == 0 ? '' : (selectedDropdown.includes(0) ? 'GAME STUDIOS' : getSelectedDropwdown(selectedDropdown[selectedDropdown.length - 1]))}}
               {{ selectedDropdown.length == 0 ? 'No Item' : ''}}
               <span
                    v-if="!selectedDropdown.includes(0)"
                    :class="[0, 1].includes(selectedDropdown.length) ? '' : 'text-black-10 bg-white px-2 rounded-xl ml-1'"
               >{{ [0, 1].includes(selectedDropdown.length) ? '' : (selectedDropdown.includes(0) ? (selectedDropdown.length-1) : selectedDropdown.length)+'+' }}</span>
          </p>
     </div>
     <div
          v-if="provider"
          :class="isDropdown ? 'open-dropdown' : ''"
          class="absolute top-0 right-0 rounded-full provider-dropdown-wrapper overflow-auto bg-primary-1 h-full"
     >
          <div class="provider-dropdown rounded-full h-full w-max flex justify-center items-center px-5">
               <div class="flex flex-row justify-center items-center gap-3">
                    <p
                         v-for="(item, key) in provider"
                         :key="key"
                         @click="onSelect(item.id)"
                         class="provider-dropdown-list font-roboto font-extrabold text-white text-xs md:text-xs lg:text-sm xl:text-lg capitalize md:uppercase cursor-pointer flex items-center gap-1"
                    >
                         <Check v-if="selectedDropdown.includes(item.id)" />
                         <Uncheck v-else />
                         {{item.name}}
                    </p>
               </div>
          </div>
     </div>
</template>
<script>
import Check from "components/checkbox/check.vue";
import Uncheck from "components/checkbox/uncheck.vue";
import { onMounted } from "vue";

export default {
     props: ['provider', 'isDropdown', 'onDropdown', 'selectedDropdown', 'onSelect', 'getSelectedDropwdown', 'hideDropdown'],
     components: {
          Check,
          Uncheck,
     },
     setup(props, { emit }) {

          onMounted(async () => {
               document.addEventListener("click", (evt) => {
                    const trigger1 = document.querySelector(
                         ".provider-dropdown-wrapper"
                    );
                    const trigger2 =
                         document.querySelector(".dropdown-trigger");
                    const trigger3 = document.querySelector(
                         ".provider-dropdown-list"
                    );

                    let targetEl = evt.target; // clicked element
                    if (
                         ["path1", "path2", "path3", "path4", "path5"].includes(
                              targetEl.className
                         )
                    ) {
                         return;
                    }
                    if (targetEl != null) {
                         do {
                              if (
                                   targetEl == trigger1 ||
                                   targetEl == trigger2 ||
                                   targetEl == trigger3 
                              ) {
                                   return;
                              }
                              targetEl = targetEl.parentNode;
                         } while (targetEl);
                    }

                    props.onDropdown(false)
               });
          })
          

          return {
          };
     },
};
</script>