<template>
     <div class="w-full !h-full welcome-bg-container welcome-bg-container-home flex flex-col lg:flex-row lg:justify-center lg:items-center bg-background-2 relative">
          <div class="w-full 3xl:max-w-screen-3xl flex flex-col lg:flex-row lg:items-center lg:justify-between relative mt-16 md:mt-22 lg:mt-0 xs:px-8 sm:px-18 md:px-24 lg:px-28 xl:px-32 pt-16 md:pt-20 lg:pt-0">
               <div class="w-full lg:w-5/12 xl:w-5/12 2xl:w-4/12 flex items-center lg:pb-22 welcome-slider">
                    <Carousel
                         :itemsToShow="1"
                         snapAlign="start"
                         :wrapAround="false"
                         :mouseDrag="false"
                         :touchDrag="false"
                         :transition="500"
                         class="w-full promotion-text-carousel z-20"
                    >
                         <Slide
                         :key="0"
                         >
                              <div class="w-full h-full flex flex-col justify-start md:justify-center items-center lg:items-start">
                                   <h1 class="text-white font-helvetica tracking-wide text-center lg:text-start text-6xl xs:text-6xl sm:text-7xl md:text-7xl lg:text-6xl xl:text-7xl 2xl:text-8xl leading-5xl xs:leading-5xl sm:leading-6xl md:leading-6xl lg:leading-5xl xl:leading-6xl 2xl:leading-7.5xl">AMERICA'S
                                        <br> SOCIAL <br> CASINO
                                   </h1>
                                   <p class="text-white font-helveticaLight tracking-wide mt-5 md:mt-10 mb-4 md:mb-8 w-full text-center lg:text-start md:w-10/12 lg:w-full font-normal text-sm sm:text-lg md:text-xl lg:text-lg xl:text-xl">Moozi have a diverse selection of games spanning across various platforms to cater to every gamer's preference.</p>
                                   <div @click="() => gotoGames()" class="rounded-full font-roboto font-bold tracking-wider px-10 py-3 bg-secondary-3 text-primary-1 text-sm sm:text-base md:text-lg lg:text-base xl:text-lg cursor-pointer">
                                        <span class="cursor-pointer">Play Now</span>
                                   </div>
                              </div>
                         </Slide>
                         <Slide v-for="(item, key) in promotion" :key="key + 1">
                              <div class="w-full h-full flex flex-col justify-center items-center lg:items-start">
                                   <h1 class="text-white font-helvetica tracking-wide text-center lg:text-start text-6xl xs:text-6xl sm:text-7xl md:text-7xl lg:text-6xl xl:text-7xl 2xl:text-8xl leading-5xl xs:leading-5xl sm:leading-6xl md:leading-6xl lg:leading-5xl xl:leading-6xl 2xl:leading-7.5xl">
                                        <span class="text-secondary-3">{{item.title}}</span> <br> {{item.title_highlight}}
                                   </h1>
                                   <p class="text-white font-helveticaLight tracking-wide mt-5 md:mt-10 mb-4 md:mb-8 w-full text-center lg:text-start md:w-10/12 lg:w-full font-normal text-sm sm:text-lg md:text-xl lg:text-lg xl:text-xl">{{item.sub_content}}</p>
                                   <div @click="() => goto(item)" class="rounded-full font-roboto font-bold tracking-wider px-10 py-3 bg-secondary-3 text-primary-1 text-sm sm:text-base md:text-lg lg:text-base xl:text-lg cursor-pointer">
                                        <span class="cursor-pointer">See More</span>
                                   </div>
                              </div>
                         </Slide>
                         <template #addons>
                              <Pagination class="lg:!justify-start" />
                         </template>
                    </Carousel>
               </div>
               <div class="w-full lg:w-2/4 welcome-animation">
                    <img
                         width="942"
                         height="870"
                         id="bee-welcome"
                         loading="lazy"
                         class="absolute z-10"
                         :src="baseUrl.img+'/assets/img/home/welcome/hero-animated-bee.webp'"
                         alt=""
                    >
                    <img
                         width="942"
                         height="870"
                         id="flag-welcome"
                         loading="lazy"
                         class="absolute"
                         :src="baseUrl.img+'/assets/img/home/welcome/hero-animated-flag.gif'"
                         alt=""
                    >
                    <img
                         width="942"
                         height="870"
                         id="cow-welcome"
                         loading="lazy"
                         class="absolute"
                         :src="baseUrl.img+'/assets/img/home/welcome/hero-animated-cow.webp'"
                         alt=""
                    >
                    <img
                         width="942"
                         height="870"
                         loading="lazy"
                         id="coin-welcome"
                         class="absolute z-10"
                         :src="baseUrl.img+'/assets/img/home/welcome/hero-animated-coin.webp'"
                         alt=""
                    >
                    <img
                         width="942"
                         height="870"
                         loading="lazy"
                         id="hen-welcome"
                         class="absolute z-10"
                         :src="baseUrl.img+'/assets/img/home/welcome/hero-animated-hen.webp'"
                         alt=""
                    >
               </div>
          </div>
          <div 
               class="w-full max-w-full h-32 xs:h-52 md:h-72 2xl:h-96 absolute bottom-0 bg-background-3"
               style="clip-path: polygon(100% 100%, 0% 100% , 0.00% 86.17%, 1.67% 85.03%, 3.33% 83.85%, 5.00% 82.61%, 6.67% 81.33%, 8.33% 80.00%, 10.00% 78.63%, 11.67% 77.22%, 13.33% 75.78%, 15.00% 74.31%, 16.67% 72.82%, 18.33% 71.31%, 20.00% 69.77%, 21.67% 68.23%, 23.33% 66.67%, 25.00% 65.12%, 26.67% 63.56%, 28.33% 62.00%, 30.00% 60.46%, 31.67% 58.93%, 33.33% 57.41%, 35.00% 55.92%, 36.67% 54.45%, 38.33% 53.01%, 40.00% 51.60%, 41.67% 50.23%, 43.33% 48.90%, 45.00% 47.62%, 46.67% 46.38%, 48.33% 45.20%, 50.00% 44.07%, 51.67% 42.99%, 53.33% 41.98%, 55.00% 41.03%, 56.67% 40.15%, 58.33% 39.34%, 60.00% 38.59%, 61.67% 37.92%, 63.33% 37.33%, 65.00% 36.81%, 66.67% 36.36%, 68.33% 36.00%, 70.00% 35.72%, 71.67% 35.51%, 73.33% 35.39%, 75.00% 35.35%, 76.67% 35.39%, 78.33% 35.51%, 80.00% 35.72%, 81.67% 36.00%, 83.33% 36.36%, 85.00% 36.81%, 86.67% 37.33%, 88.33% 37.92%, 90.00% 38.59%, 91.67% 39.34%, 93.33% 40.15%, 95.00% 41.03%, 96.67% 41.98%, 98.33% 42.99%, 100.00% 44.07%);"
          ></div>
     </div>
</template>

<script>
import { gsap } from "gsap";
import { Carousel, Navigation, Pagination, Slide } from "vue3-carousel";
import { onMounted, computed, watch, ref } from "vue";
import { utilitiesStore } from "store/utilities.js";
import { promoStore } from "store/promotion";
import { useRouter } from "vue-router";
import device from 'middleware/device.js';
import { baseUrl } from "config/services.js";

export default {
     components: {
          Carousel,
          Slide,
          Pagination,
          Navigation,
     },
     setup() {
          const { deviceType } = device();

          const router = useRouter();

          const promotion = ref(null);

          const useUtilitiesStore = utilitiesStore();
          const usePromoStore = promoStore();

          const loading = computed(() => {
               return useUtilitiesStore.loading;
          });

          const promoData = computed(() => {
               if (usePromoStore.promo) {
                    promotion.value = usePromoStore.promo;
               }
               return usePromoStore.promo || null;
          });

          const flagAnimation = () => {
               var flagTL =  gsap.timeline()
                    .from("#flag-welcome", {
                         opacity: 1,
                         xPercent: 100,
                         duration: 0.4,
                         ease: "sine.in",
                    })
                    .to("#flag-welcome", {
                         x: 8,
                         duration: 0.5,
                         ease: "power1.out",
                    });

               return flagTL;
          };

          const cowAnimation = () => {
               var cowTL =  gsap.timeline()
                    .from("#cow-welcome", {
                         opacity: 0,
                         yPercent: 60,
                         duration: 0.4,
                         ease: "sine.in",
                    })
                    .to("#cow-welcome", {
                         y: -7,
                         duration: 0.2,
                         ease: "power1.out",
                    })
                    .to("#cow-welcome", {
                         y: 8,
                         duration: 0.3,
                         ease: "power1.out",
                    });

               return cowTL;
          };

          const beeAnimation = () => {
               var beeTL = gsap.timeline()
                    .from("#bee-welcome", {
                         opacity: 0,
                         xPercent: -50,
                         yPercent: 50,
                         duration: 0.4,
                         ease: "sine.out",
                    })
                    .to("#bee-welcome", {
                         x: 10,
                         y: -10,
                         duration: 0.2,
                         ease: "power3.out",
                    })
                    .to("#bee-welcome", {
                         x: 0,
                         y: 0,
                         duration: 0.3,
                         ease: "power3.out",
                    });
               
               return beeTL;
          };

          const henAnimation = () => {
               const henTL = gsap.timeline()
                    .from("#hen-welcome", {
                         opacity: 0,
                         yPercent: 30,
                         duration: 0.4,
                         ease: "sine.out",
                    })
                    .to("#hen-welcome", {
                         y: -15,
                         duration: 0.15,
                         ease: "power3.out",
                    })
                    .to("#hen-welcome", {
                         y: 0,
                         duration: 0.3,
                         ease: "power3.out",
                    });

               return henTL;
          };

          const coinAnimation = () => {
               const coinTL = gsap.timeline()
                    .from("#coin-welcome", {
                         opacity: 0,
                         xPercent: 50,
                         duration: 0.4,
                         ease: "sine.out",
                    })
                    .to("#coin-welcome", {
                         x: -20,
                         duration: 0.2,
                         ease: "power3.out",
                    })
                    .to("#coin-welcome", {
                         x: 0,
                         duration: 0.3,
                         ease: "power3.out",
                    });

               return coinTL;
          };

          const gotoGames = () => {
               window.location.href = "#games-container";
          }

          const goto = (item) => {
               router.push({
                    path:
                         "/promotion/detail/" +
                         item.title.replaceAll(" ", "-") +
                         "-" +
                         item.title_highlight.replaceAll(" ", "-") +
                         "-" +
                         item.id,
               });
          };

          onMounted(async () => {
               if (!loading.value) {
                    flagAnimation()
                    cowAnimation();
                    beeAnimation();
                    henAnimation();
                    coinAnimation();
               }
          });

          watch(promoData, (newVal) => {
               if (newVal) {
                    promotion.value = newVal;
               }
          });

          watch(loading, (newVal) => {
               if (!newVal) {
                    cowAnimation();
                    beeAnimation();
                    henAnimation();
                    coinAnimation();
               }
          });

          return {
               gotoGames,
               promotion,
               goto,
               baseUrl
          };
     },
};
</script>
