<template>
     <div class="w-full bg-primary-2 relative">
          <div class="w-full 3xl:max-w-screen-3xl relative mx-auto">
               <div class="w-full hidden md:flex justify-center ">
                    <img
                         loading="lazy"
                         id="cow-games"
                         class="absolute w-full md:w-10/12 lg:w-9/12 xl:w-10/12 2xl:w-auto z-10 right-10 lg:top-36 2xl:top-28"
                         :src="baseUrl.img+'/assets/img/home/game/hero-animated-cow.webp'"
                         alt=""
                    >
                    <img
                         loading="lazy"
                         id="carrot-2-games"
                         class="absolute z-10 w-full md:w-10/12 lg:w-9/12 xl:w-10/12 2xl:w-auto right-10 lg:top-36 2xl:top-28"
                         :src="baseUrl.img+'/assets/img/home/game/hero-animated-carrot2.webp'"
                         alt=""
                    >
                    <img
                         loading="lazy"
                         id="carrot-games"
                         class="absolute z-10 w-full md:w-10/12 lg:w-9/12 xl:w-10/12 2xl:w-auto left-0 md:left-24 lg:left-28 xl:left-32 md:-top-56 lg:-top-52 xl:-top-76 2xl:-top-96 opacity-0"
                         :src="baseUrl.img+'/assets/img/home/game/hero-animated-carrot.webp'"
                         alt=""
                    >
                    <img
                         loading="lazy"
                         id="coin-games"
                         class="absolute z-10 w-full md:w-10/12 lg:w-9/12 xl:w-10/12 2xl:w-auto -left-1/4 md:-top-56 lg:-top-52 xl:-top-76 2xl:-top-96 opacity-0"
                         :src="baseUrl.img+'/assets/img/home/game/hero-animated-coin.webp'"
                         alt=""
                    >
               </div>
               <div
                    class="w-full h-full flex flex-col justify-start items-center md:items-start px-0 md:px-24 lg:px-28 xl:px-32 pb-8 xs:pb-12 sm:pb-18 md:pb-24 lg:pb-28 xl:pb-32 z-10 relative"
               >
                    <div class="flex flex-col justify-center items-center px-0 md:px-0 lg:px-6 xl:px-12 2xl:px-28 pt-3 lg:pt-10">
                         <h1 class="text-white font-helveticaItalic tracking-wide italic text-4xl xs:text-5xl sm:text-7xl md:text-7xl xl:text-8xl leading-3xl xs:leading-4xl sm:leading-6xl md:leading-6xl xl:leading-7.5xl">GAMES
                         </h1>
                         <div class="mt-2">
                              <Star/>
                         </div>
                    </div>
                    <div class="mt-12 md:mt-20 w-full bg-primary-2">
                         <div class="w-full flex flex-col-reverse md:flex-col">
                              <div class=" px-5 xs:px-8 sm:px-18 md:px-0">
                                   <div class="relative w-full">
                                        <SearchGame
                                             :onSearch="onSearch"
                                             :provider="provider"
                                             :isDropdown="isDropdown"
                                             :onDropdown="(val) => {isDropdown = (val != null ? val : !isDropdown)}"
                                             :selectedDropdown="selectedDropdown"
                                             :onSelect="onSelect"
                                             :getSelectedDropwdown="getSelectedDropwdown"
                                             :hideDropdown="true"
                                        />
                                   </div>
                              </div>
                              <div class="rounded-none md:rounded-full mt-0 md:mt-2 mb-3 md:mb-0 w-full h-11 bg-primary-1 shadow-lg flex flex-row items-center p-1">
                                   <CategoryGame
                                        :game_type="game_type"
                                        :isUser="isUser"
                                        :onSetActive="onSetActive"
                                        :active="active"
                                        :isExpand="isExpand"
                                        :onExpand="(val) => {isExpand = val}"
                                   />
                                   <SortGame
                                        :pagination="pagination"
                                        :sortByOpen="sortByOpen"
                                        :onSortByOpen="(val) => { sortByOpen = (val != null ? val : !sortByOpen)}"
                                        :onSort="onSort"
                                   />
                              </div>
                         </div>
                         <div class="w-full h-full">
                              <loading-overlay :isLoading="loading" />
                              <div
                                   v-if="pagination.data != null && pagination.data.length != 0"
                                   class="game-wrapper mt-3 w-full h-full grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 grid gap-3 md:gap-5 relative px-5 xs:px-8 sm:px-18 md:px-0"
                              >
                                   <CardGame
                                        :pagination="pagination"
                                        :onStar="onStar"
                                        :onLaunch="onLaunch"
                                        :isUser="isUser"
                                        :provider="provider"
                                   />
                              </div>
                              <div
                                   v-else
                                   class="px-8 sm:px-18 md:px-0 w-full pt-20 md:pt-48 text-white"
                              >
                                   <h1 v-if="selfExclusion == null" class="text-primary-1 text-center w-full font-roboto tracking-wide text-xs md:text-xs lg:text-sm xl:text-lg">
                                        No data has been found!
                                   </h1>
                                   <SelfExclusionAlert :selfExclusion="selfExclusion" v-else />
                              </div>
                         </div>
                         <!-- <div v-else class="w-full h-96 relative"> -->
                         <!-- </div> -->
                         <div
                              v-if="pagination.totalPage"
                              class="flex w-full justify-center items-center gap-2 md:gap-7 mt-14"
                         >
                              <div
                                   class="prev p-2 md:p-3 lg:p-4 xl:p-5 rounded-full"
                                   :class="pagination.page > 2 ? 'scale-100 hover:scale-105 transition-transform duration-300 ease-in-out cursor-pointer text-primary-1 bg-secondary-3' : 'cursor-not-allowed bg-primary-4'"
                                   @click="onFirstPage"
                              >
                                   <svg
                                        class="w-2 md:w-4 h-2 md:h-4"
                                        :class="pagination.page > 2 ? '' : 'opacity-20'"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                   >
                                        <path d="M8 2.00391L2 8.00391L8 14.0039" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M13 2.00391L7 8.00391L13 14.0039" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                   </svg>
                              </div>
                              <div
                                   class="prev p-2 md:p-3 lg:p-4 xl:p-5 rounded-full"
                                   :class="pagination.page != 1 ? 'scale-100 hover:scale-105 transition-transform duration-300 ease-in-out cursor-pointer text-primary-1 bg-secondary-3' : 'cursor-not-allowed bg-primary-4'"
                                   @click="onPrevious"
                              >
                                   <svg
                                        class="w-2 md:w-4 h-2 md:h-4"
                                        :class="pagination.page != 1 ? '' : 'opacity-20'"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                   >
                                        <path d="M11 1.99805L5 7.99805L11 13.998" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                   </svg>
                              </div>
                              <div
                                   class="font-medium p-2 md:p-3 lg:p-4 xl:p-5 rounded-full flex justify-center items-center cursor-pointer scale-100 hover:scale-105 transition-transform duration-300 ease-in-out"
                                   :class="pagination.page == 1 ? 'text-secondary-3 underline underline-offset-4' : 'text-primary-1'"
                                   @click="onFirstBtn"
                              >
                                   <span class="text-base sm:text-xl xl:text-2xl leading-5 text-center w-4 h-4">
                                        {{ pagination.page == 1 ? 1 : (pagination.page == pagination.totalPage && pagination.totalPage < 2) || (pagination.page == pagination.totalPage && pagination.page != 2)  ? pagination.page - 2 : pagination.page - 1 }}
                                   </span>
                              </div>
                              <div
                                   v-if="pagination.totalPage != 1"
                                   class="font-medium p-2 md:p-3 lg:p-4 xl:p-5 rounded-full flex justify-center items-center cursor-pointer scale-100 hover:scale-105 transition-transform duration-300 ease-in-out"
                                   :class="(pagination.page != 1 && pagination.page != pagination.totalPage) || (pagination.page == 2 && pagination.page == pagination.totalPage) ? 'text-secondary-3 underline underline-offset-4' : 'text-primary-1'"
                                   @click="onSecondBtn"
                              >
                                   <span class="text-base sm:text-xl xl:text-2xl leading-5 text-center w-4 h-4">
                                        {{ pagination.page == 1 ? pagination.page + 1 : ((pagination.page == pagination.totalPage && pagination.totalPage < 2) || (pagination.page == pagination.totalPage && pagination.page != 2) ? pagination.page - 1 : pagination.page) }}
                                   </span>
                              </div>
                              <div
                                   v-if="pagination.totalPage != 1 && pagination.totalPage != 2"
                                   class="font-medium p-2 md:p-3 lg:p-4 xl:p-5 rounded-full flex justify-center items-center cursor-pointer scale-100 hover:scale-105 transition-transform duration-300 ease-in-out"
                                   :class="pagination.page == 1 ? 'text-primary-1' : pagination.page == pagination.totalPage ? 'text-secondary-3 underline underline-offset-4' : 'text-primary-1'"
                                   @click="onThirdBtn"
                              >
                                   <span class="text-base sm:text-xl xl:text-2xl leading-5 text-center w-4 h-4 cursor-pointer">
                                        {{ pagination.page == 1 ? pagination.page + 2 : pagination.page == pagination.totalPage ? pagination.page : pagination.page + 1 }}
                                   </span>
                              </div>
                              <div
                                   class="p-2 md:p-3 lg:p-4 xl:p-5 rounded-full"
                                   :class="pagination.page != pagination.totalPage ? 'scale-100 hover:scale-105 transition-transform duration-300 ease-in-out cursor-pointer text-primary-1 bg-secondary-3' : 'cursor-not-allowed bg-primary-4'"
                                   @click="onNext"
                              >
                                   <svg
                                        class="w-2 md:w-4 h-2 md:h-4"
                                        :class="pagination.page != pagination.totalPage ? '' : 'opacity-20'"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5 14L11 8L5 2" stroke="#422C18" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                   </svg>
                              </div>
                              <div
                                   class="p-2 md:p-3 lg:p-4 xl:p-5 rounded-full"
                                   :class="pagination.page != pagination.totalPage ? 'scale-100 hover:scale-105 transition-transform duration-300 ease-in-out cursor-pointer text-primary-1 bg-secondary-3' : 'cursor-not-allowed bg-primary-4'"
                                   @click="onLastPage"
                              >
                                   <svg
                                        class="w-2 md:w-4 h-2 md:h-4"
                                        :class="pagination.page != pagination.totalPage ? '' : 'opacity-20'"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                   >
                                        <path d="M8 14L14 8L8 2" stroke="#422C18" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M3 14L9 8L3 2" stroke="#422C18" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                   </svg>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</template>
<script>
import { computed, onMounted, reactive, ref, watch } from "vue";
import { gamesStore } from "store/games";
import { authStore } from "store/auth.js";
import _ from "underscore";
import { setFavorites as setFavoritesAPI } from "@/api/games";
import { gameLauncher as gameLauncherAPI } from "@/api/seamless";
import { useToaster } from "composables/use-toaster.js";
import eventBus from "plugins/event.js";
import { useFormatter } from "composables/use-formatter.js";
import LoadingOverlay from "components/loading/loadingOverlay.vue";
import { useRouter } from "vue-router";
import SelfExclusionAlert from "components/selfExclusionAlert/index.vue";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Star from "components/star/index.vue";
import CardGame from "components/games/cards/index.vue";
import SortGame from "components/games/sortby/index.vue";
import CategoryGame from "components/games/category/index.vue";
import SearchGame from "components/games/search/index.vue";
import { baseUrl } from "config/services.js";

gsap.registerPlugin(ScrollTrigger);

export default {
     components: {
          LoadingOverlay,
          SelfExclusionAlert,
          Star,
          CardGame,
          SortGame,
          CategoryGame,
          SearchGame
     },
     setup() {
          const { money } = useFormatter();

          const router = useRouter();

          const useAuthStore = authStore();
          const useGamesStore = gamesStore();

          const isExpand = ref(false);

          const isDropdown = ref(false);

          const pagination = reactive({
               prevPage: false,
               nextPage: true,
               data: null,
               loading: null,
               totalPage: 0,
               page: 1,
               itemsPerPage: 20,
               game_type: [],
               game_promo: 0,
               game_name: "",
               provider_id: [],
               sortType: "popularity",
          });

          const selfExclusion = ref(null);

          const game_type = ref(null);

          const dropdownManual = ref(null);
          const dropdownManualMobile = ref(null);
          const dropdownOpen = ref(false);
          const selectedDropdown = ref([]);

          const provider = ref([]);

          const active = ref("all");

          const all_count = ref(0);

          const loading = ref(false);

          const sortByOpen = ref(false);

          let timer,
               timeoutVal = 1000;

          const gameHomeFilter = computed(() => {
               const obj = Object.keys(useGamesStore.gameHomeFilter);
               for (let index = 0; index < obj.length; index++) {
                    pagination[obj[index]] = useGamesStore.gameHomeFilter[obj[index]]
               }

               active.value = useGamesStore.gameHomeFilter['active']

               return useGamesStore.gameHomeFilter;
          });

          const gamesData = computed(() => {
               pagination.data = useGamesStore.games?.data;
               return useGamesStore.games || null;
          });

          const groupData = computed(() => {
               if (useGamesStore.groups) {
                    game_type.value = useGamesStore.groups;
               }
               return useGamesStore.groups || null;
          });

          const providerData = computed(() => {
               if (useGamesStore.provider) {
                    const all = {
                         name: "Game Studios",
                         id: 0,
                    };
                    selectedDropdown.value = [
                         0,
                         ..._.pluck(useGamesStore.provider, "id"),
                    ];
                    provider.value = [...useGamesStore.provider, all];
               }
               return useGamesStore.provider || null;
          });

          const favoritesData = computed(() => {
               return useGamesStore.favorites || null;
          });

          const recentData = computed(() => {
               return useGamesStore.recent || null;
          });

          const errorImages = ref([]);

          const isUser = computed(() => {
               return useAuthStore.user;
          });

          const kycStatus = computed(() => {
               return useAuthStore.kycStatus;
          });

          const { successToast, errorToast } = useToaster();

          const onPrevious = async () => {
               if (pagination.page == 1) {
                    return;
               }
               loading.value = true;
               pagination.page != 1 ? pagination.page-- : null;

               if(active.value != "favorite" && active.value != "recent") {
                    const params = setParameters(pagination);
                    useGamesStore.setGameHomeFilter(params, active.value);
                    await useGamesStore.getGames(params);
               } else {
                    if (active.value == "favorite") {
                         const params = setParameters(
                              pagination,
                              "favorite"
                         );
                         useGamesStore.setGameHomeFilter(params, active.value);
                         await useGamesStore.getFavorites(params);
                    } else {
                         const params = setParameters(
                              pagination,
                              "recent"
                         );
                         useGamesStore.setGameHomeFilter(params, active.value);
                         await useGamesStore.getRecent(params);
                    }
               }
               loading.value = false;
          };

          const onFirstPage = async () => {
               loading.value = true;

               pagination.page = 1;

               if(active.value != "favorite" && active.value != "recent") {
                    const params = setParameters(pagination);
                    useGamesStore.setGameHomeFilter(params, active.value);
                    await useGamesStore.getGames(params);
               } else {
                    if (active.value == "favorite") {
                         const params = setParameters(
                              pagination,
                              "favorite"
                         );
                         useGamesStore.setGameHomeFilter(params, active.value);
                         await useGamesStore.getFavorites(params);
                    } else {
                         const params = setParameters(
                              pagination,
                              "recent"
                         );
                         useGamesStore.setGameHomeFilter(params, active.value);
                         await useGamesStore.getRecent(params);
                    }
               }

               loading.value = false;
          };

          const onNext = async () => {
               if (pagination.page == pagination.totalPage) {
                    return;
               }
               loading.value = true;
               pagination.page != pagination.totalPage
                    ? pagination.page++
                    : null;

               if(active.value != "favorite" && active.value != "recent") {
                    const params = setParameters(pagination);
                    useGamesStore.setGameHomeFilter(params, active.value);
                    await useGamesStore.getGames(params);
               } else {
                    if (active.value == "favorite") {
                         const params = setParameters(
                              pagination,
                              "favorite"
                         );
                         useGamesStore.setGameHomeFilter(params, active.value);
                         await useGamesStore.getFavorites(params);
                    } else {
                         const params = setParameters(
                              pagination,
                              "recent"
                         );
                         useGamesStore.setGameHomeFilter(params, active.value);
                         await useGamesStore.getRecent(params);
                    }
               }

               loading.value = false;
          };

          const onFirstBtn = async () => {
               loading.value = true;

               pagination.page == 1
                    ? (pagination.page = 1)
                    : (pagination.page == pagination.totalPage &&
                           pagination.totalPage < 2) ||
                      (pagination.page == pagination.totalPage &&
                           pagination.page != 2)
                    ? (pagination.page = pagination.page - 2)
                    : (pagination.page = pagination.page - 1);

               if(active.value != "favorite" && active.value != "recent") {
                    const params = setParameters(pagination);
                    useGamesStore.setGameHomeFilter(params, active.value);
                    await useGamesStore.getGames(params);
               } else {
                    if (active.value == "favorite") {
                         const params = setParameters(
                              pagination,
                              "favorite"
                         );
                         useGamesStore.setGameHomeFilter(params, active.value);
                         await useGamesStore.getFavorites(params);
                    } else {
                         const params = setParameters(
                              pagination,
                              "recent"
                         );
                         useGamesStore.setGameHomeFilter(params, active.value);
                         await useGamesStore.getRecent(params);
                    }
               }

               loading.value = false;
          };

          const onSecondBtn = async () => {
               loading.value = true;

               pagination.page == 1
                    ? (pagination.page = pagination.page + 1)
                    : (pagination.page == pagination.totalPage &&
                           pagination.totalPage < 2) ||
                      (pagination.page == pagination.totalPage &&
                           pagination.page != 2)
                    ? (pagination.page = pagination.page - 1)
                    : (pagination.page = pagination.page);

               if(active.value != "favorite" && active.value != "recent") {
                    const params = setParameters(pagination);
                    useGamesStore.setGameHomeFilter(params, active.value);
                    await useGamesStore.getGames(params);
               } else {
                    if (active.value == "favorite") {
                         const params = setParameters(
                              pagination,
                              "favorite"
                         );
                         useGamesStore.setGameHomeFilter(params, active.value);
                         await useGamesStore.getFavorites(params);
                    } else {
                         const params = setParameters(
                              pagination,
                              "recent"
                         );
                         useGamesStore.setGameHomeFilter(params, active.value);
                         await useGamesStore.getRecent(params);
                    }
               }

               loading.value = false;
          };

          const onThirdBtn = async () => {
               loading.value = true;

               pagination.page == 1
                    ? (pagination.page = pagination.page + 2)
                    : pagination.page == pagination.totalPage
                    ? (pagination.page = pagination.page)
                    : (pagination.page = pagination.page + 1);

               if(active.value != "favorite" && active.value != "recent") {
                    const params = setParameters(pagination);
                    useGamesStore.setGameHomeFilter(params, active.value);
                    await useGamesStore.getGames(params);
               } else {
                    if (active.value == "favorite") {
                         const params = setParameters(
                              pagination,
                              "favorite"
                         );
                         useGamesStore.setGameHomeFilter(params, active.value);
                         await useGamesStore.getFavorites(params);
                    } else {
                         const params = setParameters(
                              pagination,
                              "recent"
                         );
                         useGamesStore.setGameHomeFilter(params, active.value);
                         await useGamesStore.getRecent(params);
                    }
               }

               loading.value = false;
          };

          const onLastPage = async () => {
               loading.value = true;

               pagination.page = pagination.totalPage;

               if(active.value != "favorite" && active.value != "recent") {
                    const params = setParameters(pagination);
                    useGamesStore.setGameHomeFilter(params, active.value);
                    await useGamesStore.getGames(params);
               } else {
                    if (active.value == "favorite") {
                         const params = setParameters(
                              pagination,
                              "favorite"
                         );
                         useGamesStore.setGameHomeFilter(params, active.value);
                         await useGamesStore.getFavorites(params);
                    } else {
                         const params = setParameters(
                              pagination,
                              "recent"
                         );
                         useGamesStore.setGameHomeFilter(params, active.value);
                         await useGamesStore.getRecent(params);
                    }
               }

               loading.value = false;
          };

          const onImageError = (e) => {
               let temp = errorImages.value;
               temp.push(parseInt(e.target.alt));

               const uniq = _.uniq(temp);

               errorImages.value = uniq;

               e.target.src = "assets/img/games/default.png";
          };

          const onSetActive = async (name) => {
               if (active.value != name) {
                    active.value = name;

                    loading.value = true;
                    pagination.page = 1;

                    if (active.value == "hot") {
                         pagination.game_promo = 1;
                         pagination.sortType = 'popularity';
                    } else {
                         pagination.game_promo = 0;
                    }

                    if (active.value == "all" || active.value == "hot") {
                         pagination.game_type = [];
                    } else {
                         pagination.game_type = [active.value];
                    }

                    if (
                         active.value == "recent" ||
                         active.value == "favorite"
                    ) {
                         if (active.value == "favorite") {
                              const params = setParameters(
                                   pagination,
                                   "favorite"
                              );
                              useGamesStore.setGameHomeFilter(params, active.value);
                              await useGamesStore.getFavorites(params);
                              loading.value = false;
                         } else {
                              const params = setParameters(
                                   pagination,
                                   "recent"
                              );
                              useGamesStore.setGameHomeFilter(params, active.value);
                              await useGamesStore.getRecent(params);
                              loading.value = false;
                         }
                         return;
                    }

                    const params = setParameters(pagination);
                    useGamesStore.setGameHomeFilter(params, active.value);
                    await useGamesStore.getGames(params);

                    loading.value = false;
               }
          };

          const onSearch = (query) => {
               window.clearTimeout(timer); // prevent errant multiple timeouts from being generated
               timer = window.setTimeout(async () => {
                    loading.value = true;
                    pagination.page = 1;
                    pagination.game_name = query.toLowerCase();

                    if (
                         active.value == "recent" ||
                         active.value == "favorite"
                    ) {
                         if (active.value == "favorite") {
                              const params = setParameters(
                                   pagination,
                                   "favorite"
                              );
                              useGamesStore.setGameHomeFilter(params, active.value);
                              await useGamesStore.getFavorites(params);
                              loading.value = false;
                         } else {
                              const params = setParameters(
                                   pagination,
                                   "recent"
                              );
                              useGamesStore.setGameHomeFilter(params, active.value);
                              await useGamesStore.getRecent(params);
                              loading.value = false;
                         }
                         return;
                    }

                    const params = setParameters(pagination);
                    useGamesStore.setGameHomeFilter(params, active.value);
                    await useGamesStore.getGames(params).catch((err) => {
                         loading.value = false;
                    });

                    loading.value = false;
               }, timeoutVal);
          };

          const onStar = async (id, status) => {
               loading.value = true

               const params = { game_id: id };

               if (status == true) {
                    params["unset"] = 1;
               }

               await setFavoritesAPI(params);

               const parameters = setParameters(pagination, "favorite");
               if (active.value == "favorite") {
                    await useGamesStore.getFavorites(parameters, "games", true);
               } else {
                    if (isUser.value) {
                         await useGamesStore.getHeart();
                    }
               }

               const sliderParams = {
                    game_name: "",
                    game_promo: 0,
                    game_type: [],
                    provider_id: [],
                    page: 1,
                    itemsPerPage: 20,
                    sortType: "a-z"
               }

               sliderParams.provider_id = [..._.pluck(useGamesStore.provider, "id")];

               await useGamesStore.getFavoriteSlider(sliderParams, true);

               loading.value = false
          };

          const onLaunch = async (slug, type) => {
               if (isUser.value) {
                    if(openKyc() == true) {
                         return;
                    };

                    const filter = _.filter(
                         providerData.value,
                         function (params) {
                              return params.id == slug.provider_id;
                         }
                    );

                    await gameLauncherAPI(
                         { game_slug: slug.game_slug, type: type },
                         filter[0]["game_source"]
                    )
                         .then((res) => {
                              if (res.status) {
                                   if (
                                        /iPad|iPhone|iPod/.test(
                                             navigator.userAgent
                                        )
                                   ) {
                                        // alert("This is an iOS device.");
                                        setTimeout(() => {
                                            //  window.open(res.url, "_blank");
                                            useAuthStore.setActiveCoinType(type);
                                            useAuthStore.setActiveGameUrl(res.url);
                                            router.push({ name: 'play', params: { slug: slug.game_slug, id: slug.provider_id, launchcode: res.launch_code, type: type } });
                                        });
                                   } else {
                                        // window.open(res.url, "_blank");
                                        useAuthStore.setActiveCoinType(type);
                                        useAuthStore.setActiveGameUrl(res.url);
                                        router.push({ name: 'play', params: { slug: slug.game_slug, id: slug.provider_id, launchcode: res.launch_code, type: type } });
                                   }
                              }
                         })
                         .catch((e) => {
                              errorToast("Something went wrong!", "top-right");
                         });
               } else {
                    router.push({ name: "login" });
               }
          };

          function onVisibleChange(e) {
               dropdownOpen.value = e;
          }

          function onDropdownTrigger(e, type) {
               if (type == "mobile") {
                    if (!dropdownManualMobile.value.visible) {
                         dropdownManualMobile.value.display();
                    } else {
                         dropdownManualMobile.value.close();
                    }
               } else {
                    if (!dropdownManual.value.visible) {
                         dropdownManual.value.display();
                    } else {
                         dropdownManual.value.close();
                    }
               }
          }

          function onCloseDropdown(e) {
               dropdownManual.value.close();
               dropdownManualMobile.value.close();
          }

          function getSelectedDropwdown(id) {
               if (selectedDropdown.value.length == 1) {
                    const filter = _.filter(
                         providerData.value,
                         function (params) {
                              return params["id"] == id;
                         }
                    );

                    return filter[0]["name"];
               } else {
                    return "Game Studios";
               }
          }

          const onSelect = async (id) => {
               isDropdown.value = false;
               let data = selectedDropdown.value;
               if (id == 0) {
                    if (data.length == 0) {
                         data = [0, ..._.pluck(providerData.value, "id")];
                         selectedDropdown.value = data;
                         pagination.provider_id = data;
                    } else {
                         selectedDropdown.value = [];
                         pagination.provider_id = [];
                    }
               } else {
                    if (data.includes(id)) {
                         if (data.includes(0)) {
                              data.splice(data.indexOf(0), 1);
                         }
                         data.splice(data.indexOf(id), 1);
                         selectedDropdown.value = data;
                         pagination.provider_id = data;
                    } else {
                         data.push(id);
                         if (data.length == providerData.value.length) {
                              selectedDropdown.value = [0, ...data];
                         } else {
                              selectedDropdown.value = data;
                         }
                         pagination.provider_id = data;
                    }
               }

               pagination.page = 1;

               loading.value = true;

               if (active.value == "recent" || active.value == "favorite") {
                    if (active.value == "favorite") {
                         const params = setParameters(pagination, "favorite");
                         useGamesStore.setGameHomeFilter(params, active.value);
                         await useGamesStore.getFavorites(params);
                         loading.value = false;
                    } else {
                         const params = setParameters(pagination, "recent");
                         useGamesStore.setGameHomeFilter(params, active.value);
                         await useGamesStore.getRecent(params);
                         loading.value = false;
                    }
                    return;
               }

               const params = setParameters(pagination);
               useGamesStore.setGameHomeFilter(params, active.value);
               await useGamesStore.getGames(params);
               loading.value = false;
          };

          const onSort = async (value) => {
               loading.value = true;
               pagination.sortType = value
               pagination.page = 1;

               sortByOpen.value = false;

               if (active.value == "recent" || active.value == "favorite") {
                    if (active.value == "favorite") {
                         const params = setParameters(pagination, "favorite");
                         useGamesStore.setGameHomeFilter(params, active.value);
                         await useGamesStore.getFavorites(params);
                         loading.value = false;
                    } else {
                         const params = setParameters(pagination, "recent");
                         useGamesStore.setGameHomeFilter(params, active.value);
                         await useGamesStore.getRecent(params);
                         loading.value = false;
                    }
                    return;
               }

               const params = setParameters(pagination);
               useGamesStore.setGameHomeFilter(params, active.value);
               await useGamesStore.getGames(params);
               loading.value = false;
          };

          const openLogin = () => {
               router.push({ name: "login" });
          };

          const setParameters = (data, game_tpye) => {
               const params = {};
               params["game_name"] = data?.game_name;
               params["game_promo"] = data?.game_promo;
               params["game_type"] =
                    game_tpye == "favorite" || game_tpye == "recent"
                         ? []
                         : data?.game_type;
               params["provider_id"] = data?.provider_id;

               params["page"] = data?.page;
               params["itemsPerPage"] = data?.itemsPerPage;
               params["sortType"] = data?.sortType;
               return params;
          };

          const openKyc = () => {
               const find = _.find(isUser.value["kyc"], function (params) {
                    return params.type == 'USA non-doc' && params.status == 1
               })

               if (!kycStatus.value && !isUser.value["address"]) {
                    if(find) {
                         return false
                    }

                    eventBus.emit("open:kyc");
                    router.replace({ query: { modal: "kyc" } });

                    return true;
               } else if (!kycStatus.value && isUser.value["address"]) {
                    if(find) {
                         return false
                    }

                    eventBus.emit("open:idverification");
                    router.replace({ query: { modal: "idverification" } });

                    return true;
               }

               return false;
          };

          let mm = gsap.matchMedia();

          const cowAnimation = () => {
               let tl = gsap.timeline({
                    scrollTrigger: {
                         trigger: "#games-container",
                         start: "top center",
                         toggleActions: "restart none none reverse",
                         // markers: true,
                    },
               });

               mm.add("(min-width: 768px) and (max-width: 839px)", () => {
                    tl.to("#cow-games", {
                         top: -115,
                         duration: 0.4,
                         ease: "sine.in",
                    })
                         .to("#cow-games", {
                              top: -125,
                              duration: 0.2,
                              ease: "power1.out",
                         })
                         .to("#cow-games", {
                              top: -115,
                              duration: 0.3,
                              ease: "power1.out",
                         });
               });

               mm.add("(min-width: 840px) and (max-width: 922px)", () => {
                    tl.to("#cow-games", {
                         top: -145,
                         duration: 0.4,
                         ease: "sine.in",
                    })
                         .to("#cow-games", {
                              top: -155,
                              duration: 0.2,
                              ease: "power1.out",
                         })
                         .to("#cow-games", {
                              top: -145,
                              duration: 0.3,
                              ease: "power1.out",
                         });
               });

               mm.add("(min-width: 923px) and (max-width: 1023px)", () => {
                    tl.to("#cow-games", {
                         top: -177,
                         duration: 0.4,
                         ease: "sine.in",
                    })
                         .to("#cow-games", {
                              top: -187,
                              duration: 0.2,
                              ease: "power1.out",
                         })
                         .to("#cow-games", {
                              top: -177,
                              duration: 0.3,
                              ease: "power1.out",
                         });
               });

               mm.add("(min-width: 1024px) and (max-width: 1179px)", () => {
                    tl.to("#cow-games", {
                         top: -135,
                         duration: 0.4,
                         ease: "sine.in",
                    })
                         .to("#cow-games", {
                              top: -145,
                              duration: 0.2,
                              ease: "power1.out",
                         })
                         .to("#cow-games", {
                              top: -135,
                              duration: 0.3,
                              ease: "power1.out",
                         });
               });

               mm.add("(min-width: 1180px) and (max-width: 1279px)", () => {
                    tl.to("#cow-games", {
                         top: -185,
                         duration: 0.4,
                         ease: "sine.in",
                    })
                         .to("#cow-games", {
                              top: -195,
                              duration: 0.2,
                              ease: "power1.out",
                         })
                         .to("#cow-games", {
                              top: -185,
                              duration: 0.3,
                              ease: "power1.out",
                         });
               });

               mm.add("(min-width: 1280px) and (max-width: 1535px)", () => {
                    tl.to("#cow-games", {
                         top: -245,
                         duration: 0.4,
                         ease: "sine.in",
                    })
                         .to("#cow-games", {
                              top: -255,
                              duration: 0.2,
                              ease: "power1.out",
                         })
                         .to("#cow-games", {
                              top: -245,
                              duration: 0.3,
                              ease: "power1.out",
                         });
               });

               mm.add("(min-width: 1536px)", () => {
                    tl.to("#cow-games", {
                         top: -384,
                         duration: 0.4,
                         ease: "sine.in",
                    })
                         .to("#cow-games", {
                              top: -394,
                              duration: 0.2,
                              ease: "power1.out",
                         })
                         .to("#cow-games", {
                              top: -384,
                              duration: 0.3,
                              ease: "power1.out",
                         });
               });
          };

          const carrot2Animation = () => {
               let tl = gsap.timeline({
                    scrollTrigger: {
                         trigger: "#games-container",
                         start: "top center",
                         toggleActions: "restart none none reverse",
                         // markers: true,
                    },
               });

               mm.add("(min-width: 768px) and (max-width: 839px)", () => {
                    tl.to("#carrot-2-games", {
                         top: -115,
                         duration: 0.4,
                         delay: 0.1,
                         ease: "sine.in",
                    })
                         .to("#carrot-2-games", {
                              top: -125,
                              duration: 0.2,
                              ease: "power1.out",
                         })
                         .to("#carrot-2-games", {
                              top: -115,
                              duration: 0.3,
                              ease: "power1.out",
                         });
               });

               mm.add("(min-width: 840px) and (max-width: 922px)", () => {
                    tl.to("#carrot-2-games", {
                         top: -145,
                         duration: 0.4,
                         delay: 0.1,
                         ease: "sine.in",
                    })
                         .to("#carrot-2-games", {
                              top: -155,
                              duration: 0.2,
                              ease: "power1.out",
                         })
                         .to("#carrot-2-games", {
                              top: -145,
                              duration: 0.3,
                              ease: "power1.out",
                         });
               });

               mm.add("(min-width: 923px) and (max-width: 1023px)", () => {
                    tl.to("#carrot-2-games", {
                         top: -177,
                         duration: 0.4,
                         delay: 0.1,
                         ease: "sine.in",
                    })
                         .to("#carrot-2-games", {
                              top: -187,
                              duration: 0.2,
                              ease: "power1.out",
                         })
                         .to("#carrot-2-games", {
                              top: -177,
                              duration: 0.3,
                              ease: "power1.out",
                         });
               });

               mm.add("(min-width: 1024px) and (max-width: 1179px)", () => {
                    tl.to("#carrot-2-games", {
                         top: -135,
                         duration: 0.4,
                         delay: 0.1,
                         ease: "sine.in",
                    })
                         .to("#carrot-2-games", {
                              top: -145,
                              duration: 0.2,
                              ease: "power1.out",
                         })
                         .to("#carrot-2-games", {
                              top: -135,
                              duration: 0.3,
                              ease: "power1.out",
                         });
               });

               mm.add("(min-width: 1180px) and (max-width: 1279px)", () => {
                    tl.to("#carrot-2-games", {
                         top: -185,
                         duration: 0.4,
                         delay: 0.1,
                         ease: "sine.in",
                    })
                         .to("#carrot-2-games", {
                              top: -195,
                              duration: 0.2,
                              ease: "power1.out",
                         })
                         .to("#carrot-2-games", {
                              top: -185,
                              duration: 0.3,
                              ease: "power1.out",
                         });
               });

               mm.add("(min-width: 1280px) and (max-width: 1535px)", () => {
                    tl.to("#carrot-2-games", {
                         top: -245,
                         duration: 0.4,
                         delay: 0.1,
                         ease: "sine.in",
                    })
                         .to("#carrot-2-games", {
                              top: -255,
                              duration: 0.2,
                              ease: "power1.out",
                         })
                         .to("#carrot-2-games", {
                              top: -245,
                              duration: 0.3,
                              ease: "power1.out",
                         });
               });

               mm.add("(min-width: 1536px)", () => {
                    tl.to("#carrot-2-games", {
                         top: -384,
                         duration: 0.4,
                         delay: 0.1,
                         ease: "sine.in",
                    })
                         .to("#carrot-2-games", {
                              top: -394,
                              duration: 0.2,
                              ease: "power1.out",
                         })
                         .to("#carrot-2-games", {
                              top: -384,
                              duration: 0.3,
                              ease: "power1.out",
                         });
               });
          };

          const carrotAnimation = () => {
               let tl = gsap.timeline({
                    scrollTrigger: {
                         trigger: "#games-container",
                         start: "top center",
                         toggleActions: "restart none none reverse",
                         // markers: true,
                    },
               });

               tl.to("#carrot-games", {
                    opacity: 1,
                    duration: 0.2,
                    delay: 0.1,
                    ease: "sine.in",
               });
          };

          const coinAnimation = () => {
               let tl = gsap.timeline({
                    scrollTrigger: {
                         trigger: "#games-container",
                         start: "top center",
                         toggleActions: "restart none none reverse",
                         // markers: true,
                    },
               });

               tl.to("#coin-games", {
                    opacity: 1,
                    left: 128,
                    duration: 0.5,
                    ease: "sine.in",
               })
                    .to("#coin-games", {
                         left: 138,
                         duration: 0.2,
                         ease: "power1.out",
                    })
                    .to("#coin-games", {
                         left: 128,
                         duration: 0.3,
                         ease: "power1.out",
                    });
          };

          onMounted(async () => {
               cowAnimation();
               carrot2Animation();
               carrotAnimation();
               coinAnimation();

               if(useGamesStore.games != null || useGamesStore.favorites != null || useGamesStore.recent != null) {

                    if (gameHomeFilter.value.provider_id) {
                         selectedDropdown.value = gameHomeFilter.value.provider_id;
                    }

                    if (active.value == "all") {
                         pagination.data = useGamesStore.games['data'];

                         pagination.totalPage = useGamesStore.games["last_page"];
                    }

                    if (active.value == "favorite") {
                         pagination.data = useGamesStore.favorites['data'];

                         pagination.totalPage = useGamesStore.favorites["last_page"];
                    }

                    if (active.value == "recent") {
                         pagination.data = useGamesStore.recent['data'];;

                         pagination.totalPage = useGamesStore.recent["last_page"];
                    }

                    return;
               }

               await useGamesStore.getProvider();

               pagination.provider_id = [..._.pluck(providerData.value, "id")];
               const params = setParameters(pagination);
               useGamesStore.setGameHomeFilter(params, active.value);

               await useGamesStore.getGames(params);
               await useGamesStore.getGameType();

               if (isUser.value) {
                    await useGamesStore.getHeart();
               }
          });

          let x = 0;
          watch(providerData, (newVal) => {
               if (newVal) {
                    if (x == 0) {
                         const all = {
                              name: "Game Studios",
                              id: 0,
                         };
                         selectedDropdown.value = [0, ..._.pluck(newVal, "id")];
                         provider.value = [...newVal, all];

                         x++;
                    }
               }
          });

          watch(gamesData, (newVal) => {
               if (newVal) {
                    let query = newVal["data"];

                    if(query) {
                         pagination.data = query;

                         if(active.value == 'hot') {
                              if(newVal["last_page"] < 3) {
                                   pagination.totalPage = newVal["last_page"];
                              } else {
                                   pagination.totalPage = 3;
                              }
                         } else {
                              pagination.totalPage = newVal["last_page"];
                         }

                         if (active.value == "all") {
                              all_count.value = newVal["total"];
                         }
                    } else {
                         selfExclusion.value = newVal
                    }
               }
          });

          watch(groupData, (newVal) => {
               if (newVal) {
                    game_type.value = newVal;
               }
          });

          watch(favoritesData, (newVal) => {
               if (newVal) {
                    const query = newVal["data"];

                    if(query) {
                         if (active.value == "favorite") {
                              pagination.data = query;

                              pagination.totalPage = newVal["last_page"];
                         }
                    } else {
                         selfExclusion.value = newVal
                    }
               }
          });

          watch(recentData, (newVal) => {
               if (newVal) {
                    const query = newVal["data"];

                    if(query) {
                         if (active.value == "recent") {
                              pagination.data = query;

                              pagination.totalPage = newVal["last_page"];
                         }
                    } else {
                         selfExclusion.value = newVal
                    }
               }
          });

          watch(gameHomeFilter, (newVal) => {
               if (newVal) {
                    const obj = Object.keys(newVal);
                    for (let index = 0; index < obj.length; index++) {
                         pagination[obj[index]] = newVal[obj[index]]
                    }

                    active.value = newVal['active']
               }
          });

          return {
               isExpand,
               isDropdown,
               active,
               onSetActive,
               gamesData,
               pagination,
               onPrevious,
               onNext,
               onFirstPage,
               onLastPage,
               onFirstBtn,
               onSecondBtn,
               onThirdBtn,
               onImageError,
               isUser,
               favoritesData,
               recentData,
               game_type,
               onSearch,
               onStar,
               onLaunch,
               errorImages,
               provider,
               onDropdownTrigger,
               onCloseDropdown,
               dropdownManual,
               selectedDropdown,
               onSelect,
               dropdownOpen,
               onVisibleChange,
               dropdownManualMobile,
               openLogin,
               all_count,
               getSelectedDropwdown,
               money,
               loading,
               groupData,
               game_type,
               selfExclusion,
               sortByOpen,
               onSort,
               baseUrl
          };
     },
};
</script>
